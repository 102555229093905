/* eslint-disable */
import { digitsFaToEn } from "@persian-tools/persian-tools";

export const tools = {
  isObject: (data) => {
    return data && typeof data === "object";
  },
  toObject(data) {
    return this.isObject(data) ? data : {};
  },

  /**
   * Find specific field error message from errorArray
   * @param {{field: String, errorMessage: String}[]} errorArray
   * @param {string} fieldName the field's name that you want to look for into errorArray
   * @return {string} field error message if found. otherwise return empty string
   */
  findErrorMessage: (errorArray, fieldName) => {
    const t = errorArray.find((x) => x.field === fieldName);
    if (t) {
      return t.errorMessage;
    } else {
      return "";
    }
  },
  /**
   * Get Validation model, and input data object then return result array that we can store it into errors Array. if there is no error, it will be empty
   * @param {Schema} validationModelSchema
   * @param {Object} inputObject object of values that contain field's name for each input
   * @return {{field: String, errorMessage: String}[]}
   */
  applyModelCheckValidation: (validationModelSchema, inputObject) => {
    let result: { field: string; errorMessage: string }[] = [];
    const checkResult = validationModelSchema.check(inputObject);
    for (let key in checkResult) {
      let value = checkResult[key];
      if (value.hasError) {
        result.push({ field: key, errorMessage: value.errorMessage });
      }
    }
    return result;
  },
  thousandSeparator: (number) => {
    if (number) {
      let n = parseFloat(number);
      return n.toLocaleString();
    } else {
      return number;
    }
  },
  convertFileCapacity: (bytes: any, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  },
  getFormattedPlate: (firstSection, letter, secondSection, thirdSection) => {
    return `${digitsFaToEn(firstSection)}${letter}${digitsFaToEn(secondSection)}-${digitsFaToEn(thirdSection)}`;
  },
  getFullPlate: (plate: string) => {
    return `ایران ${plate.substring(7, 9)}-${plate.substring(3, 6)} ${
      plate.slice(2, 3) === "ا" ? "الف" : plate.slice(2, 3)
    } ${plate.slice(0, 2)}`;
  },
  isIOS: () => {
    // @ts-ignore
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  },
  convertDateToPersianDate: (
    date: string,
    withHour: boolean = false,
    withWeekDay: boolean = false,
    shortVersion: boolean = true,
  ) => {
    return `${
      withWeekDay
        ? new Date(date).toLocaleDateString("fa-IR", {
            weekday: "long",
          })
        : ""
    } ${new Date(date).toLocaleDateString("fa-IR", {
      day: "numeric",
      month: shortVersion ? "numeric" : "long",
      year: "numeric",
    })} ${
      withHour
        ? `ساعت ${new Date(date).toLocaleTimeString("fa-IR", {
            hour: "numeric",
            minute: "numeric",
          })}`
        : ""
    }`;
  },
  convertDateToIranTime: (date: string) => {
    return date
      ? `${new Date(date).toLocaleTimeString("fa-IR", {
          hour: "numeric",
          minute: "numeric",
        })}`
      : "";
  },
};
