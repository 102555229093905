/* eslint-disable */
import { negativeItems, positiveItems } from "core";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "react-responsive";
import { ApiInstance, tools, useChangeTitle, useFetchQueryString } from "../../../core";
import { Button, ContainerScroll, FeedbackCard, Text } from "../../components";
import { FeedbackModal, HistoryDetailHeader } from "./components";

interface IHistoryDetailProps {}

interface IQueryStringTransfer {
  id: string;
  car: string;
  feedback?: string;
}
const env = import.meta.env;

const HistoryDetail: React.FC<IHistoryDetailProps> = React.memo(({}) => {
  const isLargeScreen = useMediaQuery({
    query: "(min-height: 960px)",
  });

  const metaTags = useChangeTitle("ویراژ - تاریخچه یادآورها");
  let { id, car, feedback } = useFetchQueryString<IQueryStringTransfer>();

  const [loading, setLoading] = useState(true);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [data, setData] = useState<any>({
    createdAt: "",
    id: "",
    kilometer: 0,
    plate: {
      id: "",
      carName: "",
    },
    reminder: [],
    status: "",
    updatedAt: "",
    workshop: {
      id: "",
      name: "",
      address: "",
    },
  });
  const [feedbacks, setFeedbacks] = useState([]);
  const [positives, setPositives] = useState([]);
  const [negatives, setNegatives] = useState([]);

  const separateFeedback = (feedback) => {
    if (feedback.length !== 0) {
      let pos: any = positives;
      let neg: any = negatives;
      Object.keys(feedback[0]).map((item) => {
        if (feedback[0][item] === true) {
          pos.push(positiveItems.filter((pos) => pos.value === item)[0]);
          setPositives(pos);
        } else if (feedback[0][item] === false) {
          neg.push(negativeItems.filter((neg) => neg.value === item)[0]);
          setNegatives(neg);
        }
      });
    }
  };

  const getHistoryInfo = async () => {
    await ApiInstance.car
      .getHistoryDetail(car, id)
      .then((res) => {
        console.log(res);
        setData(res.data.data);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getFeedBack = async () => {
    await ApiInstance.provider
      .getUserFeedbacks(id)
      .then((res) => {
        console.log("feedback", res);
        setFeedbacks(res.data.data);
        separateFeedback(res.data.data);
        setTimeout(() => {
          if (feedback && res.data.data.length === 0) {
            setFeedbackModal(true);
          }
        }, 1000);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getHistoryInfo();
    getFeedBack();
  }, []);

  return (
    <>
      {metaTags}
      <HistoryDetailHeader
        createdAt={data.createdAt}
        data={data.workshop}
        plateId={car || data.plate.id || ""}
        loading={loading}
      />

      <ContainerScroll heightOffset={140} className={`ts-history-detail-padding-top`}>
        {loading ? (
          <Skeleton style={{ height: "76px" }} containerClassName="w-full" className="w-full rounded-2xl mt-6 mb-4" />
        ) : feedbacks.length === 0 ? (
          <div className={`w-full ts-shadow rounded-2xl mt-6 mb-4 bg-white flex justify-between items-center p-4`}>
            <Text type="P3" className="ts-gray-06">
              دیدگاهی داده نشده است.
            </Text>
            <Button
              onClick={() => setFeedbackModal(true)}
              bcolor="primaryTransparent"
              textType="H6"
              className="h-11 rounded-xl"
            >
              ثبت دیدگاه
            </Button>
          </div>
        ) : (
          <FeedbackCard expandable data={feedbacks[0]} />
        )}

        {loading ? (
          <Skeleton containerClassName="w-full" className="w-full h-36 rounded-2xl mb-4" />
        ) : (
          <div className={`w-full ts-shadow rounded-2xl mb-4 bg-white flex flex-col justify-start items-start p-4`}>
            <Text type="H5" className="ts-accent mb-4">
              مشخصات خودرو
            </Text>
            <div className="w-full flex justify-start items-center mb-4">
              <Text type="P4" className="ts-gray-06 w-20">
                نوع ماشین:
              </Text>
              <Text type="P4" className="ts-accent">
                {data.plate ? data.plate.carName : "---"}
              </Text>
            </div>
            <div className="w-full flex justify-start items-center mb-4">
              <Text type="P4" className="ts-gray-06 w-20">
                کارکرد:
              </Text>
              <Text type="P4" className="ts-accent">
                {`${data.kilometer ? tools.thousandSeparator(data.kilometer) : 0} کیلومتر`}
              </Text>
            </div>
          </div>
        )}

        {loading ? (
          <Skeleton containerClassName="w-full" className="w-full h-48 rounded-2xl" />
        ) : (
          <div className={`w-full ts-shadow rounded-2xl bg-white flex flex-col justify-start items-start p-4`}>
            <Text type="H5" className="ts-accent mb-1">
              خدمات ثبت شده
            </Text>
            <Text type="P5" className="ts-gray-06 mb-4">
              در حال حاضر تنها خدماتی که نیاز به یادآور دارند ثبت می‌شوند.
            </Text>
            <ul className="list-disc mr-4">
              {data.reminder &&
                data.reminder.map((item) => {
                  return (
                    <li className="mb-1">
                      <Text type="P4" className="ts-accent">
                        {item.service ? item.service.name || "نام سرویس یافت نشد" : "نام سرویس یافت نشد"}
                      </Text>
                    </li>
                  );
                })}
            </ul>
          </div>
        )}
      </ContainerScroll>
      <FeedbackModal
        id={id}
        isOpen={feedbackModal}
        onFeedbackRegistered={() => {
          setLoading(true);
          getFeedBack();
        }}
        onClose={() => setFeedbackModal(false)}
        workshop={data.workshop}
        showWorkshop={Boolean(feedback)}
      />
    </>
  );
});

export { HistoryDetail as default };
