/* eslint-disable */
import { ApiInstance, negativeItems, positiveItems, workshopTypes } from "core";
import React, { useEffect, useState } from "react";
import { FaStar } from "react-icons/fa";
import { Rating } from "react-simple-star-rating";
import { Input } from "rsuite";
import { Button, Text, useNotification } from "./";
import { FeedbackTabBar } from "./FeedbackTabBar";

interface IFeedbackProps {
  id: string;
  showWorkshop?: boolean;
  workshop: any;
  onDone?: () => void;
}

const Feedback: React.FC<IFeedbackProps> = React.memo(({ id, showWorkshop = false, onDone, workshop }) => {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [activeTab, setActiveTab] = useState(1);
  const [rating, setRating] = useState(0);
  const [description, setDescription] = useState("");
  const [selectedItem, setSelectedItem] = useState({});

  useEffect(() => {
    setDisabled(rating === 0);
  }, [rating]);

  const handleRating = (rate: number) => {
    setRating(rate);
  };

  const addFeedbackHandler = (item) => {
    let value = {};
    value = { ...selectedItem, [item.value]: item.score };
    if (item.value in selectedItem && item.score === selectedItem[item.value]) {
      delete value[item.value];
    }
    console.log(value);
    setSelectedItem(value);
  };

  const registerFeedBackHandler = async () => {
    setLoading(true);
    const data = {
      ...selectedItem,
      score: rating,
      sessionId: id,
      description,
    };
    console.log(data);

    await ApiInstance.provider
      .addFeedback(data)
      .then((res) => {
        console.log(res);
        useNotification("دیدگاه شما برای این کسب و کار ثبت شد.", "success", "ثبت دیدگاه");
        // setTimeout(() => {
        //   if (showWorkshop) {
        //     navigate(ConfigLink.intro);
        //   } else {
        onDone && onDone();
        //   }
        // }, 1500);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error", "ثبت دیدگاه");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Text type="H4" className="ts-accent text-center mb-6">
        به این کسب و کار امتیاز دهید
      </Text>
      {showWorkshop && (
        <div className="w-full rounded-xl ts-bg-gray-01 p-3 flex flex-col justify-center items-center mb-6">
          <Text type="H4" className="ts-accent mb-2">
            {workshop.name || "---"}
          </Text>
          <Text type="P4" className="ts-gray-06">
            {`${
              workshop && workshop.categories
                ? workshopTypes.filter((item) => item.value === workshop?.categories[0])[0].viewLabel
                : "---"
            }`}
          </Text>
        </div>
      )}
      <div className="w-full flex justify-center items-center mb-8">
        <Rating
          initialValue={rating}
          size={31}
          // rtl
          onClick={handleRating}
          fillIcon={<FaStar size={31} className="mx-1.5 inline-block" />}
          emptyIcon={<FaStar size={31} className="mx-1.5 inline-block" />}
          // allowFraction
          transition
          fillColor="#FFA23A"
          emptyColor="#D5DDE5"
          emptyClassName="inline-block"
          SVGclassName="inline-block mb-2.5"
        />
      </div>
      <FeedbackTabBar activeTab={activeTab} onActiveChanged={(id) => setActiveTab(id)} />
      <div className="w-full flex flex-wrap justify-between items-center mb-8">
        {activeTab === 1
          ? positiveItems.map((item) => {
              return (
                <Button
                  bcolor={selectedItem[item.value] === item.score ? "black" : "blackTransparent"}
                  onClick={() => addFeedbackHandler(item)}
                  classNameContainer="ts-feedback-button-half-width"
                  className="w-full h-10 rounded-xl mb-3"
                  textType="HM"
                >
                  {item.label}
                </Button>
              );
            })
          : negativeItems.map((item) => {
              return (
                <Button
                  bcolor={selectedItem[item.value] === item.score ? "black" : "blackTransparent"}
                  onClick={() => addFeedbackHandler(item)}
                  classNameContainer="ts-feedback-button-half-width"
                  className="w-full h-10 rounded-xl mb-3"
                  textType="HM"
                >
                  {item.label}
                </Button>
              );
            })}
      </div>
      <Text type="H6" className={`transition-all ts-accent mb-2`}>
        توضیحات اضافی
      </Text>
      <Input
        as="textarea"
        value={description}
        onChange={(e) => setDescription(e)}
        rows={5}
        className={`w-full transition-all duration-200 rounded-xl mb-24 outline-none placeholder:text-gray-300 disabled:bg-white`}
        placeholder=""
      />
      <div className="ts-feedback-bottom-button w-full bg-white fixed bottom-2 rounded-b-3xl left-0 px-6 max-2xs:px-3 pt-1">
        <Button
          onClick={registerFeedBackHandler}
          bcolor="primary"
          loading={loading}
          disabled={disabled}
          classNameContainer="w-full"
          className="w-full h-12 rounded-xl mb-3"
        >
          ثبت دیدگاه
        </Button>
        <Text type="P5" className="ts-gray-06 text-center">
          نظر شما با حفظ حریم شخصی‌تان ثبت می‌شود.
        </Text>
      </div>
    </>
  );
});

export { Feedback };
