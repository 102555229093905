/* eslint-disable */
import React from "react";
import { BiDotsVerticalRounded } from "react-icons/bi";
import Skeleton from "react-loading-skeleton";
import defaultCar from "../../../../assets/images/garage-card-image.png";

interface IPlateAddLoaderProps {}

const PlateAddLoader: React.FC<IPlateAddLoaderProps> = React.memo(() => {
  return (
    <div className="w-full mt-6">
      {[1, 2].map((item) => {
        return (
          <div
            key={item}
            style={{ height: "73px" }}
            className="w-full flex justify-between items-start ts-shadow mb-2 bg-white rounded-xl p-3"
          >
            <div className="flex h-full justify-start items-start">
              {/* <div className="py-3 px-5 rounded-xl ts-bg-gray-01 ml-4"> */}
              <img src={defaultCar} alt="default" className="object-fit h-12 w-20 ml-4" />
              {/* </div> */}
              <div className="h-full flex flex-col justify-between items-start">
                <Skeleton className={`w-14 h-3`} />
                <Skeleton className={`w-20 h-3 mt-3`} />
              </div>
            </div>
            <BiDotsVerticalRounded size={23} className="ts-accent -mt-0.5" />
          </div>
        );
      })}
    </div>
  );
});

export { PlateAddLoader };

const styles = {};
