/* eslint-disable */
import { isArabic, toPersianChars } from "@persian-tools/persian-tools";
import { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { RiErrorWarningFill } from "react-icons/ri";
import Skeleton from "react-loading-skeleton";
import { Loader, SelectPicker, Toggle } from "rsuite";
import { useSnapshot } from "valtio";
import { ApiInstance, periodTimes, useChangeTitle, vState } from "../../../core";
import { ContainerScroll, SelectCity, Text, useNotification } from "../../components";

const Setting = () => {
  const metaTags = useChangeTitle("ویراژ - تنظیمات");
  const snap = useSnapshot(vState);

  const [pageLoading, setPageLoading] = useState(true);
  const [notifLoading, setNotifLoading] = useState(false);
  const [cityLoading, setCityLoading] = useState(false);
  const [isSecureLoading, setSecureLoading] = useState(false);
  const [period, setPeriod] = useState("one_months");
  const [city, setCity] = useState("");
  const [cityModal, setCityModal] = useState(false);
  const [selectedCity, setSelectedCity] = useState<any | null>(null);
  const [hideMyNumber, setHideMyNumber] = useState<boolean>(false);

  const getCities = async (cityId) => {
    await ApiInstance.common.getAllCities().then((res) => {
      console.log(res);
      const newArrayOfObj = res.data.data.map(({ id: value, city: city, province: province, priority: priority }) => ({
        value,
        label: `${isArabic(city) ? toPersianChars(city) : city}، ${
          isArabic(province) ? toPersianChars(province) : province
        }`,
        priority,
      }));
      const selectedCityFilter = newArrayOfObj.filter((item) => item.value === cityId)[0];
      setSelectedCity(selectedCityFilter);
    });
  };

  const getSetting = async () => {
    await ApiInstance.account
      .getSetting()
      .then((res) => {
        console.log(res);
        const data = res.data;
        setPeriod(data.smsReminderInterval || "one_months");
        setCity(data.cityId || "");
        setHideMyNumber(data.hideMyNumber || false);
        if (data.cityId) {
          getCities(data.cityId || "");
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  useEffect(() => {
    getSetting();
  }, []);

  const requestHandler = async (value, type: 1 | 2 | 3) => {
    type === 1 ? setNotifLoading(true) : type === 2 ? setCityLoading(true) : setSecureLoading(true);
    await ApiInstance.account
      .changeSetting({
        smsReminderInterval: type === 1 ? value : period,
        cityId: type === 2 ? value : city,
        hideMyNumber: type === 3 ? value : hideMyNumber,
      })
      .then((res) => {
        console.log("res", res);
        useNotification(
          type === 1
            ? "فاصله زمانی ارسال پیامک تغییر کرد."
            : type === 2
            ? "شهر محل سکونت شما ثبت شد."
            : "تغییرات درخواستی ثبت شد.",
          "success",
          "تنظیمات",
          2000,
        );
        type === 1 ? setPeriod(value) : type === 2 ? setCity(value) : setHideMyNumber((prev) => !prev);
      })
      .catch((err) => {
        console.log("err", err);
        useNotification("مشکلی رخ داده است. لطفا مجددا تلاش کنید.", "error", "تنظیمات");
      })
      .finally(() => {
        type === 1 ? setNotifLoading(false) : type === 2 ? setCityLoading(false) : setSecureLoading(false);
      });
  };

  const selectCityHandler = (city) => {
    setSelectedCity(city);
    setCity(city.value);
    requestHandler(city.value, 2);
  };

  const secureNumberHandler = async (value) => {
    console.log(value);

    requestHandler(value, 3);
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={50} className="">
        <Text type="H4" className="ts-accent mb-8">
          تنظیمات
        </Text>

        <Text type="H5" className="ts-accent mb-2">
          فاصله زمانی ارسال پیامک
        </Text>
        <Text type="P5" className="ts-gray-07 mb-2">
          با مشخص کردن فاصله زمانی برای ارسال پیامک، ویراژ، به شما یادآوری میکند که کارکرد ماشین خود را وارد نمایید.
        </Text>
        <div className="w-full relative">
          {pageLoading ? (
            <Skeleton containerClassName="w-full" className="w-full h-12 -mt-2 mb-5 rounded-xl" />
          ) : (
            <SelectPicker
              size="lg"
              placement="auto"
              data={periodTimes}
              value={period}
              onChange={(e) => requestHandler(e, 1)}
              block
              searchable={false}
              cleanable={false}
              placeholder="فاصله زمانی را انتخاب کنید"
              className={`w-ful h-12 mb-6 outline-none rounded-xl z-20`}
            />
          )}
          {notifLoading && (
            <div className="bg-white absolute top-4 left-4 z-30">
              <Loader size="xs" />
            </div>
          )}
        </div>
        <div className="flex justify-start items-center mb-8">
          <RiErrorWarningFill size={20} className="ts-gray-07 -mt-px" />
          <Text
            type="P5"
            className="ts-gray-07 mr-1"
          >{`پیامک‌های یادآور به شماره “${snap.userInfo.username}” ارسال می‌شود.`}</Text>
        </div>

        <Text type="H5" className="ts-accent mb-2">
          ناحیه جغرافیایی
        </Text>
        <Text type="P5" className="ts-gray-07 mb-2">
          برای خدمت رسانی بهتر و متناسب با ناحیه جغرافیایی شما خواهشمندیم شهر محل زندگی خود را وارد نمایید.
        </Text>
        <Text type="H6" className="ts-accent mb-2">
          شهر
        </Text>
        <div className="w-full relative mb-6">
          {pageLoading ? (
            <Skeleton containerClassName="w-full" className="w-full h-12 rounded-xl" />
          ) : (
            <div
              onClick={() => setCityModal(true)}
              className="w-full cursor-pointer rounded-xl p-4 flex justify-between items-center h-12 ts-input-border-city"
            >
              <Text type="P4" className="ts-accent">
                {selectedCity ? selectedCity.label : "انتخاب شهر"}
              </Text>
              <BiChevronDown size={21} className="ts-accent -mt-px" />
            </div>
          )}
          {cityLoading && (
            <div className="bg-white absolute top-4 left-4 z-30">
              <Loader size="xs" />
            </div>
          )}
        </div>

        <Text type="H5" className="ts-accent mb-2">
          امنیت تماس
        </Text>
        <Text type="P5" className="ts-gray-07 mb-4">
          در صورتیکه تمایل ندارید شماره تماس شما به کسب‌وکارها نمایش داده شود، میتوانید شماره تماس خود را از آنها مخفی
          کنید.
        </Text>

        {snap.activeFeatures.includes("hide_mobile_number") && (
          <div className="w-full flex justify-start items-center mb-4">
            <Toggle
              size="sm"
              className="ts-secure-toggle"
              disabled={pageLoading}
              loading={isSecureLoading}
              checked={hideMyNumber}
              onChange={(ch) => secureNumberHandler(ch)}
            />
            <Text type="P4" className="ts-accent mr-2">
              عدم نمایش شماره تماس به کسب‌وکارها
            </Text>
          </div>
        )}
      </ContainerScroll>
      <SelectCity
        isOpen={cityModal}
        selectedId={city.length !== 0 ? city : selectedCity ? selectedCity?.value : null}
        onClose={() => setCityModal(false)}
        onSelect={(city) => selectCityHandler(city)}
      />
    </>
  );
};

export { Setting as default };
