import { proxy } from "valtio";
import { UserProfile } from "./../interfaces/dto/User";

interface ICarInfo {
  plate: string;
  carType: string;
  fuelType: string;
  year: string;
  kilometer: string;
}

interface ICarList {
  id: string;
  createdAt: string;
  kilometer: number;
  plate: {
    id: string;
    plateNumber: string;
    mobile: string;
    car: {
      id: string;
      name: string;
    };
  };
  status: string;
  updatedAt: string;
}

interface IOwnerCars {
  carName: string;
  carId?: string;
  car?: any;
  id: string;
  kilometer?: number | undefined;
  kilomeeterCreatedAt?: string;
  mobile: string;
  ownerName: any;
  owner: string | null;
  plateNumber: string;
  updatedAt?: string | undefined;
  buildYear?: number | undefined;
  fuelType?: string | undefined;
  monthlyUsage?: string | undefined;
}

interface ISelectPicker {
  label: string;
  value: number | string;
}

export interface IVersion {
  number: number;
  code: number;
  note: string;
}

export interface IVState {
  cities: any[];
  cars: any[];
  services: any[];
  categories: ISelectPicker[];
  version: IVersion;
  activeFeatures: string[];

  userInfo: UserProfile;
  carList: ICarList[];
  reminders: any[];
  sessionId: string;

  pageLoading: boolean;
  hasError: boolean;
  isMobileDevice: boolean;
  isScrolling: boolean;
  isCarStatusScroll: boolean;
  isAuthenticated: boolean;
  isEndOfPage: boolean;

  ownerCars: IOwnerCars[];
  carStatus: IOwnerCars;
  userCar: ICarInfo;
  setupServices: any[];
}

const vState = proxy({
  pageLoading: true,
  hasError: false,
  isMobileDevice: false,
  isScrolling: false,
  isCarStatusScroll: false,
  isEndOfPage: false,
  version: {
    number: 0,
    code: 0,
    note: "",
  },
  activeFeatures: [],

  cities: [],

  cars: [],
  services: [],
  categories: [],

  userInfo: {
    id: "",
    name: "",
    username: "",
    phoneNumber: "",
    roles: [],
    mobile: "",
    userProfileId: "",
  },
  carList: [],

  reminders: [],
  sessionId: "",
  isAuthenticated: false,

  ownerCars: [],
  carStatus: {
    carName: "",
    id: "",
    kilometer: 0,
    mobile: "",
    ownerName: "",
    plateNumber: "",
    updatedAt: "",
    buildYear: 0,
    fuelType: "",
    monthlyUsage: "",
    owner: null,
  },
  userCar: {
    plate: "",
    carType: "",
    fuelType: "",
    year: "",
    kilometer: "",
  },
  setupServices: [],
} as IVState);

export { vState };
