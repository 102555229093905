/* eslint-disable */
import React, { useEffect, useState } from "react";
import { MdAddCircle } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { ApiInstance, useChangeTitle, vState } from "../../../core";
import { Button, ContainerScroll, NoWorkspaceEmptyState, Text, useNotification } from "../../components";
import { AddOrEditCar, CarsCard, DeleteCarModal, PlateAddLoader, SetupModal } from "./components";

interface IGarageProps {}

const Garage: React.FC<IGarageProps> = React.memo(({}) => {
  const metaTags = useChangeTitle("ویراژ - گاراژ من");
  const snap = useSnapshot(vState);
  const navigate = useNavigate();

  const [screenLoading, setScreenLoading] = useState(true);
  const [deleteCarModal, setDeleteCarModal] = useState(false);
  const [AddOrEditCarModal, setAddOrEditCarModal] = useState(false);
  const [setupModal, setSetupModal] = useState(false);
  const [carOperation, setCarOperation] = useState<"register" | "edit">("register");
  const [selectedCar, setSelectedCar] = useState<any>(null);
  const [userHasCar, setUserHasCar] = useState(false);

  const getCars = async () => {
    setScreenLoading(true);
    await ApiInstance.plates
      .getUserCars()
      .then((res) => {
        console.log(res);
        if (res.data.data.length === 0) {
          setUserHasCar(false);
        } else {
          vState.ownerCars = res.data.data;
          setUserHasCar(true);
        }
      })
      .catch((err) => {
        console.log("err", err);
        useNotification("مشکلی رخ داده است. لطفا مجددا تلاش کنید.", "error", "گاراژ");
      })
      .finally(() => {
        setScreenLoading(false);
      });
  };

  useEffect(() => {
    getCars();
  }, []);

  const addCarHandler = () => {
    setSelectedCar(null);
    setCarOperation("register");
    setAddOrEditCarModal(true);
  };

  const editCarHandler = (data) => {
    setSelectedCar(data);
    setCarOperation("edit");
    setAddOrEditCarModal(true);
  };

  const deleteCarHandler = (data) => {
    setSelectedCar(data);
    setDeleteCarModal(true);
  };

  const setupCarHandler = (data) => {
    setSelectedCar(data);
    setSetupModal(true);
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={50} className="relative">
        <Text type="H4" className="ts-accent mb-4">
          گاراژ من
        </Text>
        {screenLoading ? (
          <PlateAddLoader />
        ) : userHasCar ? (
          <>
            {snap.ownerCars.map((item) => {
              return (
                <CarsCard
                  key={item.id}
                  data={item}
                  onDelete={() => deleteCarHandler(item)}
                  onEdit={() => editCarHandler(item)}
                  onStart={() => setupCarHandler(item)}
                />
              );
            })}
            <Button
              bcolor="primary"
              textType="H6"
              icon={<MdAddCircle size={20} className="text-white -mt-1" />}
              classNameContainer="w-full"
              className="w-full h-12 rounded-xl ts-letter-space mt-4"
              onClick={addCarHandler}
            >
              اضافه کردن خودرو
            </Button>
          </>
        ) : (
          <NoWorkspaceEmptyState onClicked={() => setAddOrEditCarModal(true)} />
        )}
      </ContainerScroll>

      <AddOrEditCar
        isOpen={AddOrEditCarModal}
        type={carOperation}
        data={selectedCar}
        onCarAdded={getCars}
        onClose={() => setAddOrEditCarModal(false)}
      />
      <DeleteCarModal
        id={selectedCar ? selectedCar?.id : ""}
        onDeleteEnded={getCars}
        isOpen={deleteCarModal}
        onClose={() => setDeleteCarModal(false)}
      />
      <SetupModal id={selectedCar ? selectedCar?.id : ""} isOpen={setupModal} onClose={() => setSetupModal(false)} />
    </>
  );
});

export { Garage as default };
