/* eslint-disable */
import React, { useEffect, useState } from "react";
import { ApiInstance } from "../../../core";
import { Button, Text } from "../../components";
import { ServerNotRespondImage, VpnEnableImage } from "./components";

interface IServerNotRespondProps {}

const ServerNotRespond: React.FC<IServerNotRespondProps> = React.memo(({}) => {
  const [vpnEnabled, setVpnEnabled] = useState(false);
  const [loading, setLoading] = useState(true);

  const callBackupHandler = () => {
    window.open("tel:02191693862", "_self");
  };

  useEffect(() => {
    getUserIp();
  }, []);

  const getUserLocation = async (ip) => {
    await ApiInstance.map
      .getUserLocation(ip)
      .then((res) => {
        console.log("location", res);
        if (res.status === 200 && res.data.countryCode !== "IR") {
          setVpnEnabled(true);
        }
      })
      .catch((err) => console.log("err location", err))
      .finally(() => {
        setLoading(false);
      });
  };

  const getUserIp = async () => {
    await ApiInstance.map
      .getUserIp()
      .then((res) => {
        console.log("ip", res);
        getUserLocation(res.data.ip || "");
      })
      .catch((err) => {
        console.log("err IP", err);
        setLoading(false);
      });
  };

  const reload = () => {
    window.location.reload();
  };

  return (
    <div className="w-full h-screen bg-white flex flex-col justify-center items-center">
      {loading ? (
        <></>
      ) : vpnEnabled ? (
        <div className="w-full px-7 flex flex-col justify-center items-center text-center">
          <VpnEnableImage />
          <Text type="P4" className="ts-accent my-7">
            برای اختلال کمتر و عملکرد بهتر اپلیکیشن، لطفا VPN خود را خاموش کنید.
          </Text>

          <Button bcolor="primary" onClick={reload} classNameContainer="w-full" className="w-full h-12 rounded-xl">
            بارگذاری مجدد صفحه
          </Button>
        </div>
      ) : (
        <>
          <ServerNotRespondImage />
          <div className="w-full px-7 flex flex-col justify-center items-center text-center mt-16">
            <Text type="H5" className="ts-gray-07">
              مشکلی پیش آمده!
            </Text>
            <Text type="P4" className="ts-gray-07 text-center mt-3 mb-1.5">
              در صورت اطمینان از اتصال دستگاه خود به اینترنت، پس از چند دقیقه دوباره امتحان کنید.
            </Text>
            <Text type="P4" className="ts-gray-07 text-center mb-6">
              همچنین، در صورت تداوم مشکل میتوانید با پشتیبانی تماس بگیرید.
            </Text>
            <div className="w-full md:w-2/3 flex justify-between items-center xs:mb-10 gap-3">
              <Button
                onClick={reload}
                bcolor="primary"
                classNameContainer="ts-half-width"
                className="w-full rounded-xl h-12"
              >
                تلاش مجدد
              </Button>
              <Button
                onClick={callBackupHandler}
                bcolor="primaryTransparent"
                classNameContainer="ts-half-width"
                className="w-full rounded-xl h-12"
              >
                تماس با پشتیبانی
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
});

export { ServerNotRespond };

const styles = {};
