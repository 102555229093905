/* eslint-disable */
// @ts-nocheck
import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import defaultCar from "../../assets/images/part-request-default.svg";
import { configs } from "./../../configs/configs";

interface IImageSliderProps {
  images: any[];
  dir: string;
  onClick: (index) => void;
}

const ImageSlider: React.FC<IImageSliderProps> = React.memo(({ images, dir, onClick }) => {
  useEffect(() => {}, []);

  const [hasError, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  return (
    <>
      <Swiper
        spaceBetween={10}
        slidesPerView={1}
        modules={[Pagination, Navigation]}
        lazy={true}
        pagination={{
          clickable: true,
          el: ".swiper-custom-pagination",
        }}
      >
        {images.map((item, index) => {
          return (
            <SwiperSlide
              key={index}
              // onClick={() => imageClickHandler(item)}
              className={`w-full ts-carousel-image-height rounded-xl`}
            >
              {/* <img
                src={`${configs().imageUrl}${dir}/${item}`}
                className={`w-full h-full rounded-xl object-cover cursor-pointer`}
                alt={index.toString()}
                loading="lazy"
              /> */}
              {hasError ? (
                <div className="w-full ts-carousel-image-height rounded-xl ts-bg-gray-01">
                  <img
                    src={defaultCar}
                    alt="error"
                    className="w-full max-w-full ts-carousel-image-height rounded-xl object-contain"
                  />
                </div>
              ) : (
                <LazyLoadImage
                  alt={index.toString()}
                  onClick={() => onClick(index)}
                  className={`w-full max-w-full ts-carousel-image-height rounded-xl object-cover cursor-pointer`}
                  src={`${configs().imageUrl}${dir}/${item}`}
                  onLoad={() => setLoading(false)}
                  width={"100%"}
                  effect={loading ? "blur" : "opacity"}
                  onError={() => setError(true)}
                />
              )}
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="swiper-custom-pagination" />
    </>
  );
});

export { ImageSlider };

const styles = {};
