/* eslint-disable */
import React from "react";
import ReminderDetail from "ui/views/Car/ReminderDetail";
import { useSnapshot } from "valtio";
import { vState } from "../../../../core";
import { BottomModal } from "../../../components";

interface IReminderDetailModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDone: () => void;
  service: string;
  needUpdate: () => boolean;
}

const ReminderDetailModal: React.FC<IReminderDetailModalProps> = React.memo(
  ({ isOpen, onClose, onDone, service, needUpdate }) => {
    const snap = useSnapshot(vState);
    const showUpdate = !needUpdate();

    const doneHandler = () => {
      onDone();
      setTimeout(() => {
        onClose();
      }, 1000);
    };

    const info = {
      id: service,
      currentKilometer: snap.carStatus.kilometer || 0,
      ...(showUpdate ? { plate: snap.carStatus.id } : undefined),
    };
    return (
      <BottomModal isOpen={isOpen} onClose={onClose}>
        {snap.carStatus && snap.carStatus.id && snap.reminders.length !== 0 && service.length !== 0 && (
          <ReminderDetail forModal info={info} onDone={doneHandler} carPlateId={snap.carStatus.id || ""} />
        )}
      </BottomModal>
    );
  },
);

export { ReminderDetailModal };

const styles = {};
