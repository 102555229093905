/* eslint-disable */
import React, { useState } from "react";
import { FiCornerUpRight } from "react-icons/fi";
import { HiUserCircle } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import banner from "../../../assets/images/header-banner.svg";
import virazh from "../../../assets/images/virazh-logo.svg";
import { ConfigLink, vState } from "../../../core";
import { Button, Text } from "../../components";

interface IHeaderProps {
  className?: string;
  showBackButton?: boolean;
  noLogo?: boolean;
  logoCenter?: boolean;
  backClick?: () => void;
  lowMargin?: boolean;
  withBackground?: boolean;
  noProfile?: boolean;
  profileActive?: boolean;
  backLink?: string;
}

const Header: React.FC<IHeaderProps> = React.memo(
  ({
    className,
    showBackButton = false,
    backLink = "/",
    noLogo = false,
    backClick,
    withBackground = false,
    logoCenter = false,
    lowMargin = false,
    noProfile = false,
    profileActive = false,
  }) => {
    const snap = useSnapshot(vState);
    const navigate = useNavigate();
    const profileCondition = window.location.pathname.includes(ConfigLink.profile);
    const messageCondition = window.location.pathname === "ConfigLink.profile";

    const [isLoggedIn, setLoggedIn] = useState(false);

    const backButtonClickHandler = () => {
      if (backClick) {
        backClick();
      } else {
        navigate(-1);
      }
    };

    return (
      <>
        <div
          className={`w-full ts-limit-width ${
            lowMargin ? "px-3" : "px-6"
          } max-2xs:px-3 py-3 h-16 fixed flex justify-between items-center ${className} transition-all bg-white ${
            snap.isScrolling ? "ts-bottom-shadow" : ""
          } z-40`}
        >
          {showBackButton ? (
            <div
              onClick={backButtonClickHandler}
              className={`transition-all h-11 px-3 rounded-xl ts-bg-gray-01 flex justify-center items-center cursor-pointer z-20`}
            >
              <FiCornerUpRight size={18} className="ts-primary" />
              <Text type="H6" className="ts-primary mr-1">
                بازگشت
              </Text>
            </div>
          ) : !noLogo ? (
            <Link to={ConfigLink.homePage}>
              <img src={virazh} alt="logo" className=" cursor-pointer" />
            </Link>
          ) : null}

          {!noProfile && (
            <div className="flex justify-center items-center">
              {/* <Button
              classNameContainer="ts-shadow rounded-xl ts-header-button"
              className={`w-11 h-11 rounded-xl ${
                messageCondition ? "ts-bg-primary-light" : "bg-white"
              }`}
            >
              <BiSolidMessageAltDetail
                size={24}
                className={`${messageCondition ? "ts-primary" : "ts-accent"}`}
              />
            </Button> */}
              <Link to={ConfigLink.profile}>
                <Button
                  classNameContainer="ts-shadow rounded-xl mr-2 ts-header-button"
                  className={`w-11 h-11 rounded-xl ${
                    profileCondition || profileActive ? "ts-bg-primary-light" : "bg-white"
                  }`}
                >
                  <HiUserCircle
                    size={24}
                    className={`${profileCondition || profileActive ? "ts-primary" : "ts-accent"}`}
                  />
                </Button>
              </Link>
            </div>
          )}
        </div>
      </>
    );
  },
);

export { Header };

const withBackgroundStyle = {
  background: `url(${banner})`,
  height: "88px",
  backgroundPosition: "center center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};
