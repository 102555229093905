/* eslint-disable */
import React, { useState } from "react";
import { IoMdRefresh } from "react-icons/io";
import { MdKeyboardArrowDown } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { Divider } from "rsuite";
import { useSnapshot } from "valtio";
import avgIcon from "../../../../assets/images/avg-icon.png";
import banner from "../../../../assets/images/car-prototype.svg";
import kmIcon from "../../../../assets/images/car-status-kilometer.svg";
import fuelIcon from "../../../../assets/images/fuel-icon.png";
import plateIcon from "../../../../assets/images/plate-icon.png";
import yearIcon from "../../../../assets/images/year-icon.png";
import { fuelTypes, monthlyUsage, tools, vState } from "../../../../core";
import { Button, Text } from "../../../components";

interface ICustomHeaderProps {
  onEdit: () => void;
  loading: boolean;
  updateKilometerClick: () => void;
}

const env = import.meta.env;

const CustomHeader: React.FC<ICustomHeaderProps> = React.memo(({ onEdit, loading, updateKilometerClick }) => {
  const navigate = useNavigate();
  const snap = useSnapshot(vState);

  const [isOpen, setIsOpen] = useState(false);

  const isLargeScreen = useMediaQuery({
    query: "(min-width: 530px)",
  });

  return (
    <>
      <div
        className={`w-full ts-shadow flex flex-col justify-start items-start rounded-2xl p-4 mb-8 ts-cars-card-bg ${
          isOpen ? "ts-car-status-header-open" : "ts-car-status-header-close"
        }`}
      >
        {loading ? (
          <Skeleton className="mb-3 w-32 h-8" />
        ) : (
          <Text type="H4" className="ts-gray-08 mb-4">
            {snap.carStatus.car ? snap.carStatus.car.name : snap.carStatus.carName ? snap.carStatus.carName : ""}
          </Text>
        )}

        <img src={banner} alt="car-status" className="m-auto mb-6" />

        <span className="w-full flex justify-between items-center mb-6">
          <span className="flex justify-center">
            <img src={kmIcon} alt="icn" className=" -mt-1" />
            {loading ? (
              <Skeleton className="w-24 h-8" />
            ) : (
              <Text type="H3" className="ts-gray-08 text-center mr-1">
                {snap.carStatus.kilometer ? `${tools.thousandSeparator(snap.carStatus.kilometer)}` : "بدون کارکرد"}
                {snap.carStatus.kilometer ? <b className="mr-0.5 ts-text-p2">کیلومتر</b> : ""}
              </Text>
            )}
          </span>
          {loading ? (
            <Skeleton className="w-28 h-10 rounded-xl" />
          ) : (
            <Button
              bcolor="gray1"
              icon={<IoMdRefresh size={21} className="ts-primary" />}
              iconPosition="afterText"
              disabled={loading}
              onClick={updateKilometerClick}
              className="h-10"
            >
              {snap.carStatus.kilometer ? "بروز رسانی" : "ثبت کارکرد"}
            </Button>
          )}
        </span>

        {!isOpen && (
          <span
            onClick={() => setIsOpen((prev) => !prev)}
            className="w-full flex justify-center items-center cursor-pointer transition-all mb-6"
          >
            <Text type="HM" className="ts-primary ml-1">
              {isOpen ? "مخفی کردن جزییات" : "نمایش جزییات"}
            </Text>
            <MdKeyboardArrowDown
              size={21}
              className={`ts-primary -mt-0.5 transition-all duration-500 ${isOpen ? "rotate-180" : "rotate-0"}`}
            />
          </span>
        )}

        <div className="w-full flex justify-center items-center mb-5">
          {snap.carStatus.plateNumber && (
            <>
              <img src={plateIcon} alt="plate" className="w-5 h-5 ml-1" />
              <Text type="H6" className="ts-gray-07 w-32">
                {tools.getFullPlate(snap.carStatus.plateNumber)}
              </Text>
              <Divider vertical className="h-5 mx-4" />
            </>
          )}

          <>
            <img src={fuelIcon} alt="fuel" className="w-5 h-5 ml-1" />
            <Text type="H6" className="ts-gray-07 w-11">
              {snap.carStatus.fuelType
                ? fuelTypes.filter((item) => item.value === snap.carStatus.fuelType)[0].label
                : "---"}
            </Text>
            {/* <Divider vertical className="h-5 mx-4" /> */}
          </>
        </div>
        <div className="w-full flex justify-center items-center">
          <>
            <img src={avgIcon} alt="avg" className="w-5 h-5 ml-1" />
            <Text type="H6" className="ts-gray-07 w-32">
              {snap.carStatus.monthlyUsage
                ? monthlyUsage.filter((item) => item.value === snap.carStatus.monthlyUsage)[0].showLabel || "---"
                : "---"}
            </Text>
            <Divider vertical className="h-5 mx-4" />
          </>

          <>
            <img src={yearIcon} alt="year" className="w-5 h-5 ml-1" />
            <Text type="H6" className="ts-gray-07 w-11">
              {snap.carStatus.buildYear || "---"}
            </Text>
          </>

          {/* {snap.carStatus.vin && <>
              <Divider vertical className="h-5 mx-4"/>
              <img src={fuelIcon} alt='plate' className="w-5 h-5 ml-1"/>
             <Text type='H6' className="ts-gray-07">
             {snap.carStatus.vin || "---"}
             </Text>
            </>} */}
        </div>

        {isOpen && (
          <span
            onClick={() => setIsOpen((prev) => !prev)}
            className="w-full flex justify-center items-center cursor-pointer transition-all mt-6"
          >
            <Text type="HM" className="ts-primary ml-1">
              {isOpen ? "مخفی کردن جزییات" : "نمایش جزییات"}
            </Text>
            <MdKeyboardArrowDown
              size={21}
              className={`ts-primary -mt-0.5 transition-all duration-500 ${isOpen ? "rotate-180" : "rotate-0"}`}
            />
          </span>
        )}
      </div>

      {/* <div
        style={{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${banner})`,
          // background: `url(${banner})`,
          backgroundPosition: isLargeScreen ? "40% 55%" : "55% 65%",
          backgroundRepeat: "no-repeat",
        }}
        className={`w-full ts-custom-header-height-full bg-cover rounded-xl flex flex-col justify-between items-start p-4`}
      >
        <div className="w-full flex justify-between items-center">
          {loading ? (
            <Skeleton baseColor="#8b8d8b" className="w-24 h-8" />
          ) : (
            <Text type="H3" className="text-white">
              {snap.carStatus.carName ? snap.carStatus.carName : snap.carStatus.car ? snap.carStatus.car.name : ""}
            </Text>
          )}
          <Button
            onClick={onEdit}
            disabled={loading}
            bcolor="greenTransparent"
            iconPosition="afterText"
            icon={<HiPencilAlt size={23} className="ts-success-secondary mx-2  -rotate-90" />}
            className="h-10 -ml-5 -mt-1.5"
          >
            ویرایش
          </Button>
        </div>
        <div
          className={`w-full flex justify-between
           ${snap.carStatus.buildYear && snap.carStatus.fuelType ? "items-end" : "items-end"}`}
        >
          <div className="flex flex-col justify-end items-start text-white">
            {loading ? (
              <Skeleton baseColor="#8b8d8b" className="w-24 h-5" />
            ) : (
              <Text type="H6" className="ts-gray-03">
                {tools.getFullPlate(snap.carStatus.plateNumber)}
              </Text>
            )}
            {loading ? (
              <Skeleton baseColor="#8b8d8b" className="w-14 h-4" />
            ) : (
              (snap.carStatus.buildYear || snap.carStatus.fuelType) && (
                <Text type="HM" className="ts-gray-03 mt-2">
                  {snap.carStatus.fuelType
                    ? fuelTypes.filter((item) => item.value === snap.carStatus.fuelType)[0].label
                    : ""}
                  {`${snap.carStatus.buildYear && snap.carStatus.fuelType ? " - " : ""}`}
                  {`${snap.carStatus.buildYear || ""}`}
                </Text>
              )
            )}
          </div>
          {loading ? (
            <Skeleton baseColor="#8b8d8b" className="w-24 h-5" />
          ) : (
            <div className="flex justify-center">
              <img src={kmIcon} alt="icn" className=" -mt-1" />
              <Text type="H6" inline className="text-white">
                {snap.carStatus.kilometer
                  ? `${tools.thousandSeparator(snap.carStatus.kilometer)} کیلومتر`
                  : "بدون کارکرد"}
              </Text>
            </div>
          )}
        </div>
      </div> */}
    </>
  );
});

export { CustomHeader };
