/* eslint-disable */
import { axios } from "./api";

interface IPart {
  name: string;
  car: string;
  price: number | string;
  manufacture: string;
  grouping: string;
  manufacturingCountry: string;
  code?: string;
  description?: string;
}

const baseUrl = "/shops";

const shop = {
  getParts: async (
    page: number,
    sort: "name" | "newest" | "oldest" | "nearest" | "",
    location: number[] | null,
    query: string | null,
    filter: any,
    grouping: string | null,
  ) => {
    const url = `${baseUrl}/search`;
    const result = await axios.post(url, {
      pageSize: 50,
      page,
      ...(sort ? { sort } : undefined),
      ...(sort === "nearest" && location
        ? { currentLocation: { latitude: location[0], longitude: location[1] } }
        : undefined),
      ...(query ? { query } : undefined),
      ...(filter.car ? { carId: filter.car.id } : undefined),
      ...(filter.manufacture ? { manufacture: filter.manufacture } : undefined),
      ...(filter.manufacturingCountry ? { manufacturingCountry: filter.manufacturingCountry } : undefined),
      // ...(filter.cityId ? { cityId: filter.cityId } : undefined),
      ...(grouping ? { grouping } : undefined),
    });

    return result;
  },
  getPartInfo: async (id: string) => {
    const url = `${baseUrl}/${id}`;
    const result = await axios.get(url);

    return result;
  },
};

export { shop };
