/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { ApiInstance, vState } from "../../../../core";
import { BottomModal, Button, Text, useNotification } from "../../../components";

interface IDeleteReminderModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDone: () => void;
  service: any;
  serviceId: string;
  id: string;
}

const DeleteReminderModal: React.FC<IDeleteReminderModalProps> = React.memo(
  ({ isOpen, onClose, onDone, service, serviceId, id }) => {
    const navigate = useNavigate();
    const snap = useSnapshot(vState);
    const [loading, setLoading] = useState(false);

    useEffect(() => {}, []);

    const deleteReminderHandler = async () => {
      setLoading(true);
      await ApiInstance.car
        .deleteReminder(id, serviceId)
        .then((res) => {
          console.log(res);
          useNotification("یادآور حذف شد.", "success");
          setTimeout(() => {
            onDone();
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
          useNotification("مشکلی رخ داده است. لطفا مجددا تلاش کنید.", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    return (
      <BottomModal isOpen={isOpen} onClose={onClose}>
        <div className="w-full flex flex-col justify-start items-start">
          <div className="flex justify-end items-center mb-4">
            <Text type="H4" className="ts-accent">
              حذف یادآور
            </Text>
          </div>
          <Text type="P3" className="ts-accent mb-8">
            آیا میخواهید یادآور <b>{service}</b> را حذف کنید؟‌!در اینصورت، یادآور از لیست شما حذف میشود اما همچنان در
            تاریخچه یادآورها در دسترس خواهد بود.
          </Text>
          <div className=" w-full flex justify-between items-center">
            <Button
              onClick={deleteReminderHandler}
              loading={loading}
              bcolor="primary"
              textType="H6"
              classNameContainer="ts-half-width"
              className="w-full h-12"
            >
              بله، حذف شود
            </Button>

            <Button
              onClick={() => onClose()}
              bcolor="primaryTransparent"
              textType="H6"
              classNameContainer="ts-half-width"
              className="w-full h-12"
            >
              انصراف
            </Button>
          </div>
        </div>
      </BottomModal>
    );
  },
);

export { DeleteReminderModal };
