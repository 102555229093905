import Axios from "axios";
import { ConfigLink } from "core/routes";
import { vState } from "core/valtio";
import NProgress from "nprogress";
import { useNotification } from "ui/components";
import { configs } from "../../configs";
import { keycloak } from "../../keycloak";
import { ServiceStorage } from "../services";

NProgress.configure({ showSpinner: false });

export const axios = Axios.create({
  baseURL: configs().endpointUrl,
});

axios.interceptors.request.use(
  (config) => {
    if (keycloak.authenticated && keycloak.idToken) {
      config.headers = {
        ...(config.headers || {}),
        Authorization: `Bearer ${keycloak.idToken}`,
      };
    }
    NProgress.start();
    return config;
  },
  (e) => Promise.reject(e),
);

axios.interceptors.response.use(
  (res) => {
    NProgress.done();
    return res;
  },
  (err) => {
    NProgress.done();
    if (err.response.status === 401) {
      console.log("401");
      const savedToken = ServiceStorage.getToken();
      if (keycloak.idToken && keycloak.idToken !== savedToken) {
        console.log("SAVED", savedToken);
        console.log("KEYCLOAK", keycloak.idToken);

        ServiceStorage.setToken(keycloak.idToken);
        err.config.headers["Authorization"] = `Bearer ${keycloak.idToken}`;
        axios.request(err.config);
      } else {
        useNotification("لطفا دوباره وارد شوید.", "warning", "اتمام توکن");
        vState.isAuthenticated = false;
        vState.userInfo = {
          id: "",
          name: "",
          username: "",
          phoneNumber: "",
          roles: [],
          userProfileId: "",
          mobile: "",
        };
        ServiceStorage.removeAuth();
        ServiceStorage.removeToken();
        if (window.location.pathname !== ConfigLink.profile) {
          ServiceStorage.setRedirectUrl(`${window.location.href}${window.location.search}`);
        }
        keycloak.logout();
        window.location.href = `/intro`;
      }
    }
    return Promise.reject(err);
  },
);
