/* eslint-disable */
import React, { useEffect, useState } from "react";
import { CheckPicker } from "rsuite";
import { useSnapshot } from "valtio";
import { ApiInstance, vState } from "../../../../core";
import { BottomModal, Button, Text } from "../../../components";

interface IFilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  filter: string[];
  onFilterChanged: (f: string[]) => void;
}

const FilterModal: React.FC<IFilterModalProps> = React.memo(({ isOpen, onClose, filter, onFilterChanged }) => {
  const picker = React.useRef();
  const snap = useSnapshot(vState) as typeof vState;

  const [filters, setFilters] = useState<any[]>([]);

  const getCategories = async () => {
    await ApiInstance.common.getUI();
  };

  useEffect(() => {
    setFilters(filter);
  }, [filter]);

  useEffect(() => {
    if (snap.categories.length === 0) {
      getCategories();
    }
  }, [isOpen]);

  return (
    <BottomModal isOpen={isOpen} onClose={onClose}>
      <Text type="H4" className="ts-accent text-right mb-8">
        فیلتر کسب و کارها
      </Text>
      <Text type="H6" className="ts-accent text-right mb-2">
        نوع کسب و کار
      </Text>
      <CheckPicker
        size="lg"
        name="workshop_type"
        placement="auto"
        // @ts-ignore
        ref={picker}
        data={snap.categories}
        value={filters}
        onChange={(value) => setFilters(value)}
        onClean={() => setFilters([])}
        block
        cleanable
        searchable={false}
        countable={false}
        placeholder="نوع کسب و کار خود را انتخاب کنید"
        menuClassName="ts-workshops-picker"
        className={`ts-filter-picker w-full h-12 mb-8 outline-none rounded-xl`}
        renderExtraFooter={() => (
          <div className="w-full py-3 px-5 border-t-2 flex items-center">
            <Button
              bcolor="primaryTransparent"
              onClick={() => {
                //@ts-ignore
                picker.current.close();
              }}
              className="w-full rounded-xl h-11"
              classNameContainer="w-full"
            >
              تایید و بستن
            </Button>
          </div>
        )}
      />
      <Button
        onClick={() => {
          onFilterChanged(filters);
          onClose();
        }}
        bcolor="primary"
        classNameContainer="w-full"
        className="w-full h-12 rounded-xl"
      >
        اعمال فیلتر
      </Button>
    </BottomModal>
  );
});

export { FilterModal };

const styles = {};
