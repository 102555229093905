/* eslint-disable */
import React from "react";
import { HiExclamation } from "react-icons/hi";
import { BottomModal, Button, Text } from "../../../components";

interface IReminderUpdateAlertProps {
  isOpen: boolean;
  onClose: () => void;
}

const ReminderUpdateAlert: React.FC<IReminderUpdateAlertProps> = React.memo(({ isOpen, onClose }) => {
  return (
    <BottomModal centerScreen staticBackdrop isOpen={isOpen} onClose={onClose}>
      <div className="w-full flex flex-col justify-center items-center -mt-2">
        <HiExclamation size={100} className="ts-warning" />
        <Text type="H3" className="ts-accent mb-6 mt-5">
          بروز رسانی کارکرد
        </Text>
        <Text type="P4" className="ts-accent mb-6">
          باتوجه به اینکه کارکرد جدید وارد شده، از کارکرد قبلی کمتر میباشد، لازم است یادآورهای ثبت شده خود را مرور و در
          صورت نیاز آن‌ها را به صورت دستی ویرایش نمایید.
        </Text>

        <Button onClick={onClose} bcolor="primary" classNameContainer="w-full" className="w-full h-12">
          متوجه شدم
        </Button>
      </div>
    </BottomModal>
  );
});

export { ReminderUpdateAlert };

const styles = {};
