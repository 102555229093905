/* eslint-disable */
import { configs } from "#configs";
import { ConfigLink, useQueryString } from "core";
import React from "react";
import { HiCheckCircle } from "react-icons/hi";
import { MdAccessTimeFilled, MdChevronLeft } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import defaultCar from "../../../../assets/images/part-request-default.svg";
import { Text } from "../../../components";

interface IPartRequestCardProps {
  data: any;
}

const PartRequestCard: React.FC<IPartRequestCardProps> = React.memo(({ data }) => {
  const navigate = useNavigate();

  const requestClickHandler = () => {
    navigate({ pathname: ConfigLink.partRequestDetail, search: useQueryString({ id: data.id }) });
  };

  return (
    <div
      onClick={requestClickHandler}
      key={data.id}
      className="w-full flex justify-between items-center p-3 bg-white rounded-2xl ts-shadow mb-4 cursor-pointer"
    >
      <div className="flex h-full flex-col justify-between items-start">
        <div className="flex justify-start items-start mb-3">
          <div className="w-12 h-12">
            <img
              src={data.images && data.images.length !== 0 ? `${configs().imageUrl}/${data.images[0]}` : defaultCar}
              alt="default"
              className={`w-full h-full ${
                data.images && data.images.length !== 0 ? "object-cover aspect-square rounded-xl" : ""
              }`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = defaultCar;
              }}
            />
          </div>
          <div className="flex flex-col justify-start items-start mr-3">
            <Text type="H6" className="ts-accent">
              {data.name}
            </Text>
            <Text type="P4" className="ts-gray-07 mt-2">
              {typeof data.car === "object" ? data.car.name : data.car}
            </Text>
          </div>
        </div>
        <div className="flex justify-center items-center">
          {data.suppliers.length === 0 ? (
            <MdAccessTimeFilled size={18} className="ts-warning -mt-0.5" />
          ) : (
            <HiCheckCircle size={18} className="ts-success -mt-0.5" />
          )}
          <Text type="HM" className={`${data.suppliers.length === 0 ? "ts-warning" : "ts-success"} mr-1`}>
            {data.suppliers.length === 0
              ? "تاکنون تامین کننده‌ای پیدا نشده است."
              : `${data.suppliers.length} تامین کننده میتوانند این قطعه را تامین کنند.`}
          </Text>
        </div>
      </div>
      <MdChevronLeft size={25} className="ts-accent" />
    </div>
  );
});

export { PartRequestCard };

const styles = {};
