import React from "react";
import bazar from "../../../../assets/images/bazar-btn.png";
import myket from "../../../../assets/images/myket-btn.png";

interface IStoreButtonsProps {
  type: "owner" | "provider";
  platform: "bazar" | "myket";
}

const StoreButtons: React.FC<IStoreButtonsProps> = ({ type, platform }) => {
  const downloadHandler = () => {
    if (typeof window !== "undefined") {
      window.open(`https://${platform === "bazar" ? "cafebazaar" : "myket"}.ir/app/ir.virazh.${type}.twa`, "_blank");
    }
  };

  return (
    <div className={`cursor-pointer rounded-md mb-2 w-48 ts-download-button-wrapper`}>
      <img
        onClick={downloadHandler}
        src={`${platform === "bazar" ? bazar : myket}`}
        alt={platform}
        className={`w-full h-full object-scale-down rounded-md`}
      />
    </div>
  );
};

export { StoreButtons };
