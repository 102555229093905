/* eslint-disable */
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import historyImage from "../../../assets/images/history-image.svg";
import { ApiInstance, useChangeTitle, vState } from "../../../core";
import { useFetchQueryString } from "../../../core/hooks";
import { ContainerScroll, Text } from "../../components";
import { HistoryCard, HistoryCardLoader } from "./components";

interface IQueryStringTransfer {
  id: string;
}

const History = () => {
  const metaTags = useChangeTitle("ویراژ - تاریخچه یادآورها");
  const snap = useSnapshot(vState);
  const navigate = useNavigate();
  let { id } = useFetchQueryString<IQueryStringTransfer>();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [car, setCar] = useState("");

  const getHistory = async () => {
    await ApiInstance.car
      .getCarHistory(id)
      .then((res) => {
        console.log(res);
        setData(res.data.data);
        setCar(res.data.plate.carName || "");
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getHistory();
  }, []);

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={100} className="">
        {loading ? (
          <Skeleton className="w-52 h-7" />
        ) : (
          <Text type="H4" className="ts-accent">
            {`تاریخچه یادآورهای ${car}`}
          </Text>
        )}

        <img src={historyImage} alt="history-img" className="my-6 mx-auto" />

        {loading ? (
          <HistoryCardLoader />
        ) : (
          data.map((item: any) => {
            return <HistoryCard plateId={id} key={item.id} data={item} />;
          })
        )}
      </ContainerScroll>
    </>
  );
};

export { History as default };
