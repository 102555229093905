/* eslint-disable */
import prototype from "assets/images/car-prototype.svg";
import kmIcon from "assets/images/kilomemter-icon.svg";
import { ConfigLink, ServiceStorage, tools, useQueryString, vState } from "core";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { IoCarSport } from "react-icons/io5";
import { MdAddCircle, MdChevronLeft } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { Divider } from "rsuite";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { ReminderCircleProgress, Text } from "ui/components";
import { useSnapshot } from "valtio";
import { AddCarCard } from "./AddCarCard";

interface ICarsListProps {
  carChanged: (value: string | number) => void;
  addReminderClick: () => void;
  addCarClick: () => void;
  reminderClick: (id) => void;
  addCarSlideReached: () => void;
  initialIndex: number;
}

const CarsList: React.FC<ICarsListProps> = React.memo(
  ({ carChanged, addReminderClick, reminderClick, addCarSlideReached, addCarClick, initialIndex }) => {
    const snap = useSnapshot(vState) as typeof vState;
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [reminders, setReminders] = useState<any[]>([]);

    const today = new Date().toISOString();
    const daysDiff =
      snap.ownerCars.length === 0
        ? 0
        : moment(today).diff(
            snap.ownerCars[activeIndex] ? snap.ownerCars[activeIndex].kilomeeterCreatedAt : "",
            "days",
          );

    useEffect(() => {
      console.log(snap.reminders);
      setReminders(snap.reminders.length < 2 ? snap.reminders : snap.reminders.slice(0, 2));
      setLoading(false);
    }, [snap.reminders]);

    const carDetailHandler = (id) => {
      ServiceStorage.setTempReturnURL(ConfigLink.homePage);
      navigate({
        pathname: ConfigLink.carStatus,
        search: useQueryString({ id }),
      });
    };

    const debouncedFetchData = _.debounce((idx) => {
      console.log(idx);
      setLoading(true);
      setActiveIndex(idx);
      const id = snap.ownerCars[idx] ? snap.ownerCars[idx].id : "";
      if (id) {
        carChanged(id);
      }
    }, 800);

    const slideChangeHandler = (e) => {
      if (e.activeIndex < snap.ownerCars.length) {
        debouncedFetchData(e.activeIndex);
      } else {
        addCarSlideReached();
      }
    };

    return (
      <>
        <Swiper
          slidesPerView={1}
          initialSlide={initialIndex}
          spaceBetween={20}
          speed={100}
          threshold={4}
          modules={[Pagination]}
          pagination={{
            clickable: true,
            el: ".swiper-car-custom-pagination",
          }}
          onSlideChange={(e) => slideChangeHandler(e)}
        >
          {snap.ownerCars.map((item, index) => {
            return (
              <SwiperSlide key={index} className={`w-full ts-cars-list-box-height`}>
                <div className="w-full ts-shadow flex flex-col justify-start items-start rounded-xl p-4 mb-3 ts-cars-card-bg ts-cars-list-box-height">
                  <div onClick={() => carDetailHandler(item.id)} className="w-full cursor-pointer">
                    <span className="w-full flex justify-between items-center mb-4">
                      <Text type="H5" className="ts-gray-08">
                        {item.car ? item.car.name : item.carName ? item.carName : "---"}
                      </Text>

                      <span className="flex justify-center items-center cursor-pointer">
                        <Text type="H6" className="ts-primary ml-1">
                          جزییات
                        </Text>
                        <MdChevronLeft size={21} className="ts-primary -mt-1" />
                      </span>
                    </span>

                    <span className="w-full flex justify-start items-start mb-4">
                      <span className="flex flex-col justify-start items-start">
                        <Text type="P4" className="ts-gray-06 mb-1">
                          کارکرد (کیلومتر)
                        </Text>
                        <span className="flex justify-center items-center">
                          <span className="w-6 h-6 flex justify-center items-center ts-bg-primary-light rounded-full ml-1">
                            <img src={kmIcon} alt="icn" className="" />
                          </span>
                          <Text type="H5" className="ts-gray-07">
                            {item.kilometer ? tools.thousandSeparator(item.kilometer) : "---"}
                          </Text>
                        </span>
                      </span>

                      <Divider vertical className="h-12" />

                      <span className="flex flex-col justify-start items-start">
                        <Text type="P4" className="ts-gray-06 mb-1">
                          پلاک خودرو
                        </Text>
                        <span className="flex justify-center items-center">
                          <span className="w-6 h-6 flex justify-center items-center ts-bg-primary-light rounded-full ml-1">
                            <IoCarSport size={18} className="ts-primary" />
                          </span>
                          <Text type="H5" className="ts-gray-07">
                            {tools.getFullPlate(item.plateNumber)}
                          </Text>
                        </span>
                      </span>
                    </span>

                    <img src={prototype} alt="car" className="m-auto mb-4" />
                  </div>

                  {loading ? (
                    <div className="w-full flex justify-center items-center -mt-2 mb-2">
                      {[1, 2, 3].map((item) => {
                        return (
                          <React.Fragment key={item}>
                            <span className="flex flex-col justify-center items-center cursor-pointer">
                              <Skeleton className="ts-reminder-circle mb-2 rounded-full" />
                              <Skeleton className="w-20 h-5 mb-2" />
                              <Skeleton className="w-20 h-5" />
                            </span>
                            {item !== 3 && <Divider vertical className="h-36" />}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="w-full flex justify-center items-start">
                      {reminders.map((remind) => {
                        return (
                          <React.Fragment key={remind.id}>
                            <ReminderCircleProgress data={remind} onClick={() => reminderClick(remind.id)} />
                            <Divider vertical className="h-36" />
                          </React.Fragment>
                        );
                      })}
                      <span
                        onClick={addReminderClick}
                        className="flex flex-col justify-center items-center cursor-pointer"
                      >
                        <span className="ts-reminder-circle ts-add-reminder-circle flex justify-center items-center mb-2">
                          <MdAddCircle size={20} className="ts-gray-03" />
                        </span>
                        <Text type="HM" className="ts-gray-06 w-20 text-center">
                          اضافه کردن یادآور جدید
                        </Text>
                      </span>
                    </div>
                  )}

                  <span className="w-full flex justify-center items-center mt-6">
                    <Text type="P5" className="ts-gray-06 text-center ">
                      آخرین بروزرسانی:{" "}
                      {item.kilomeeterCreatedAt ? tools.convertDateToPersianDate(item.kilomeeterCreatedAt) : "---"} (
                      {daysDiff === 0 ? "امروز" : `${daysDiff} روز گذشته`})
                    </Text>
                  </span>
                </div>
              </SwiperSlide>
            );
          })}

          <SwiperSlide className={`w-full pl-0.5 ts-cars-list-box-height`}>
            <AddCarCard type="extra" onClick={addCarClick} />
          </SwiperSlide>
        </Swiper>
        <div className="swiper-car-custom-pagination" />
      </>
    );
  },
);

export { CarsList };

const styles = {};
