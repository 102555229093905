/* eslint-disable */
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import partRequest from "../../../../assets/images/part-request-icon.svg";
import shop from "../../../../assets/images/shop-search-icon.svg";
import search from "../../../../assets/images/workshop-search-icon.svg";
import { ConfigLink, vState } from "../../../../core";
import { Text } from "../../../components";

interface IMostUsedServicesProps {}

const MostUsedServices: React.FC<IMostUsedServicesProps> = React.memo(() => {
  const snap = useSnapshot(vState);
  const navigate = useNavigate();

  const operator =
    snap.userInfo.roles &&
    (snap.userInfo.roles.indexOf("operator") !== -1 || snap.userInfo.roles.indexOf("visitor") !== -1);

  const features = snap.activeFeatures;

  return (
    <div className="w-full flex flex-col justify-start items-start my-6">
      <Text type="H5" className="ts-accent mb-3">
        خدمات پرکاربرد
      </Text>
      <span className="w-full flex justify-between items-center gap-2">
        {features.includes("workshop_search") && (
          <ServiceButton icon={search} label={"جستجوی\nکسب‌و‌کار"} onClick={() => navigate(ConfigLink.search)} />
        )}

        {features.includes("shop_search") && (
          <ServiceButton icon={shop} label={"جستجوی\nقطعات"} onClick={() => navigate(ConfigLink.shop)} />
        )}
        {features.includes("part_order") && (
          <ServiceButton
            icon={partRequest}
            label={"درخواست\nقطعات‌کمیاب"}
            onClick={() => navigate(ConfigLink.partRequest)}
          />
        )}
      </span>
    </div>
  );
});

export { MostUsedServices };

const ServiceButton = ({ icon, label, onClick }) => {
  return (
    <span
      onClick={onClick}
      style={{ height: "138px" }}
      className="w-full flex flex-col justify-center items-center rounded-xl ts-shadow cursor-pointer ts-most-used-btn p-4"
    >
      <img src={icon} alt="icon" className="w-12 h-12" />
      <Text type="P4" className="ts-accent mt-3 text-center whitespace-pre">
        {label}
      </Text>
    </span>
  );
};
