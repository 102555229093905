/* eslint-disable */
import { useChangeTitle } from "../../../core/hooks";
import { ContainerScroll, Text } from "../../components";

const ContactUs = ({}) => {
  const metaTags = useChangeTitle("ویراژ - تماس با ما");

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={50} className="">
        <Text type="H3" className="ts-accent mb-6">
          تماس با ما
        </Text>

        <div className="w-full p-4 rounded-2xl ts-bg-primary-light flex flex-col justify-start items-start">
          <Text type="H5" className="ts-gray-07">
            اطلاعات تماس
          </Text>
          <Text type="P4" className="ts-gray-06 my-4">
            پاسخگویی در روز های کاری از 9 الی 17 می باشد.
          </Text>
          <div className="flex justify-start items-center mb-4">
            <Text type="P4" className="ts-accent ts-text-space">
              شماره ثابت:
            </Text>
            <Text
              onClick={() => window.open("tel:02191693862", "_self")}
              type="P4"
              className="ts-primary cursor-pointer"
            >
              021-91693862
            </Text>
          </div>
          <div className="flex justify-start items-center">
            <Text type="P4" className="ts-accent ts-text-space">
              شماره همراه:
            </Text>
            <Text
              onClick={() => window.open("tel:+989207901785", "_self")}
              type="P4"
              className="ts-primary cursor-pointer"
            >
              09207901785
            </Text>
          </div>
        </div>
      </ContainerScroll>
    </>
  );
};

export { ContactUs as default };
