/* eslint-disable */
import React from "react";
import { useNavigate } from "react-router-dom";
import { ReminderLoader } from "ui/views/Car/components";
import { useSnapshot } from "valtio";
import noReminder from "../../../../assets/images/no-reminder.svg";
import { ConfigLink, vState } from "../../../../core";
import { ReminderCard, Text } from "../../../components";

interface IRegisteredRemindersProps {
  onButtonClicked?: () => void;
  reminderLoading?: boolean;
  kilometerRegistered?: boolean;
  returnUrl?: string;
  moreMargin?: boolean;
  plate?: string;
  updateNeeded?: () => boolean;
}

const RegisteredReminders: React.FC<IRegisteredRemindersProps> = ({
  onButtonClicked,
  reminderLoading = false,
  kilometerRegistered = true,
  returnUrl,
  moreMargin = false,
  plate,
  updateNeeded,
}) => {
  const snap = useSnapshot(vState);
  const navigate = useNavigate();
  const needUpdate: boolean = updateNeeded ? updateNeeded() : false;

  const registerServiceHandler = () => {
    if (onButtonClicked) {
      onButtonClicked();
    }
  };

  return (
    <>
      <div className={`w-full flex flex-col justify-start items-start mt-6 ${moreMargin && "max-2xs:px-3 px-6"}`}>
        {kilometerRegistered && (
          <Text type="H5" className="ts-accent text-right mb-3">
            یادآورهای ثبت شده
          </Text>
        )}
        {reminderLoading ? (
          <ReminderLoader />
        ) : snap.reminders.length !== 0 ? (
          <>
            {snap.reminders.map((item: any) => {
              return (
                <ReminderCard
                  data={item}
                  returnURL={returnUrl || ConfigLink.homePage}
                  goToDetailPage
                  plate={plate}
                  updateNeeded={!needUpdate}
                />
              );
            })}
          </>
        ) : (
          kilometerRegistered && (
            <div className="w-full flex flex-col justify-center items-center">
              <img src={noReminder} alt="noReminder" className="mb-2 mt-3" />
              <Text type="P3" className="ts-gray-07">
                هیچ یادآوری برای شما ثبت نشده است
              </Text>
            </div>
          )
        )}
      </div>
    </>
  );
};

export { RegisteredReminders };

const styles = {};
