/* eslint-disable */
import React, { useEffect, useState } from "react";
import { IoAddCircleSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { Loader } from "rsuite";
import banner from "../../../assets/images/setup-banner.svg";
import { ApiInstance, ConfigLink, ServiceStorage, useChangeTitle } from "../../../core";
import { Button, SetupCarTemplate, Text, useNotification } from "../../components";
import { CarsCard, DeleteCarModal } from "../Car/components";

interface ISetupIntroProps {}

const env = import.meta.env;

const SetupIntro: React.FC<ISetupIntroProps> = React.memo(({}) => {
  const metaTags = useChangeTitle("ویراژ - خوش آمدید");
  const navigate = useNavigate();

  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteCarModal, setDeleteCarModal] = useState(false);
  const [selectedCar, setSelectedCar] = useState<any>(null);

  const addCarHandler = () => {
    navigate(ConfigLink.createCar);
  };

  const getCars = async () => {
    await ApiInstance.plates
      .getUserCars(true)
      .then((res) => {
        setCars(res.data.data);
      })
      .catch((err) => {
        useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا تلاش کنید.", "warning", "لیست خودروها");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCars();
    ServiceStorage.removeSetupReturnURL();
  }, []);

  const deleteCarHandler = (data) => {
    setSelectedCar(data);
    setDeleteCarModal(true);
  };

  return (
    <>
      <SetupCarTemplate metaTag={metaTags} className="-mt-14" wrapperClassName="mt-8">
        <img src={banner} alt="banner" className="mb-8 w-full" />
        <Text type="H3" className="ts-accent">
          به ویراژ خوش آمدید
        </Text>
        <Text type="P3" className="ts-gray-08 mt-4 mb-8">
          برای ثبت پلاک و ایجاد یادآور لازم است اطلاعات خودرو خود را وارد کنید. پس از اضافه شدن خودرو خود، میتوانید از
          تمامی خدمات ویراژ استفاده کنید.
        </Text>
        {loading ? (
          <div className="w-full flex justify-center items-center mt-1 mb-8">
            <Loader size="sm" />
          </div>
        ) : (
          cars.length > 0 && (
            <>
              <Text type="H4" className="ts-accent mt-1">
                خودرو های شما
              </Text>
              <Text type="P5" className="ts-gray-06 my-3">
                درصورتی که خودروهای نمایش داده شده مغایر از خودرو های شما می‌باشد، می‌توانید آن را حذف نمایید. یا خودروی
                جدید اضافه کنید.
              </Text>
              {cars.map((item) => {
                return <CarsCard lowMargin forSetup data={item} onDelete={() => deleteCarHandler(item)} />;
              })}
            </>
          )
        )}
        <Button
          onClick={addCarHandler}
          icon={<IoAddCircleSharp size={21} className="text-white ml-3" />}
          textType="H6"
          bcolor="primary"
          classNameContainer="w-full"
          className={`w-full h-12 rounded-xl `}
        >
          اضافه کردن خودرو
        </Button>
      </SetupCarTemplate>
      <DeleteCarModal
        id={selectedCar ? selectedCar?.id : ""}
        onDeleteEnded={getCars}
        isOpen={deleteCarModal}
        onClose={() => setDeleteCarModal(false)}
      />
    </>
  );
});

export { SetupIntro as default };

const styles = {};
