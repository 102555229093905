/* eslint-disable */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { ApiInstance, ConfigLink, useFetchQueryString, vState } from "../../../core";
import { useNotification } from "../../components";
import { useAuth } from "./../../../hooks/auth";

interface ICompleteInviteProps {} //INTRODUCTION PAGES

interface IQueryStringTransfer {
  code: string;
}

const env = import.meta.env;

const CompleteInvite: React.FC<ICompleteInviteProps> = React.memo(({}) => {
  const auth = useAuth();
  const snap = useSnapshot(vState);
  const navigate = useNavigate();
  let { code } = useFetchQueryString<IQueryStringTransfer>();

  const redirectHandler = () => {
    navigate(ConfigLink.createCarWelcome, { replace: true });
  };

  const registerInvitation = async () => {
    await ApiInstance.account
      .completeInvitation(code)
      .then((res) => {
        console.log(res);
        redirectHandler();
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است و در حال حاضر امکان ثبت درخواست وجود ندارد.", "error", "تکمیل دعوت");
        setTimeout(() => {
          redirectHandler();
        }, 2000);
      });
  };

  useEffect(() => {
    if (auth.ready) {
      if (auth.authenticated) {
        if (code) {
          registerInvitation();
        }
      }
    }
  }, [auth.ready, auth.authenticated]);

  return (
    <>
      <div></div>
    </>
  );
});

export { CompleteInvite as default };

const styles = {};
