/* eslint-disable */
import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import user from "../../../../assets/images/user.svg";
import { Text } from "../../../components";

interface IProfileLoaderProps {}

const ProfileLoader: React.FC<IProfileLoaderProps> = React.memo(() => {
  return (
    <>
      <div className="w-full flex justify-start items-center mb-5">
        <img src={user} alt="tst-user" className="w-14 h-14 rounded-full ml-4"></img>
        <div className="flex flex-col justify-between items-start">
          <Skeleton className={`w-32 mb-2 -mr-1`} />

          <Text type="P3" className="ts-gray-05">
            مالک خودرو
          </Text>
        </div>
      </div>

      {[1, 2, 3, 4, 5].map((item) => (
        <Skeleton
          key={item}
          style={{ height: "72px" }}
          containerClassName="w-full"
          className={`w-full mb-4 rounded-xl`}
        />
      ))}
    </>
  );
});

export { ProfileLoader };

const styles = {};
