/* eslint-disable */
import moment from "moment";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { Progress } from "rsuite";
import { useSnapshot } from "valtio";
import { ApiInstance, useChangeTitle, useFetchQueryString, vState } from "../../../core";
import { Button, ContainerScroll, Text } from "../../components";
import { KilometerUpdateModal } from "../Home/components";
import { DeleteReminderModal, RegisterNewService } from "./components";

interface IReminderDetailProps {
  forModal?: boolean;
  info?: IQueryStringTransfer;
  onDone?: () => void;
  carPlateId?: string;
}

interface IQueryStringTransfer {
  id: string;
  currentKilometer: string | number;
  plateId?: string;
  plate?: string;
}
const env = import.meta.env;

const ReminderDetail: React.FC<IReminderDetailProps> = React.memo(({ forModal = false, info, onDone, carPlateId }) => {
  const metaTags = useChangeTitle("ویراژ - وضعیت خودرو");
  const navigate = useNavigate();
  const snap = useSnapshot(vState);
  let { id, currentKilometer, plate, plateId } = useFetchQueryString<IQueryStringTransfer>();

  const [data, setData] = useState({
    committed: true,
    createdAt: "",
    description: "",
    expiresInKilometers: 0,
    expiresInDays: null,
    id: "",
    kilometer: 0,
    updatedAt: "",
    service: {
      name: "",
      expiresInDays: 0,
      expiresType: "kilometers",
      id: "",
      description: "",
    },
  });
  const [loading, setLoading] = useState(true);
  const [reminderModal, setReminderModal] = useState(false);
  const [updateKilometerModal, setUpdateKilometerModal] = useState(false);
  const [deleteReminderModal, setDeleteReminderModal] = useState(false);

  const getServiceHistory = async () => {
    await ApiInstance.common
      .getServiceDetail(plateId || plate || carPlateId || "", info ? info.id : id)
      .then((res) => {
        console.log("history", res);
        setData(res.data.data);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getServiceHistory();
  }, []);

  const registerServiceHandler = () => {
    if (info ? info.plate || false : plate) {
      setUpdateKilometerModal(true);
    } else {
      setReminderModal(true);
    }
  };

  const today = moment();
  const nextChangeTime =
    data.updatedAt && data.expiresInDays ? moment(data.updatedAt).add(data.service.expiresInDays, "days") : 0;
  const nextChangeDays =
    data.updatedAt && data.expiresInDays
      ? moment(data.updatedAt).add(data.expiresInDays, "days").diff(moment(data.updatedAt), "days")
      : 0;
  const nextChangeDaysAccordingToToday =
    nextChangeTime && data.expiresInDays ? moment(nextChangeTime).diff(today, "days") : 0;
  const daysPercentage = 100 - +(+nextChangeDaysAccordingToToday / +nextChangeDays).toFixed(2) * 100;

  const daysRemained = nextChangeTime ? nextChangeTime.diff(today, "days") : -1000;
  const isTimePassed = nextChangeTime ? nextChangeTime.diff(today, "days") <= 0 : false;

  const currentKM = info ? +info.currentKilometer : +currentKilometer || 0;
  const nextKM = +(data.kilometer + data.expiresInKilometers);
  const remainKM = nextKM - currentKM;
  const percentage =
    currentKM > nextKM || remainKM === 0 ? 100 : 100 - +(remainKM / data.expiresInKilometers).toFixed(2) * 100;

  const deleteReminderDoneHandler = () => {
    if (forModal) {
      onDone && onDone();
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <ContainerScroll heightOffset={0} noPadding className={`relative`}>
        <div
          className={`${
            forModal ? "w-full" : "w-auto rounded-2xl mt-14 py-6 mx-6 max-2xs:px-3"
          } flex flex-col justify-start items-start`}
        >
          <div className="w-full flex justify-between items-center mb-6">
            {loading ? (
              <Skeleton className="w-14" />
            ) : (
              <Text type="H4" className="ts-gray-08">
                {data.service.name || "بدون نام"}
              </Text>
            )}

            {isTimePassed && data.committed && (
              <div className="p-2 -mt-px flex justify-center items-center ts-bg-light-red rounded-xl">
                <Text type="HM" className="ts-secondary-red">
                  موعد زمانی گذشته
                </Text>
              </div>
            )}
          </div>
          {loading ? (
            <>
              <Skeleton containerClassName="w-full" className="w-full h-4" />
              <Skeleton className="w-36 h-4" />
            </>
          ) : (
            <Text type="P4" className="ts-gray-07 mb-4">
              آخرین {data.service.name}، در تاریخ{" "}
              <b className="ts-accent font-bold">{new Date(data.updatedAt).toLocaleDateString("fa-IR")}</b> و کیلومتر{" "}
              <b className="ts-accent font-bold">{data.kilometer}</b> انجام شده است.
            </Text>
          )}

          {loading ? (
            <Skeleton style={{ height: "68px" }} containerClassName="w-full" className="w-full mt-4 mb-3 rounded-2xl" />
          ) : (
            data.service.expiresType !== "days" && (
              <div className="w-full mb-3 ts-bg-gray-01 rounded-2xl p-4 flex flex-col justify-start items-start">
                <Text type="P4" className="ts-accent mb-2">
                  {`${
                    !data.committed
                      ? data.expiresInKilometers
                      : remainKM <= 0
                      ? "0"
                      : Math.abs(nextKM - (currentKM || 0))
                  } کیلومتر مانده تا تعویض بعدی`}
                </Text>
                <Progress.Line
                  strokeColor={percentage >= 80 ? "#CA2525" : "#272D37"}
                  strokeWidth={7}
                  percent={percentage < 0 || !data.committed ? 0 : percentage}
                  showInfo={false}
                  className="w-full p-0"
                />
              </div>
            )
          )}

          {loading ? (
            <Skeleton style={{ height: "68px" }} containerClassName="w-full" className="w-full mb-3 rounded-2xl" />
          ) : data.expiresInDays && daysRemained !== -1000 ? (
            <div className="w-full mb-3 ts-bg-gray-01 rounded-2xl p-4 flex flex-col justify-start items-start">
              <Text type="P4" className="ts-accent mb-2">
                {`${
                  daysRemained < 30
                    ? daysRemained < 0
                      ? daysRemained < -30
                        ? `${Math.round(Math.abs(daysRemained) / 30)} ماه از موعد زمانی گذشته است.`
                        : `${Math.abs(daysRemained)} روز از موعد زمانی گذشته است.`
                      : `${Math.abs(daysRemained)} روز مانده تا تعویض بعدی`
                    : `${Math.round(daysRemained / 30)} ماه مانده تا تعویض بعدی`
                }`}
              </Text>
              <Progress.Line
                strokeColor={daysPercentage >= 80 ? "#CA2525" : "#752FBB"}
                strokeWidth={7}
                percent={daysPercentage < 0 ? 0 : daysPercentage}
                showInfo={false}
                className="w-full p-0"
              />
              {daysRemained < 0 && daysRemained !== -1000 && (
                <Text type="P5" className="ts-gray-07 mt-2">
                  {`تاریخ آخرین تعویض: ${new Date(data.updatedAt).toLocaleDateString("fa-IR")}`}
                </Text>
              )}
            </div>
          ) : null}

          {loading ? (
            <Skeleton containerClassName="w-full" className="w-full h-28 mb-4 rounded-2xl" />
          ) : (
            <div className="w-full cursor-pointer mb-4 ts-bg-gray-01 rounded-2xl p-4 flex flex-col justify-start items-start">
              <Text type="H6" className="ts-accent mb-3">
                توضیحات یادآور:
              </Text>
              <Text type="P4" className="ts-gray-06">
                {data.description || "توضیحاتی برای این یادآور اضافه نشده است."}
              </Text>
            </div>
          )}
          {loading ? (
            <>
              <Skeleton containerClassName="w-full" className="w-full h-12 rounded-xl mb-4" />
              <Skeleton containerClassName="w-full" className="w-full h-12 rounded-xl" />
            </>
          ) : (
            <>
              <Button
                onClick={registerServiceHandler}
                bcolor="primaryTransparent"
                classNameContainer="w-full"
                className="w-full h-12 rounded-xl mb-4"
              >
                به روز رسانی یادآور
              </Button>
              <Button
                onClick={() => setDeleteReminderModal(true)}
                bcolor="redTransparent"
                classNameContainer="w-full"
                className="w-full h-12 rounded-xl"
              >
                حذف یادآور
              </Button>
            </>
          )}

          {forModal ? null : (
            <>
              {data.service.description && <hr className="w-full h-px my-6" />}
              {loading ? (
                <Skeleton containerClassName="w-full" className="w-full h-5 mb-4" />
              ) : (
                data.service.description && (
                  <Text type="H5" className="ts-accent mb-4">
                    {`نکاتی درباره ${data.service.name}`}
                  </Text>
                )
              )}

              {loading ? (
                <>
                  <Skeleton containerClassName="w-full" className="w-full h-4" />
                  <Skeleton containerClassName="w-full" className="w-full h-4" />
                  <Skeleton containerClassName="w-full" className="w-full h-4" />
                  <Skeleton containerClassName="w-full" className="w-3/5 h-4" />
                </>
              ) : (
                data.service.description && (
                  <Text type="P4" className="ts-gray-07">
                    {data.service.description || "---"}
                  </Text>
                )
              )}
            </>
          )}
        </div>
      </ContainerScroll>
      <RegisterNewService
        carId={info ? info.id : id}
        type="edit"
        plateId={plateId || plate || snap.carStatus.id || ""}
        editItem={data}
        currentKM={info ? +info.currentKilometer : +currentKilometer}
        onEditDone={() => (onDone ? onDone() : getServiceHistory())}
        isOpen={reminderModal}
        onClose={() => setReminderModal(false)}
      />
      <KilometerUpdateModal
        isOpen={updateKilometerModal}
        onClose={() => setUpdateKilometerModal(false)}
        onDone={() => setReminderModal(true)}
        id={info ? info.plate : plate}
        km={snap.carStatus.kilometer || undefined}
      />

      <DeleteReminderModal
        isOpen={deleteReminderModal}
        onClose={() => setDeleteReminderModal(false)}
        onDone={() => deleteReminderDoneHandler()}
        id={info ? info.id || "" : plate ? plate || "" : data.id}
        serviceId={data.service.id}
        service={data.service.name || ""}
      />
    </>
  );
});

export { ReminderDetail as default };
