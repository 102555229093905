/* eslint-disable */
import React from "react";
import Skeleton from "react-loading-skeleton";
import { Text } from "../../../components";

interface IEditCarPageLoaderProps {}

const env = import.meta.env;

const EditCarPageLoader: React.FC<IEditCarPageLoaderProps> = React.memo(() => {
  return (
    <>
      <Text type="H6" className="ts-gray-07 mb-2">
        شماره پلاک
      </Text>
      <Skeleton containerClassName="w-full" className="w-full rounded-xl mb-8 h-12" />

      <Text type="H6" className="ts-gray-07 mb-2">
        خودرو
      </Text>
      <Skeleton containerClassName="w-full" className="w-full rounded-xl mb-8 h-12" />

      <Text type="H6" className="ts-gray-07 mb-2 mt-4">
        سال ساخت
      </Text>
      <Skeleton containerClassName="w-full" className="w-full rounded-xl mb-8 h-12" />

      <Text type="H6" className="ts-gray-07 mb-2">
        نوع سوخت
      </Text>
      <Skeleton containerClassName="w-full" className="w-full rounded-xl mb-8 h-12" />

      <Text type="H6" className="ts-gray-07 mb-2">
        متوسط کارکرد در ماه
      </Text>
      <Skeleton containerClassName="w-full" className="w-full rounded-xl mb-8 h-12" />

      <div className="w-full flex justify-between items-center gap-8">
        <Skeleton containerClassName="ts-half-width" className="w-full rounded-xl h-12" />
        <Skeleton containerClassName="ts-half-width" className="w-full rounded-xl h-12" />
      </div>
    </>
  );
});

export { EditCarPageLoader };

const styles = {};
