/* eslint-disable */
import { ConfigLink, ServiceStorage, useQueryString, vState } from "core";
import moment from "moment";
import React from "react";
import { IoIosWarning } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Progress } from "rsuite";
import { useSnapshot } from "valtio";
import { Text } from "./";

interface IReminderCardProps {
  data: any;
  className?: string;
  onClick?: (data) => void;
  goToDetailPage?: boolean;
  returnURL?: string | null;
  forOwner?: boolean;
  currentKilometer?: number;
  plate?: string;
  updateNeeded?: boolean;
}

const ReminderCard: React.FC<IReminderCardProps> = React.memo(
  ({
    data,
    onClick,
    goToDetailPage = false,
    returnURL = ConfigLink.homePage,
    forOwner = false,
    currentKilometer = 0,
    className = "",
    plate,
    updateNeeded = false,
  }) => {
    const snap = useSnapshot(vState);
    const navigate = useNavigate();
    console.log("updateNeeded", updateNeeded);

    const expiresType = data.service.expiresType;

    const today = moment();
    const nextChangeTime =
      data.createdAt && data.expiresInDays ? moment(data.createdAt).add(data.expiresInDays, "days") : null;
    const isTimePassed = nextChangeTime ? nextChangeTime.diff(today, "days") <= 0 : false;
    const differenceTiming = nextChangeTime ? nextChangeTime.diff(today, "days") : 0;

    const currentKM = forOwner ? currentKilometer : snap.carStatus.kilometer ? +snap.carStatus.kilometer : 0;

    const nextKM = +(data.expiresInKilometers + data.kilometer);
    const remainKM = nextKM - currentKM;
    const kilometerPercentage =
      currentKM > nextKM || remainKM === 0 ? 100 : 100 - +(remainKM / data.expiresInKilometers).toFixed(2) * 100;
    const timePercentage = !nextChangeTime
      ? 100
      : 100 - +(+nextChangeTime.diff(today, "days") / data.service.expiresInDays).toFixed(2) * 100;

    const showExclamationMark =
      expiresType === "kilometers"
        ? kilometerPercentage >= 80
        : expiresType === "days"
        ? timePercentage >= 80
        : kilometerPercentage >= 80 || timePercentage >= 80;
    return (
      <div
        key={data.id}
        onClick={() => {
          if (!forOwner) {
            if (!goToDetailPage && onClick) {
              onClick(data);
            } else {
              ServiceStorage.setTempReturnURL(returnURL);
              navigate({
                pathname: ConfigLink.reminderDetail,
                search: useQueryString({
                  id: data.id,
                  currentKilometer: snap.carStatus.kilometer,
                  ...(plate ? { plateId: plate } : undefined),
                  ...(updateNeeded ? { plate } : undefined),
                }),
              });
            }
          }
        }}
        className="w-full cursor-pointer mb-3 ts-bg-gray-01 rounded-2xl p-4 flex flex-col justify-start items-start"
      >
        <div className="w-full flex justify-between items-center mb-4">
          <div className="flex justify-start items-center">
            {showExclamationMark && data.committed && (
              <IoIosWarning size={22} className="ts-secondary-red -mt-1 ml-2" />
            )}
            <Text type="H5" className="ts-gray-08">
              {data.service.name}
            </Text>
          </div>
          {!data.committed && (
            <div className="w-14 h-7 flex justify-center items-center bg-white rounded-full">
              <Text type="HM" className="ts-primary">
                جدید
              </Text>
            </div>
          )}
          {isTimePassed && data.committed && (
            <div className="h-7 py-1 px-2 flex justify-center items-center bg-white rounded-full">
              <Text type="HM" className="ts-secondary-red">
                موعد زمانی گذشته
              </Text>
            </div>
          )}
        </div>
        {expiresType !== "days" && (
          <>
            <Text type="P4" className="ts-gray-07 mb-2">
              {`${
                !data.committed ? data.expiresInKilometers : remainKM <= 0 ? "0" : Math.abs(nextKM - currentKM)
              } کیلومتر تا تعویض بعدی`}
            </Text>
            <Progress.Line
              strokeColor={kilometerPercentage >= 80 ? "#CA2525" : "#752FBB"}
              strokeWidth={7}
              percent={kilometerPercentage < 0 || !data.committed ? 0 : kilometerPercentage}
              showInfo={false}
              className="w-full p-0"
            />
          </>
        )}

        {(expiresType === "both" || expiresType === "days") && (
          <>
            <Text type="P4" className={`ts-gray-07 mb-2 ${expiresType !== "days" && "mt-3"}`}>
              {`${!data.committed ? +data.expiresInDays / 30 : Math.abs(Math.round(differenceTiming / 30))} ${
                differenceTiming / 30 > 1 ? "ماه" : "روز"
              } ${differenceTiming / 30 < 0 ? "از موعد تعویض گذشته" : "تا تعویض بعدی"}`}
            </Text>
            <Progress.Line
              strokeColor={timePercentage >= 80 ? "#CA2525" : "#752FBB"}
              strokeWidth={7}
              percent={timePercentage < 0 || !data.committed ? 0 : timePercentage}
              showInfo={false}
              className="w-full p-0"
            />
          </>
        )}
        {data.description && (
          <Text type="P5" className="text-right mt-4 ts-gray-06">
            {data.description}
          </Text>
        )}
      </div>
    );
  },
);

export { ReminderCard };

const styles = {};
