/* eslint-disable */
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { vState } from "../../../../core";
import { useAuth } from "../../../../hooks";
import { BottomModal, Feedback } from "../../../components";

interface IFeedbackModalProps {
  isOpen: boolean;
  onClose: () => void;
  onFeedbackRegistered: () => void;
  id: string;
  showWorkshop: boolean;
  workshop: any;
}

const FeedbackModal: React.FC<IFeedbackModalProps> = React.memo(
  ({ isOpen, onClose, workshop, showWorkshop, onFeedbackRegistered, id }) => {
    const navigate = useNavigate();
    const auth = useAuth();
    const snap = useSnapshot(vState);

    const doneHandler = async () => {
      await onFeedbackRegistered();
      onClose();
    };

    return (
      <BottomModal isOpen={isOpen} onClose={onClose} className="pb-4">
        <Feedback id={id} onDone={doneHandler} showWorkshop={showWorkshop} workshop={workshop} />
      </BottomModal>
    );
  },
);

export { FeedbackModal };

const styles = {};
