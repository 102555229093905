/* eslint-disable */
import { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { HiLibrary, HiLocationMarker, HiPhone } from "react-icons/hi";
import { IoNavigateCircle } from "react-icons/io5";
import { RiErrorWarningFill, RiShareFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import user from "../../../assets/images/user-profile-icon.svg";
import { ApiInstance, vState } from "../../../core";
import { useChangeTitle, useFetchQueryString } from "../../../core/hooks";
import { useAuth } from "../../../hooks";
import { Button, ContainerScroll, ReportProblem, StarRating, Text, useNotification } from "../../components";
import { Header } from "../../_layouts/components";
import { FeedbackStatistics, MiniMap, WorkshopViewLoader } from "./components";
import FeedbackComments from "./components/FeedbackComments";

const env = import.meta.env;

interface IQueryStringTransfer {
  id: string;
}

const WorkshopInfo = ({}) => {
  const auth = useAuth();
  const metaTags = useChangeTitle("ویراژ - اطلاعات کسب‌وکار");
  let { id } = useFetchQueryString<IQueryStringTransfer>();
  const snap = useSnapshot(vState);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [cardOpen, setCardOpen] = useState(false);
  const [staffListOpen, setStaffListOpen] = useState(false);
  const [shareLink, setShareLink] = useState(`${window.location.href}`);
  const [workshop, setWorkshops] = useState<any>({
    name: "",
    description: "",
    phone: "",
    address: "",
    location: null,
    services: [],
    agents: [],
    feedbacks: {},
    createdBy: {},
  });

  const [reportModalShow, setReportModalShow] = useState<boolean>(false);

  const getWorkshop = async () => {
    await ApiInstance.provider
      .getWorkshopInfo(id || "")
      .then((res) => {
        console.log(res);
        setWorkshops(res.data);
      })
      .catch((err) => {
        useNotification("مشکلی رخ داده است. لطفا مجددا امتحان کنید", "error", "مشخصات");
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getShortLink = async () => {
    await ApiInstance.provider
      .getWorkshopShortLink(id)
      .then((res) => {
        console.log(res);
        const link = window.location.hostname.includes("virazh.ir") ? "" : "dev.";
        setShareLink(`https://${link}stlk.ir/${res.data.data.shortid}`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getWorkshop();
    getShortLink();
  }, []);

  const callWorkshopHandler = () => {
    window.open(`tel:${phoneNumberCondition ? workshop.createdBy.mobile : workshop.phone}`, "_self");
  };

  const openLocation = () => {
    window.open(
      `https://maps.google.com?q=${workshop.location.coordinates[0]},${workshop.location.coordinates[1]}`,
      "_blank",
    );
  };

  const backBtnHandler = () => {
    navigate(-1);
  };

  const shareHandler = () => {
    if (navigator && navigator.share) {
      navigator
        .share({
          title: "ویراژ",
          text: `کسب و کار ${workshop.name || ""}`,
          url: shareLink,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => {
          console.log("Error sharing", error);
        });
    } else {
      useNotification("قابلیت به اشتراک گذاری لینک در حال حاضر در دسترس نیست.", "error", "اشتراک گذاری");
    }
  };

  const reportOptions: any[] = [
    {
      id: 0,
      label: "این کسب و کار تکراری است.",
      value: "duplicate",
    },
    {
      id: 1,
      label: "کسب و کاری با این مشخصات پیدا نکردم.",
      value: "not_found",
    },
    {
      id: 2,
      label: "اطلاعات کسب و کار دقیق نیست.",
      value: "not_precise",
    },
    {
      id: 3,
      label: "سایر",
      value: "other",
    },
  ];

  const phoneNumberCondition =
    workshop.createdBy && workshop.createdBy.mobile && !workshop.createdBy.mobile.includes("*");

  return (
    <>
      <Header showBackButton noLogo backClick={backBtnHandler} />
      {metaTags}
      <ContainerScroll heightOffset={140} className="">
        {loading ? (
          <WorkshopViewLoader />
        ) : (
          <>
            <div className="w-full flex justify-between items-center mb-6">
              <Text type="H3" className="ts-accent pt-1">
                {workshop.name || "---"}
              </Text>
              <Button
                bcolor="white"
                onClick={shareHandler}
                icon={<RiShareFill size={25} className="ts-primary" />}
                classNameContainer="w-10 h-10 ts-shadow rounded-xl"
                className="w-full h-full flex justify-center items-center"
              ></Button>
            </div>
            {workshop.feedbacks && Object.keys(workshop.feedbacks).length !== 0 && (
              <div
                className={`${
                  cardOpen ? "ts-feedback-box-open" : "ts-feedback-box-close"
                } w-full flex flex-col justify-start bg-white ts-shadow rounded-2xl py-3 px-4 items-center mb-4`}
              >
                <div className="w-full flex justify-between items-center">
                  <div className="flex justify-center items-center">
                    <StarRating
                      // @ts-ignore
                      initialValue={
                        workshop.feedbacks && workshop.feedbacks.averageScore
                          ? parseFloat(workshop.feedbacks.averageScore).toFixed(1)
                          : 0
                      }
                      size={18}
                    />

                    <Text type="P3" className="ts-gray-06 mr-2.5 mt-0.5">
                      {workshop.feedbacks.averageScore ? parseFloat(workshop.feedbacks.averageScore).toFixed(1) : 0}
                    </Text>
                  </div>
                  <div
                    onClick={() => {
                      if (
                        snap.userInfo.roles &&
                        (snap.userInfo.roles.indexOf("operator") !== -1 ||
                          snap.userInfo.roles.indexOf("visitor") !== -1)
                      ) {
                        setCardOpen((prev) => !prev);
                      }
                    }}
                    className="flex justify-center items-center"
                  >
                    <Text type="P5" className="ts-accent mt-0.5">
                      {+workshop.feedbacks.total || 0} دیدگاه
                    </Text>
                    {snap.userInfo.roles &&
                      (snap.userInfo.roles.indexOf("operator") !== -1 ||
                        snap.userInfo.roles.indexOf("visitor") !== -1) && (
                        <BiChevronDown
                          size={23}
                          className={`ts-accent transition-all duration-300 mr-1.5 ${
                            cardOpen ? "rotate-180" : "rotate-0"
                          }`}
                        />
                      )}
                  </div>
                </div>

                {snap.userInfo.roles &&
                  (snap.userInfo.roles.indexOf("operator") !== -1 || snap.userInfo.roles.indexOf("visitor") !== -1) && (
                    <>
                      {workshop.feedbacks.serviceQuality && (
                        <FeedbackStatistics label="کیفیت خدمت" data={workshop.feedbacks.serviceQuality} />
                      )}
                      {workshop.feedbacks.wellBehaved && (
                        <FeedbackStatistics label="حسن رفتار" data={workshop.feedbacks.wellBehaved} />
                      )}
                      {workshop.feedbacks.fairPrice && (
                        <FeedbackStatistics label="قیمت منصفانه" data={workshop.feedbacks.fairPrice} />
                      )}
                      {workshop.feedbacks.equipment && (
                        <FeedbackStatistics label="قطعات و تجهیزات" data={workshop.feedbacks.equipment} />
                      )}
                    </>
                  )}
              </div>
            )}
            <div className="w-full relative mb-6">
              {!workshop.location && (
                <div className="w-full ts-mini-map bg-black opacity-70 z-20 absolute top-0 left-0 flex justify-center items-center">
                  <Text type="H5" className="text-white text-center">
                    موقعیت مکانی برای کسب‌وکار اضافه نشده
                  </Text>
                </div>
              )}
              <MiniMap data={workshop} id={"workshop-mini-map"} />
            </div>
            <div className="w-full flex justify-between items-center">
              {workshop.location && (
                <Button
                  bcolor="primary"
                  textType="H6"
                  onClick={openLocation}
                  icon={<IoNavigateCircle size={23} className="text-white -mt-1" />}
                  classNameContainer={`${workshop.phone || phoneNumberCondition ? "ts-half-width" : "w-full"}`}
                  className="w-full h-12"
                >
                  مسیریابی
                </Button>
              )}
              {(workshop.phone || phoneNumberCondition) && (
                <Button
                  bcolor="primaryTransparent"
                  textType="H6"
                  onClick={callWorkshopHandler}
                  icon={<HiPhone size={23} className="ts-primary -mt-1 -rotate-90" />}
                  classNameContainer={`${workshop.location ? "ts-half-width" : "w-full"}`}
                  className="w-full h-12"
                >
                  تماس
                </Button>
              )}
            </div>
            <Text type="H4" className="ts-accent text-right mb-4 mt-8">
              اطلاعات و نشانی
            </Text>
            {workshop.description && (
              <Text type="P3" className="ts-gray-05 text-justify mb-4">
                {workshop.description || "---"}
              </Text>
            )}
            <div className="w-full flex justify-start items-center mb-2">
              <div className="w-8 h-8 rounded-full ts-bg-primary-light ml-2 flex justify-center items-center">
                <HiLocationMarker size={19} className="ts-primary" />
              </div>
              <Text type="H6" className="ts-accent ts-workshop-address">
                {workshop.address || "---"}
              </Text>
            </div>

            {phoneNumberCondition && (
              <div className="w-full flex justify-start items-center mb-2">
                <div className="w-8 h-8 rounded-full ts-bg-primary-light ml-2 flex justify-center items-center">
                  <HiPhone size={19} className="ts-primary -rotate-90" />
                </div>
                <Text
                  onClick={() => {
                    window.open(`tel:${workshop.createdBy.mobile}`, "_self");
                  }}
                  type="H6"
                  className="ts-accent cursor-pointer"
                >
                  {workshop.createdBy.mobile || "---"}
                </Text>
              </div>
            )}

            {workshop.phone && (
              <div className="w-full flex justify-start items-center">
                <div className="w-8 h-8 rounded-full ts-bg-primary-light ml-2 flex justify-center items-center">
                  <HiLibrary size={19} className="ts-primary" />
                </div>
                <Text
                  onClick={() => {
                    window.open(`tel:${workshop.phone}`, "_self");
                  }}
                  type="H6"
                  className="ts-accent cursor-pointer"
                >
                  {workshop.phone || "---"}
                </Text>
              </div>
            )}

            {/* <div className="w-full flex justify-start items-center">
              <div className="w-12 h-12 rounded-2xl p-3 ts-bg-primary-light ml-3">
                <HiClock size={23} className="ts-accent" />
              </div>
              <div className="flex flex-col justify-start items-start">
                <Text type="H6" className="ts-accent">
                  ملک شهر، خیابان مطهری، نبش کوچه ۱۴
                </Text>
                <Text type="P5" className="ts-gray-05 mt-2">
                  در حال حاضر بسته می باشد.
                </Text>
              </div>
            </div> */}

            {workshop.agents && workshop.agents.length !== 0 && (
              <div
                className={`w-full ${staffListOpen ? "ts-staff-open" : "ts-staff-close"} ${
                  workshop.agents.length > 3 ? "mb-2" : "mb-6"
                } flex flex-col justify-start items-start mt-6 px-0.5`}
              >
                <Text type="H4" className="ts-accent mb-2">
                  کارکنان
                </Text>

                {workshop.agents.map((item) => {
                  return (
                    <span
                      key={item.id}
                      className="w-full flex justify-between items-center rounded-xl ts-shadow p-4 mb-2"
                    >
                      <span className="flex justify-center items-center">
                        <img src={user} alt="tst-user" className="w-8 h-8 rounded-full ml-2" />
                        <Text type="H6" className="ts-accent">
                          {item.user ? item.user.name || "---" : "بدون نام"}
                        </Text>
                      </span>
                      {item.accessLevel === "owner" && (
                        <Text type="P4" className="ts-gray-06">
                          مالک کسب و کار
                        </Text>
                      )}
                    </span>
                  );
                })}
              </div>
            )}
            {workshop.agents && workshop.agents.length > 3 && (
              <span className="w-full flex justify-center items-center mb-6">
                <Text onClick={() => setStaffListOpen((prev) => !prev)} type="H6" className="ts-primary cursor-pointer">
                  {staffListOpen ? "نمایش کمتر" : "مشاهده همه"}
                </Text>
              </span>
            )}
            {workshop.services && workshop.services.length !== 0 && (
              <div className="w-full flex flex-col justify-start items-start rounded-xl ts-bg-primary-light py-4 px-6 mb-6">
                <Text type="H4" className="ts-accent mb-6">
                  خدمات
                </Text>

                <ul className="list-disc mr-4">
                  {workshop.services.map((item) => {
                    return (
                      <li className="mb-1">
                        <Text type="P4" className="ts-gray-07">
                          {item.name || "---"}
                        </Text>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}

            {workshop.cars && workshop.cars.length !== 0 && (
              <div className="w-full flex flex-col justify-start items-start rounded-xl ts-bg-primary-light py-4 px-6">
                <Text type="H4" className="ts-accent mb-6">
                  خودرو های قابل پذیرش
                </Text>

                <ul className="list-disc mr-4">
                  {workshop.cars.map((item) => {
                    return (
                      <li className="mb-1">
                        <Text type="P4" className="ts-gray-07">
                          {item.name || "---"}
                        </Text>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            <div className="w-full relative ts-report-box-bg rounded-2xl px-4 py-2.5 mt-8 flex justify-start items-start">
              <div className="w-5 h-5 absolute top-2.5 right-4">
                <RiErrorWarningFill size={20} className="ts-warning" />
              </div>
              <Text type="P4" className="ts-gray-07 indent-6">
                ویراژ هیچگونه مسئولیتی در قبال کیفیت خدمات ارائه شده توسط کسب و کارها ندارد. هدف ما صرفا معرفی خدمت
                دهنده ها می باشد. در صورت مشاهده یا بروز هرگونه مشکل می توانید{" "}
                <b onClick={() => setReportModalShow(true)} className="ts-text-p4 z-20 ts-info cursor-pointer">
                  گزارش کنید.
                </b>
              </Text>
            </div>

            {snap.userInfo.roles &&
              (snap.userInfo.roles.indexOf("operator") !== -1 || snap.userInfo.roles.indexOf("visitor") !== -1) && (
                <FeedbackComments id={id} />
              )}
          </>
        )}
      </ContainerScroll>
      <ReportProblem
        isOpen={reportModalShow}
        id={id}
        options={reportOptions}
        onClose={() => setReportModalShow(false)}
      />
      {/* <Footer activeTab={2} /> */}
    </>
  );
};

export { WorkshopInfo as default };
