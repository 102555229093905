/* eslint-disable */
import React, { useEffect, useState } from "react";
import { IoMdCall } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { vState } from "../../core";
import { BottomModal, Button, Text } from "./";

interface ICarRegisteredBeforeProps {
  isOpen: boolean;
  onClose: () => void;
}

const CarRegisteredBefore: React.FC<ICarRegisteredBeforeProps> = React.memo(({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const snap = useSnapshot(vState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  return (
    <BottomModal isOpen={isOpen} onClose={onClose}>
      <div className="w-full flex flex-col justify-start items-start">
        <Text type="H4" className="ts-accent">
          اضافه کردن خودرو
        </Text>
        <Text type="P3" className="ts-accent my-6">
          شماره پلاک وارد شده قبلا در سیستم ثبت شده است و با شماره تلفن همراه شما همخوانی ندارد. در صورت ادعای مالکیت،
          با شماره زیر تماس بگیرید.
        </Text>
        <div className="w-full flex flex-col">
          <Button
            onClick={() => window.open("tel:+989207901785", "_self")}
            icon={<IoMdCall size={20} className="text-white mr-2 -mt-1" />}
            iconPosition="afterText"
            bcolor="gray"
            textType="H6"
            classNameContainer="w-full"
            className="w-full rounded-xl h-12 mb-3"
          >
            09207901785
          </Button>

          {/* <Button
            onClick={() => window.open("http://api.whatsapp.com/send?phone=989207901785", "_blank")}
            bcolor="green"
            textType="H6"
            classNameContainer="w-full"
            className="w-full rounded-xl h-12 my-3"
          >
            ارسال پیام در واتساپ
          </Button> */}

          <Button
            onClick={() => onClose()}
            bcolor="blackTransparent"
            textType="H6"
            classNameContainer="w-full"
            className="w-full rounded-xl h-12"
          >
            لغو
          </Button>
        </div>
      </div>
    </BottomModal>
  );
});

export { CarRegisteredBefore };

const styles = {};
