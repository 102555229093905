/* eslint-disable */
import React from "react";
import { useChangeTitle } from "../../../core";

interface ISupportProps {}

const Support: React.FC<ISupportProps> = React.memo(({}) => {
  const metaTags = useChangeTitle("ویراژ - پشتیبانی");

  return (
    <>
      {metaTags}
      <iframe
        src="https://www.goftino.com/c/2H3C71"
        className="ts-support-wrapper"
        style={{
          position: "fixed",
          top: "63px",
          left: 0,
          bottom: 0,
          right: 0,
          width: "100%",
          maxWidth: "768px",
          height: "92%",
          border: "none",
          margin: 0,
          padding: 0,
          overflow: "hidden",
          inset: "unset",
          // zIndex: 50,
        }}
      >
        مرورگر شما از این قابلیت پشتیبانی نمی کند.
      </iframe>
    </>
  );
});

export { Support as default };
