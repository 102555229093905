/* eslint-disable */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { ApiInstance, ConfigLink, ServiceStorage, useChangeTitle, useFetchQueryString, vState } from "../../../core";
import { useNotification } from "../../components";
import { useAuth } from "./../../../hooks/auth";

interface IIntroductionProps {} //INTRODUCTION PAGES

interface IQueryStringTransfer {
  code?: string;
}

const env = import.meta.env;

const Introduction: React.FC<IIntroductionProps> = React.memo(({}) => {
  const metaTags = useChangeTitle("ویراژ");
  const auth = useAuth();
  const snap = useSnapshot(vState);
  const navigate = useNavigate();
  let { code } = useFetchQueryString<IQueryStringTransfer>();

  const getCars = async (hasCity: boolean) => {
    await ApiInstance.plates
      .getUserCars()
      .then((res) => {
        console.log(res);
        const visitedFlow = ServiceStorage.getSetupFlowVisited();
        if (res.data.data.length === 0 && !visitedFlow) {
          navigate(ConfigLink.createCarWelcome, { replace: true });
        } else {
          console.log(snap.userInfo, hasCity);

          if (!("name" in snap.userInfo) || ("name" in snap.userInfo && !Boolean(snap.userInfo.name)) || !hasCity) {
            navigate(ConfigLink.completeAccount, { replace: true });
          } else {
            navigate(ConfigLink.homePage, { replace: true });
          }
        }
      })
      .catch((err) => {
        console.log("getCars Error", err);
        useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا تلاش کنید.", "warning", "ویراژ");
        navigate(ConfigLink.homePage, { replace: true });
      });
  };

  const getUserSetting = async () => {
    await ApiInstance.account
      .getSetting()
      .then((res) => {
        console.log(res);
        const ownerSetting = res.data;
        getCars(Boolean(ownerSetting.cityId));
      })
      .catch((err) => {
        console.log("getSetting Error", err);
        if (err.response.status !== 400) {
          useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا تلاش کنید.", "warning", "ویراژ");
        }
        getCars(false);
      });
  };

  useEffect(() => {
    if (auth.ready) {
      if (auth.authenticated) {
        getUserSetting();
      } else {
        if (code) {
          const url = new URL(`/complete-invite?code=${code}`, window.location.origin);
          auth.login({
            redirectUri: url.toString(),
          });
        } else {
          const url = ServiceStorage.getRedirectUrl();
          ServiceStorage.removeRedirectUrl();
          console.log("url", url);
          auth.login({
            redirectUri: url ?? undefined,
          });
        }
      }
    }
  }, [auth.ready, auth.authenticated]);

  return (
    <>
      {metaTags}
      <div></div>
    </>
  );
});

export { Introduction as default };

const styles = {};
