/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "rsuite";
import { CarRegisteredBefore } from "ui/components/CarRegisteredBefore";
import { useSnapshot } from "valtio";
import {
  ApiInstance,
  fuelTypes,
  monthlyUsage,
  ServiceStorage,
  tools,
  useEffectNoInitial,
  useObjectState,
  vState,
} from "../../../../core";
import {
  BottomModal,
  Button,
  CarBrandSelector,
  CarsModal,
  InputField,
  SelectPickerField,
  Text,
  useNotification,
} from "../../../components";
import { InputLicensePlate } from "./";

type ServiceType = "register" | "edit";

interface IAddOrEditCarProps {
  isOpen: boolean;
  onClose: () => void;
  onCarAdded?: () => void;
  type?: ServiceType;
  data?: any;
}

const AddOrEditCar: React.FC<IAddOrEditCarProps> = React.memo(
  ({ isOpen, onClose, onCarAdded, data = null, type = "register" }) => {
    const defaultError = "پر کردن این فیلد الزامی می‌باشد.";
    const yearRegex = /[1|۱][3-4 | ۳-۴][0-9 | \u06F0-\u06F9][0-9 | \u06F0-\u06F9]/;

    const navigate = useNavigate();
    const snap = useSnapshot(vState);
    const returnUrl = ServiceStorage.getTempReturnURL();

    const [disabled, setDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const [alreadyRegistered, setAlreadyRegistered] = useState(false);

    const [plateValue, setPlateValue] = useObjectState({
      firstSection: "",
      secondSection: "",
      thirdSection: "",
      letter: "ا",
    });
    const { firstSection, letter, secondSection, thirdSection } = plateValue;

    const [carInfo, setCarInfo] = useObjectState<any>({
      carType: "",
      carId: null,
      fuelType: "",
      year: "",
      kilometer: "",
      kilometerAvg: "",
    });
    const [yearError, setYearError] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const { carType, fuelType, year, carId, kilometer, kilometerAvg } = carInfo;

    useEffect(() => {
      if (isOpen) {
        setErrors({});
        if (type === "edit") {
          setCarInfo({
            carType: data.carName || "",
            carId: data.car || null,
            kilometerAvg: data.monthlyUsage || "",
            fuelType: data.fuelType || "",
            year: data.buildYear || "",
          });
          setPlateValue({
            firstSection: data.plateNumber.slice(0, 2),
            secondSection: data.plateNumber.substring(3, 6),
            thirdSection: data.plateNumber.substring(7, 9),
            letter: data.plateNumber.slice(2, 3),
          });
        } else {
          setCarInfo({
            carType: "",
            carId: null,
            kilometerAvg: "",
            fuelType: "",
            year: "",
          });
          setPlateValue({
            firstSection: "",
            secondSection: "",
            thirdSection: "",
            letter: "ا",
          });
        }
      }
    }, [isOpen]);

    const checkPlateCompleted = () => {
      if (firstSection.length === 2 && secondSection.length === 3 && thirdSection.length === 2) {
        checkPlate();
      }
    };

    useEffectNoInitial(() => {
      if (isOpen) {
        if (type === "register") {
          checkPlateCompleted();
        } else {
          const mainPlate = {
            firstSection: data.plateNumber.slice(0, 2),
            secondSection: data.plateNumber.substring(3, 6),
            thirdSection: data.plateNumber.substring(7, 9),
            letter: data.plateNumber.slice(2, 3),
          };
          const mainPlateStr = JSON.stringify(mainPlate);
          const editedPlateStr = JSON.stringify(plateValue);
          console.log(mainPlateStr, "///", editedPlateStr, mainPlateStr !== editedPlateStr);

          if (mainPlateStr !== editedPlateStr) {
            checkPlateCompleted();
          }
        }
      }
    }, [plateValue]);

    const checkPlate = async () => {
      const plate = tools.getFormattedPlate(firstSection, letter, secondSection, thirdSection);
      await ApiInstance.plates
        .checkPlateExists(plate)
        .then((res) => {
          console.log(res);
          if (res.data.meta.found) {
            let errorsObj = { ...errors };
            const mobile = res.data.data.mobile || "";
            console.log(mobile, snap.userInfo.username);

            errorsObj.plate = "این شماره پلاک قبلا ثبت شده است.";
            setErrors(errorsObj);
          } else {
            removeField("plate");
          }
        })
        .catch((err) => {
          console.log(err);
          useNotification("مشکلی رخ داده است. لطفا مجددا تلاش کنید", "error");
        });
    };

    const getFormattedData = () => {
      const plate = tools.getFormattedPlate(firstSection, letter, secondSection, thirdSection);

      console.log(carId);

      return {
        plateNumber: plate,
        carId: carId ? carId.id : null,
        carName: carType,
        ...(year ? { buildYear: +year } : undefined),
        ...(fuelType ? { fuelType } : undefined),
        ...(kilometerAvg ? { monthlyUsage: kilometerAvg } : undefined),
      };
    };

    const registerDataHandler = async () => {
      setLoading(true);

      const finalData = getFormattedData();

      await ApiInstance.plates
        .addCar(finalData)
        .then((res: any) => {
          console.log(res);
          if (res.status === 200 || res.status === 201) {
            useNotification("خودرو با موفقیت به گاراژ شما اضافه شد", "success", "اضافه کردن خودرو");
            if (onCarAdded) {
              onCarAdded();
            }
            setTimeout(() => {
              onClose();
            }, 1000);
          }
        })
        .catch((err) => {
          console.log("err", err);
          if (err.response.status === 400 && err.response.data.message.toString().includes("already registered.")) {
            setAlreadyRegistered(true);
          } else {
            useNotification(
              "مشکلی رخ داده است. لطفا اتصال خود را بررسی کنید و دوباره سعی کنید.",
              "error",
              "اضافه کردن خودرو",
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const editDataHandler = async () => {
      setLoading(true);

      const finalData = getFormattedData();

      await ApiInstance.plates
        .editCar(data.id, finalData)
        .then((res: any) => {
          console.log(res);
          if (res.status === 200 || res.status === 201) {
            useNotification("مشخصات خودرو ویرایش شد", "success", "ویرایش خودرو");
            if (onCarAdded) {
              onCarAdded();
            }
            setTimeout(() => {
              onClose();
            }, 1000);
          }
        })
        .catch((err) => {
          console.log("err", err);
          if (err.response.status === 400 && err.response.data.message.toString().includes("already registered.")) {
            setAlreadyRegistered(true);
          } else {
            useNotification(
              "مشکلی رخ داده است. لطفا اتصال خود را بررسی کنید و دوباره سعی کنید.",
              "error",
              "اضافه کردن خودرو",
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    };

    const checkStateValue = (state: string, operation: () => void) => {
      if (plateValue[state].length === 0) {
        operation();
      }
    };

    const setPlateValues = (e, place) => {
      // if (/^[\u06F0-\u06F9]*$/.test(e) || /^[0-9]*$/g.test(e)) {
      if ("plate" in errors) {
        removeField("plate");
      }
      setPlateValue({ [place]: e });
      // }
    };

    const submitBtnHandler = () => {
      let errorsObj = { ...errors };
      if (carId === null) {
        errorsObj.carId = defaultError;
      }
      if (firstSection.length !== 2 || secondSection.length !== 3 || thirdSection.length !== 2) {
        errorsObj.plate = "پر کردن همه قسمت های پلاک الزامی است";
      }
      if (year.length !== 0 && !yearRegex.test(year)) {
        errorsObj.year = "فرمت سال وارد شده صحیح نیست";
      }
      if (Object.keys(errorsObj).length !== 0) {
        useNotification("لطفا فیلدهای مشخص شده را به طور صحیح پر کنید.", "warning");
        setErrors(errorsObj);
      } else {
        type === "register" ? registerDataHandler() : editDataHandler();
      }
    };

    const removeField = (field) => {
      let cn = { ...errors };
      delete cn[field];
      setErrors(cn);
    };

    return (
      <>
        <BottomModal isOpen={isOpen} onClose={onClose}>
          <Text type="H4" className="ts-accent mb-6">
            {type === "register" ? "اضافه کردن خودرو" : "ویرایش اطلاعات خودرو"}
          </Text>

          <Text type="H6" className="ts-accent mb-2">
            شماره پلاک
          </Text>
          <InputLicensePlate
            plateValue={plateValue}
            setPlateValues={setPlateValues}
            checkStateValue={checkStateValue}
            letterChange={(value) => setPlateValue({ letter: value })}
            error={"plate" in errors}
            errorText={errors.plate || ""}
          />

          <Form fluid className="w-full mt-2">
            <CarBrandSelector
              onClick={() => {
                setModalOpen(true);
                removeField("carId");
              }}
              value={carId ? carId.name : null}
              error={"carId" in errors}
            />

            <InputField
              label="نام خودرو (اختیاری)"
              value={carType}
              onChange={(e) => setCarInfo({ carType: e })}
              placeholder="مانند پراید ۱۲۱، پژو ۲۰۶، ..."
            />

            <InputField
              label=" سال ساخت (اختیاری)"
              inputType="number"
              value={year}
              onChange={(e) => {
                if (e.length <= 4) {
                  setCarInfo({ year: e });
                }
              }}
              placeholder="یک عدد چهار رقمی؛ مثلا 1402"
              error={"year" in errors}
              errorText={errors.year || ""}
              onFocus={() => removeField("year")}
            />

            <SelectPickerField
              label="نوع سوخت (اختیاری)"
              data={fuelTypes}
              value={fuelType}
              onChange={(e) => setCarInfo({ fuelType: e })}
              searchable={false}
              cleanable={false}
              placeholder="نوع سوخت خودرو را انتخاب کنید"
            />

            <SelectPickerField
              label="متوسط کارکرد در ماه (اختیاری)"
              data={monthlyUsage}
              value={kilometerAvg}
              onChange={(e) => setCarInfo({ kilometerAvg: e })}
              searchable={false}
              cleanable={false}
              placeholder="متوسط کارکرد خودروی خود را انتخاب کنید"
            />

            <Button
              type="submit"
              bcolor="primary"
              classNameContainer="w-full"
              className="w-full rounded-xl h-12"
              onClick={submitBtnHandler}
              disabled={disabled}
              loading={loading}
            >
              {type === "register" ? "اضافه کردن خودرو" : "ویرایش خودرو"}
            </Button>
          </Form>
        </BottomModal>
        <CarsModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          isSingleChoice
          selectedCars={carId}
          selectCarHandler={(value) => {
            console.log(value);

            setCarInfo({ carId: value });
          }}
        />
        <CarRegisteredBefore isOpen={alreadyRegistered} onClose={() => setAlreadyRegistered(false)} />
      </>
    );
  },
);

export { AddOrEditCar };
