/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { ApiInstance, vState } from "../../../../core";
import { BottomModal, Button, CarBrandSelector, CarsModal, Text, useNotification } from "../../../components";

interface IUpdateCarBrandProps {
  isOpen: boolean;
  onClose: () => void;
  onCarUpdated: () => void;
  data: any;
}

const UpdateCarBrand: React.FC<IUpdateCarBrandProps> = React.memo(({ isOpen, onClose, onCarUpdated, data }) => {
  const navigate = useNavigate();
  const snap = useSnapshot(vState);

  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [carInfo, setCarInfo] = useState<any>(null);
  const [carsModal, setCarModalVisible] = useState(false);

  useEffect(() => {
    setDisabled(carInfo === null);
  }, [carInfo]);

  useEffect(() => {
    setCarInfo(null);
  }, [isOpen]);

  const registerDataHandler = async () => {
    setLoading(true);

    const formattedData = {
      plateNumber: data.plateNumber,
      carId: carInfo.id,
    };

    await ApiInstance.plates
      .editCar(data.id, formattedData)
      .then((res: any) => {
        console.log(res);
        useNotification("برند و تیپ به مشخصات خودروی شما اضافه شد.", "success", "اضافه کردن خودرو");
        onCarUpdated && onCarUpdated();
        setTimeout(() => {
          onClose();
        }, 1000);
      })
      .catch((err) => {
        useNotification(
          "مشکلی رخ داده است. لطفا اتصال خود را بررسی کنید و دوباره سعی کنید.",
          "error",
          "اضافه کردن خودرو",
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <BottomModal isOpen={isOpen} onClose={onClose}>
        <Text type="H4" className="ts-accent mb-3">
          تکمیل اطلاعات
        </Text>

        <Text type="P4" className="ts-accent mb-3">
          با توجه به تغییرات جدید ویراژ لازم است که برند و تیپ خودرو «
          <strong>{data && data.carName ? data.carName : ""}</strong>» را از دسته بندی زیر انتخاب کنید:
        </Text>

        <CarBrandSelector onClick={() => setModalOpen(true)} value={carInfo ? carInfo.name : null} className="mb-4" />

        <Button
          onClick={registerDataHandler}
          loading={loading}
          disabled={disabled}
          bcolor="primary"
          classNameContainer="w-full"
          className="w-full h-12 rounded-xl"
        >
          ذخیره
        </Button>
      </BottomModal>
      <CarsModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        isSingleChoice
        selectedCars={carInfo}
        selectCarHandler={(value) => {
          setCarInfo(value);
        }}
      />
    </>
  );
});

export { UpdateCarBrand };
