/* eslint-disable */
import React, { useState } from "react";
import { AiFillExclamationCircle } from "react-icons/ai";
import { MdChevronLeft, MdOutlineClose } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { ConfigLink, ServiceStorage, vState } from "../../../../core";
import { Text } from "../../../components";

interface ICompleteInfoAlertProps {}

const CompleteInfoAlert: React.FC<ICompleteInfoAlertProps> = () => {
  const snap = useSnapshot(vState);
  const navigate = useNavigate();

  const [close, setClose] = useState(false);

  // useEffect(() => {
  //   const isVisited = ServiceStorage.getWarningVisited();
  //   if (isVisited) {
  //     setClose(true);
  //   } else {
  //     setClose(false);
  //   }
  // }, []);

  const editHandler = () => {
    ServiceStorage.setTempReturnURL(ConfigLink.homePage);
    navigate({ pathname: ConfigLink.completeAccount });
    // navigate({ pathname: ConfigLink.workshopEdit, search: useQueryString({ id: snap.selectedWorkshop || "" }) });
  };

  const closeWarningHandler = () => {
    // ServiceStorage.setWarningVisited();
    setClose(true);
    setTimeout(() => {
      document.getElementById("warning-box")?.classList.add("hidden");
    }, 1100);
  };

  return (
    <div
      id="warning-box"
      style={{ background: "rgba(255, 162, 58, 0.2)" }}
      className={`w-full max-h-16 z-20 transition-all duration-1000 px-4 py-2.5 mb-3 rounded-2xl flex justify-between items-center ${
        close ? "ts-hide-popup" : ""
      }`}
    >
      <div className="w-full flex flex-col justify-start items-start">
        <div className="w-full flex justify-between items-center mb-2">
          <div className="flex justify-center items-center">
            <AiFillExclamationCircle size={23} className="ts-warning ml-1.5 -mt-px" />
            <Text onClick={editHandler} type="HM" className="ts-accent font-bold">
              تکمیل اطلاعات
            </Text>
          </div>
          <MdOutlineClose onClick={closeWarningHandler} size={21} className="ts-gray-06 cursor-pointer z-30" />
        </div>
        <div onClick={editHandler} className="w-full flex justify-between items-center">
          <Text type="P5" className="ts-gray-08">
            جهت استفاده از امکانات ویراژ اطلاعات خود را کامل کنید
          </Text>
          <MdChevronLeft size={25} className="ts-accent -mt-1" />
        </div>
      </div>
    </div>
  );
};

export { CompleteInfoAlert };

const styles = {};
