/* eslint-disable */
import React from "react";
import { Sheet } from "react-modal-sheet";
import { useMediaQuery } from "react-responsive";
import { Modal } from "rsuite";

interface IBottomModalProps {
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  modalClassName?: string;
  hasShadow?: boolean;
  forPWA?: boolean;
  staticBackdrop?: boolean;
  centerScreen?: boolean;
  children: any;
  noPadding?: boolean;
  carModal?: boolean;
}

const BottomModal: React.FC<IBottomModalProps> = React.memo(
  ({
    children,
    isOpen,
    onClose,
    hasShadow = false,
    centerScreen = false,
    forPWA = false,
    staticBackdrop = false,
    className = "",
    modalClassName = "",
    noPadding = false,
    carModal = false,
  }) => {
    const isLargeScreen = useMediaQuery({
      query: "(min-width: 481px)",
    });

    return isLargeScreen || centerScreen ? (
      <Modal
        open={isOpen}
        onClose={onClose}
        className={`${modalClassName}`}
        backdrop={forPWA || staticBackdrop ? "static" : true}
        backdropStyle={{ background: "rgba(39, 45, 55, 0.8)" }}
        dialogClassName={`h-auto ts-hide-scroll-but-keep-working bg-white ${
          !forPWA && `rounded-3xl ${noPadding ? "" : "p-6 pt-10"}`
        } flex flex-col justify-start items-center ${className}`}
      >
        {children}
      </Modal>
    ) : (
      <>
        <Sheet
          isOpen={isOpen}
          onClose={onClose}
          disableScrollLocking
          detent={forPWA ? "full-height" : "content-height"}
          className="ts-modal-z-index"
          tweenConfig={{ ease: "easeOut", duration: 0.4 }}
        >
          <Sheet.Container className={`${forPWA ? "h-screen rounded-none" : "rounded-t-3xl"} ${className}`}>
            {!forPWA && (
              <Sheet.Header className="">
                <div className="w-full h-8 flex justify-center items-start mt-2">
                  <div
                    style={{ width: "102px" }}
                    className="h-1 z-30 bg-white rounded-full ts-bg-gray-04 cursor-pointer"
                  ></div>
                </div>
              </Sheet.Header>
            )}
            <Sheet.Content className={`${noPadding || carModal ? "" : "px-6 max-2xs:px-3"} pt-5 pb-9`}>
              <Sheet.Scroller>{children}</Sheet.Scroller>
            </Sheet.Content>
          </Sheet.Container>
          <Sheet.Backdrop className="bg-black opacity-60" onTap={onClose} />
        </Sheet>
      </>
    );
  },
);

export { BottomModal };

const styles = {};
