/* eslint-disable */
import axios from "axios";
import { vState } from "core/valtio";
import { axios as axiosInstance } from "./api";

const common = {
  healthCheck: async () => {
    const url = `/ping`;
    const result = await axiosInstance.get(url);

    return result;
  },
  getUI: async () => {
    const url = `/ui`;
    const result = await axiosInstance
      .get(url)
      .then((res) => {
        console.log("ui", res);
        const data = res.data.data;
        if (data.categories.length !== 0) {
          const newArrayOfObj = data.categories.map(({ id, name }) => ({
            value: id,
            label: name,
          }));
          vState.categories = newArrayOfObj.reverse();
        }
        if (data.services.length !== 0) {
          const newArrayOfObj = data.services.map(
            ({ id, name, expiresInKilometers, expiresInDays, expiresType, showInPlateFirstSetup }) => ({
              value: id,
              label: name,
              expiresInKilometers,
              expiresInDays,
              expiresType,
              showInPlateFirstSetup,
            }),
          );
          vState.services = newArrayOfObj;
          vState.activeFeatures = data.ownerAppConfig?.features || [];
        }
      })
      .catch((e) => {
        console.log("error ui", e);
      });

    return result;
  },
  getCars: async () => {
    const url = `/cars`;
    const result = await axiosInstance.get(url);

    return result;
  },
  getServices: async () => {
    const url = `/services?pageSize=100`;
    const result = await axiosInstance.get(url);

    return result;
  },
  getSetupServices: async () => {
    const url = `/services?pageSize=100&withRecommendations=true&showInPlateFirstSetup=true`;
    const result = await axiosInstance.get(url);

    return result;
  },
  getServicesWithDescription: async () => {
    const url = `/services?pageSize=100&hasDescription=true`;
    const result = await axiosInstance.get(url);

    return result;
  },
  getServiceDetail: async (plateId: string, id: string) => {
    const url = `/plates/${plateId}/reminders/${id}`;
    const result = await axiosInstance.get(url);

    return result;
  },
  getAllCities: async () => {
    const url = `/Cities`;
    const result = await axiosInstance.get(url);

    return result;
  },
  getVersion: async () => {
    const url = `/versions`;
    const result = await axiosInstance.get(url);

    return result;
  },
  getUploadUrl: async () => {
    const url = `/upload`;
    const result = await axiosInstance.post<{
      data: {
        id: string;
        key: string;
        url: string;
      };
    }>(url);

    return result.data;
  },
  uploadFile: async (
    url: string,
    file: File,
    options?: {
      controller?: AbortController;
      onUploadProgress?: (progressEvent: any) => void;
    },
  ) => {
    const result = await axios.put(url, file, {
      signal: options?.controller?.signal,
      onUploadProgress: options?.onUploadProgress,
      headers: {
        "Content-Type": file.type,
        "x-amz-acl": "public-read",
        "Content-Disposition": "inline",
      },
    });

    return result;
  },
};

export { common };
