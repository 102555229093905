/* eslint-disable */
import { shopCategories } from "hooks";
import _debounce from "lodash/debounce";
import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { BsCircleFill } from "react-icons/bs";
import { HiOutlineAdjustments } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router";
import { Form, Input, InputGroup, Loader, SelectPicker } from "rsuite";
import { useSnapshot } from "valtio";
import noSegment from "../../../assets/images/no-segment-requested.svg";
import {
  ApiInstance,
  ConfigLink,
  countries,
  useChangeTitle,
  useEffectNoInitial,
  useFetchQueryString,
  useObjectState,
  useQueryString,
  vState,
} from "../../../core";
import { ContainerScroll, Text, useNotification } from "../../components";
import { FilterModal, MyPartsCard } from "./components";
import CardLoader from "./components/CardLoader";
import ShopLoader from "./components/ShopLoader";

interface IMyShopProps {}

type SORT_TYPE = "name" | "newest" | "oldest" | "nearest" | "";

interface IQueryStringTransfer {
  q?: string;
  carId?: string[];
  company?: string;
  country?: string;
  cat?: string;
}

interface IFilter {
  car: null | any;
  manufacture: string;
  manufacturingCountry: string;
}

const PartShop: React.FC<IMyShopProps> = React.memo(() => {
  const metaTags = useChangeTitle("ویراژ - فروشگاه من");
  let { q, carId, company, country, cat } = useFetchQueryString<IQueryStringTransfer>();
  const snap = useSnapshot(vState);
  const navigate = useNavigate();
  const defaultValues: IFilter = {
    car: null,
    manufacture: "",
    manufacturingCountry: "",
  };

  let sortDataValues = [
    {
      label: "به ترتیب نام",
      value: "name",
    },
    {
      label: "جدیدترین",
      value: "newest",
    },
    {
      label: "قدیمی ترین",
      value: "oldest",
    },
  ];

  const [requests, setRequests] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [cardLoading, setCardLoading] = useState(true);

  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState("");
  const [sortReminders, setSortReminders] = useState<SORT_TYPE>("");
  const [currentLocation, setCurrentLocation] = useState<number[]>([]);
  const [sortData, setSortData] = useState<any[]>(sortDataValues);

  const [filterOpen, setFilterOpen] = useState(false);
  const [filters, setFilters] = useObjectState<{
    car: null | any;
    manufacture: string;
    manufacturingCountry: string;
  }>(defaultValues);
  const { car, manufacture, manufacturingCountry } = filters;
  const [selectedCategory, setSelectedCategory] = useState("");

  const getPartsList = async (pageIncreased: boolean, query?: string | null, sort?: SORT_TYPE | null) => {
    await ApiInstance.shop
      .getParts(
        pageCount + 1,
        sort || sortReminders,
        currentLocation.length === 2 ? currentLocation : null,
        query || searchText,
        filters,
        selectedCategory,
      )
      .then((res) => {
        console.log(res);
        setCount(res.data.meta.total);
        if (pageIncreased) {
          let data = [...requests, ...res.data.data];
          setRequests(data);
          setPageCount((prev) => prev + 1);
        } else {
          setRequests(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error");
      })
      .finally(() => {
        setLoading(false);
        setCardLoading(false);
        vState.isEndOfPage = false;
      });
  };

  const getCurrentLocation = () => {
    if ("geolocation" in navigator) {
      console.log("Available");

      navigator.geolocation.getCurrentPosition(
        function (pos) {
          console.log("position", pos);
          const loc = [pos.coords.latitude, pos.coords.longitude];
          let sort = [...sortData];
          sort.unshift({ label: "نزدیکترین به من", value: "nearest" });
          setSortData(sort);
          setSortReminders("nearest");

          setCurrentLocation(loc);
        },
        function (position) {
          console.log("position Error", position.message);
          // setCurrentLocation([35.69979085412715, 51.33753633512243]);
        },
        { timeout: 10000, enableHighAccuracy: true },
      );
    }
  };

  useEffect(() => {
    getCurrentLocation();
    if (q) {
      setSearchText(q);
      setSearch(q);
    }
    setFilters({
      car: carId ? { id: carId[0], name: carId[1] } : null,
      manufacture: company || "",
      manufacturingCountry: country || "",
    });
    if (cat) setSelectedCategory(cat);
  }, []);

  useEffectNoInitial(() => {
    updateURL(search, filters, selectedCategory);
    getPartsList(false, search);
  }, [filters, selectedCategory, search, currentLocation]);

  useEffect(() => {
    if (snap.isEndOfPage && count > requests.length && !loading) {
      getPartsList(true, searchText);
    } else {
      vState.isEndOfPage = false;
    }
  }, [snap.isEndOfPage]);

  const sortChangeHandler = (value) => {
    setSortReminders(value);
    console.log(filters, selectedCategory);
    setCardLoading(true);
    getPartsList(false, search, value);
  };

  const callApi = (e) => {
    setCardLoading(true);
    setSearch(e);
  };
  const [debouncedCallApi] = useState(() => _debounce((e) => callApi(e), 1100));

  const handleChange = (e) => {
    setSearchText(e);
    debouncedCallApi(e);
  };

  const resetSearch = () => {
    setSearchText("");
    setCardLoading(true);
    setTimeout(() => {
      callApi("");
    }, 1100);
  };

  const updateURL = (search: string, filter: IFilter, cat: string) => {
    navigate(
      {
        pathname: ConfigLink.shop,
        search: useQueryString({
          ...(search ? { q: search } : undefined),
          ...(filter.car ? { carId: [filter.car.id, filter.car.name] } : undefined),
          ...(filter.manufacture ? { company: filter.manufacture } : undefined),
          ...(filter.manufacturingCountry ? { country: filter.manufacturingCountry } : undefined),
          ...(cat ? { cat } : undefined),
        }),
      },
      { replace: true },
    );
  };

  const getFilterData = (filter) => {
    setPageCount(0);
    setCardLoading(true);
    setFilters(filter);
  };

  const setCategory = (cat) => {
    if (cat !== selectedCategory) {
      setPageCount(0);
      setSelectedCategory(cat);
      setCardLoading(true);
    }
  };

  const removeFilter = (item) => {
    const finalFilter = {
      ...filters,
      [item]: item === "car" ? null : "",
    };
    console.log(finalFilter);
    setFilters(finalFilter);
    setCardLoading(true);
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={20} className={`relative overflow-x-hidden`}>
        {loading ? (
          <ShopLoader />
        ) : (
          <>
            <div className="w-full flex justify-between items-center mb-4">
              <Text type="H4" className="ts-accent">
                فروشگاه قطعات
              </Text>
            </div>
            <div className="w-full flex justify-between items-center mb-4 gap-2">
              <Form fluid className={`w-full transition-all`}>
                <InputGroup inside className="w-full h-12 outline-none border-none ts-shadow ts-input-search">
                  <InputGroup.Button
                    onClick={resetSearch}
                    className={`${
                      searchText.length === 0 ? "hidden" : ""
                    } left-9 h-full bg-transparent hover:bg-transparent mr-7`}
                  >
                    <IoMdClose size={19} className={` transition-colors duration-200 -mt-1`} />
                  </InputGroup.Button>
                  <InputGroup.Button type="submit" className="h-full left-0 bg-transparent hover:bg-transparent">
                    <AiOutlineSearch size={23} className={`ts-gray-03 transition-colors duration-200 -mt-1`} />
                  </InputGroup.Button>
                  <Input
                    value={searchText}
                    onChange={handleChange}
                    className="h-full outline-none border-none rounded-2xl placeholder:text-gray-200"
                    placeholder="جستجو"
                  />
                </InputGroup>
              </Form>
              <div
                onClick={() => setFilterOpen(true)}
                className="w-14 h-12 rounded-xl ts-shadow bg-white cursor-pointer p-2 flex justify-center items-center relative"
              >
                <HiOutlineAdjustments size={22} className="ts-accent" />
                {JSON.stringify(filters) !== JSON.stringify(defaultValues) && (
                  <BsCircleFill size={13} className="ts-primary absolute -top-1 -left-1" />
                )}
              </div>
            </div>

            {JSON.stringify(filters) !== JSON.stringify(defaultValues) && (
              <div className="w-full flex justify-start items-center mb-4 flex-wrap gap-1">
                {Object.keys(filters).map((item) => {
                  if (filters[item]) {
                    const label =
                      item === "car"
                        ? filters[item].name
                        : item === "manufacturingCountry"
                        ? countries.filter((ctr) => ctr.value === filters[item])[0].label
                        : filters[item] || "---";
                    return (
                      <div
                        onClick={() => removeFilter(item)}
                        className="px-4 py-1 flex justify-center items-center rounded-full ts-bg-gray-01 cursor-pointer"
                      >
                        <Text type="HM" className="ts-accent ml-1 pt-0.5">
                          {label}
                        </Text>
                        <IoMdClose size={20} className="ts-accent" />
                      </div>
                    );
                  }
                })}
              </div>
            )}

            <div className="w-full flex mb-1.5 pl-0.5 overflow-x-auto touch-auto ts-hide-category-scroll-but-keep-working">
              <div className="flex gap-2 mb-2.5 pt-px pr-px">
                {shopCategories.map((item, index) => {
                  return (
                    <div
                      onClick={() => setCategory(item.value)}
                      key={item.value}
                      className={`bg-white ${
                        selectedCategory === item.value ? "ts-selected-category" : ""
                      } transition-all cursor-pointer w-max ts-shadow rounded-xl px-3 py-2 flex justify-center items-center`}
                    >
                      <img src={item.image} alt={index.toString()} />
                      <Text
                        type={selectedCategory === item.value ? "H6" : "P4"}
                        className={`mr-1 pt-px ${selectedCategory === item.value ? "ts-primary" : "ts-accent"} `}
                      >
                        {item.label}
                      </Text>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="w-full flex justify-between items-center mb-2">
              {cardLoading ? (
                <Skeleton className="w-12" />
              ) : (
                <Text type="H6" className="ts-accent">
                  {`${count} قطعه`}
                </Text>
              )}
              <SelectPicker
                size="lg"
                data={sortData}
                value={sortReminders}
                disabled={loading}
                cleanable={false}
                onChange={(value: any) => sortChangeHandler(value)}
                searchable={false}
                placeholder="مرتب سازی"
                className={`w-36 h-12 outline-none rounded-xl`}
              />
            </div>

            {cardLoading ? (
              <CardLoader />
            ) : requests.length === 0 ? (
              <div className="w-full flex flex-col justify-center items-center mt-20">
                <img src={noSegment} alt="no-part" className="mb-6" />
                <Text type="P4" className="ts-gray-07">
                  قطعه‌ای تاکنون ثبت نشده است.
                </Text>
              </div>
            ) : (
              requests.map((item) => {
                return <MyPartsCard key={item.id} data={item} />;
              })
            )}
            {snap.isEndOfPage && !loading && (
              <div className="w-full flex justify-center my-6">
                <Loader size="xs" />
              </div>
            )}
          </>
        )}
      </ContainerScroll>

      {/* <Footer activeTab={4} /> */}

      <FilterModal
        isOpen={filterOpen}
        onClose={() => setFilterOpen(false)}
        onDone={getFilterData}
        mainFilters={filters}
      />
    </>
  );
});

const sortData = [
  {
    label: "به ترتیب تاریخ",
    value: "DATE",
  },
  {
    label: "به ترتیب الفبا",
    value: "NAME",
  },
];

export { PartShop as default };
