/* eslint-disable */
import React from "react";
import { BottomModal, Button, Text } from "ui/components";

interface ILoginPromptProps {
  isOpen: boolean;
  onClose: () => void;
  onClick: () => void;
  title: string;
}

const LoginPrompt: React.FC<ILoginPromptProps> = React.memo(({ isOpen, onClose, onClick, title }) => {
  return (
    <BottomModal centerScreen isOpen={isOpen} onClose={onClose}>
      <div className="w-full flex flex-col justify-start items-start -mt-4">
        <Text type="P4" className="ts-accent mb-6">
          برای {title} لازم است به حساب کاربری خود وارد شوید یا حساب کاربری جدید ایجاد کنید.
        </Text>
        <Button onClick={onClick} bcolor="primary" classNameContainer="w-full" className="w-full h-12 rounded-xl">
          ورود / ثبت نام
        </Button>
      </div>
    </BottomModal>
  );
});

export { LoginPrompt };

const styles = {};
