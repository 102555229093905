/* eslint-disable */
import _, { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "rsuite";
import { useSnapshot } from "valtio";
import {
  ApiInstance,
  ConfigLink,
  ISearchResult,
  tools,
  useChangeTitle,
  useFetchQueryString,
  vState,
} from "../../../core";
import { useNotification } from "../../components";
import { useQueryString } from "./../../../core/hooks/useQueryString";
import { CardView, ChangeViewFooter, FilterModal, MapView, SearchMapHeader } from "./components";

type ViewMode = 1 | 2;

interface IQueryStringTransfer {
  q?: string;
  cl?: any;
  category?: string[];
  zoom?: string;
}

const Search = ({}) => {
  const metaTags = useChangeTitle("ویراژ - جستجو");
  let { q, cl, category, zoom } = useFetchQueryString<IQueryStringTransfer>();
  const snap = useSnapshot(vState);
  const navigate = useNavigate();
  const [ref, setRef] = useState<any>(null);
  const defaultLocation = [35.69979085412715, 51.33753633512243]; //AZADI square

  const [currentLocation, setCurrentLocation] = useState<any>(null);
  const [userLocation, setUserLocation] = useState<any>(null);
  const [bbox, setBbox] = useState<any>(null); //minLongitude,minLatitude,maxLongitude,maxLatitude
  const [zoomLevel, setZoomLevel] = useState<string>("16");
  const [loading, setLoading] = useState(false);
  const [locationLoading, setLocationLoading] = useState(false);
  const [viewMode, setViewMode] = useState<ViewMode>(1); //1: map, 2: list
  const [workshops, setWorkshops] = useState<any[]>([]);
  const [filter, setFilter] = useState<string[]>([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedWorkshop, setSelectedWorkshop] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResults, setSearchResults] = useState<ISearchResult[] | null>(null);
  const [selectedSearchResult, setSelectedSearchResult] = useState<ISearchResult | null>(null);

  const updateURL = (search: string | null, location: any | null, filter: string[] | null, zoom: string | null) => {
    navigate(
      {
        pathname: ConfigLink.search,
        search: useQueryString({
          q: search || undefined,
          category: filter || undefined,
          cl: location || undefined,
          zoom: zoom || undefined,
        }),
      },
      { replace: true },
    );
  };

  const getWorkshops = async (sort?: string | null) => {
    await ApiInstance.provider
      .getWorkshops(bbox, filter, sort ? sort : null, currentLocation)
      .then((res) => {
        console.log(res);
        setWorkshops(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا مجددا امتحان کنید.", "error", "جستجو");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!cl) {
      if ("geolocation" in navigator) {
        console.log("Available");

        navigator.geolocation.getCurrentPosition(
          function (pos) {
            console.log("position", pos);
            const loc = [pos.coords.latitude, pos.coords.longitude];
            setCurrentLocation(loc);
            updateURL(q || null, loc, category || null, zoom || null);
            if (q) {
              setSearchText(q);
            }
            if (category) {
              setFilter(category);
            }
            if (zoom) {
              setZoomLevel(zoom);
            }
          },
          function (position) {
            console.log("position Error", position.message);
            setCurrentLocation(defaultLocation);
            updateURL(q || null, defaultLocation, category || null, zoom || null);
            if (q) {
              setSearchText(q);
            }
            if (category) {
              setFilter(category);
            }
            if (zoom) {
              setZoomLevel(zoom);
            }
          },
          { timeout: 10000, enableHighAccuracy: true },
        );
      } else {
        console.log("Not Available");
      }
    } else {
      setCurrentLocation(cl);
      if (q) {
        setSearchText(q);
      }
      if (category) {
        setFilter(category);
      }
      if (zoom) {
        setZoomLevel(zoom);
      }
    }
  }, []);

  const getWorkshopChanges = () => {
    getWorkshops();
  };

  useEffect(() => {
    if (bbox) {
      getWorkshopChanges();
    }
  }, [bbox, filter]);

  useEffect(() => {
    if (selectedSearchResult) {
      if (ref && ref.current) {
        const { location } = selectedSearchResult;
        setCurrentLocation([location.y, location.x]);
        ref.current.moveToUserLocation([location.y, location.x]);
      }
    }
  }, [selectedSearchResult]);

  const getLocationSuccessful = (pos) => {
    let crd = pos.coords;

    console.log(`Latitude : ${crd.latitude}`);
    console.log(`Longitude: ${crd.longitude}`);
    console.log(`More or less ${crd.accuracy} meters.`);

    // if (ref && ref.current) {
    setUserLocation([crd.latitude, crd.longitude]);
    setLocationLoading(false);
    ref.current.moveToUserLocation([crd.latitude, crd.longitude]);
    // }
  };

  const getLocationFailed = (e) => {
    console.log("error getLocation", e.message);
    useNotification(
      "امکان نمایش موقعیت مکانی شما وجود ندارد. لطفا تنظیمات گوشی خود را بررسی نمایید.",
      "error",
      "موقعیت مکانی",
    );
    setLocationLoading(false);
  };

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.permissions.query({ name: "geolocation" }).then(function (result) {
        if (result.state === "granted") {
          console.log(result.state);
          setLocationLoading(true);
          window.navigator.geolocation.getCurrentPosition(getLocationSuccessful, getLocationFailed, {
            timeout: 10000,
            enableHighAccuracy: true,
          });
        } else if (result.state === "prompt") {
          console.log(result.state);
          if (tools.isIOS()) {
            setLocationLoading(true);
            window.navigator.geolocation.getCurrentPosition(getLocationSuccessful, getLocationFailed, {
              timeout: 10000,
              enableHighAccuracy: true,
            });
          }
        } else if (result.state === "denied") {
          //If denied then you have to show instructions to enable location
          useNotification(
            "شما دسترسی این برنامه به موقعیت مکانی خود را لغو کرده اید. لطفا از تنظیمات اجازه دسترسی به ویراژ را بدهید و از روشن بودن موقعیت مکانی دستگاه خود اطمینان حاصل کنید.",
            "warning",
            "عدم دسترسی",
            6000,
          );
        }
        result.onchange = function () {
          console.log(result.state);
        };
      });
    } else {
      console.log("not");
      useNotification("مرورگر شما از این قابلیت پشتیبانی نمی کند.", "error", "خطا");
    }
  };

  const modeChangeHandler = () => {
    setViewMode(viewMode === 1 ? 2 : 1);
    setSelectedWorkshop(null);
  };

  const getSearchResult = async (text, mapCenter) => {
    if (mapCenter) {
      setSelectedSearchResult(null);
      await ApiInstance.map
        .searchOnMap(text, mapCenter[0], mapCenter[1])
        .then((res) => {
          console.log(res);
          setSearchResults(res.data.items);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setSearchLoading(false);
        });
    } else {
      setSearchLoading(false);
    }
  };

  const searchButtonClicked = () => {
    setSearchLoading(true);
    getSearchResult(searchText, currentLocation);
  };

  const handleSearch = useCallback(
    debounce((text, location) => {
      setSearchLoading(true);
      updateURL(text, cl || null, filter || category || null, zoom || null);
      getSearchResult(text, location);
    }, 2000),
    [],
  );

  const handleChange = (e) => {
    setSearchText(e);
    handleSearch(e, currentLocation);
  };

  const resetSearchValue = () => {
    setSearchText("");
    updateURL(null, currentLocation, filter || category || null, zoom || null);
    if (searchResults && searchResults.length !== 0) {
      setSearchResults(null);
    }
    setSelectedSearchResult(null);
  };

  const closeSearchBox = () => {
    setSearchResults(null);
    setSelectedSearchResult(null);
  };

  const updateUrlHandler = useCallback((currentLocation, zoom, search, cat) => {
    updateURL(search, currentLocation, cat, zoom || null);
  }, []);

  useEffect(() => {
    const debouncedFetchData = _.debounce(
      () => updateUrlHandler(currentLocation, zoomLevel, q || searchText, category || filter),
      1500,
    );

    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, [currentLocation]);

  useEffect(() => {
    updateURL(q || null, currentLocation, filter, zoom || null);
  }, [filter]);

  const mapCenterChanged = (latLng) => {
    setCurrentLocation(latLng);
  };

  const filterChangeHandler = (f) => {
    setFilter(f);
  };

  const sortChangeHandler = (v) => {
    setLoading(true);
    getWorkshops(v);
  };

  const setZoomHandler = (n) => {
    setZoomLevel(n);
    updateURL(q || null, currentLocation, filter, n || null);
  };

  return (
    <>
      {metaTags}
      {loading && (
        <div className="w-full h-full absolute top-0 left-0 z-40 flex justify-center items-center">
          <div className="bg-black p-4 rounded-xl bg-opacity-70">
            <Loader size="sm" content="لطفا صبر کنید..." vertical className="ts-map-loader" />
          </div>
        </div>
      )}
      <>
        <div className="w-full h-screen relative overflow-hidden">
          {viewMode === 1 && (
            <SearchMapHeader
              searchText={searchText}
              searchResults={searchResults}
              selectedSearchResult={selectedSearchResult}
              resultSelected={(item) => setSelectedSearchResult(item)}
              closeSearchBox={closeSearchBox}
              loading={searchLoading}
              searchChanged={(e) => handleChange(e)}
              searchClicked={searchButtonClicked}
              onReset={resetSearchValue}
              filter={filter}
              filterOpen={() => setFilterOpen(true)}
            />
          )}
          <MapView
            reference={ref}
            setRef={(reference) => setRef(reference)}
            workshops={workshops}
            currentLocation={currentLocation}
            userLocation={userLocation}
            setCurrentLocation={(latLng) => mapCenterChanged(latLng)}
            setBbox={(coords) => setBbox(coords)}
            selectedWorkshop={selectedWorkshop}
            selectWorkshopHandler={(item) => setSelectedWorkshop(item)}
            zoomLevel={zoomLevel}
            setZoomLevel={(n) => setZoomHandler(n)}
          />
          {viewMode === 2 && (
            <div className="w-full h-full absolute top-0 left-0 bg-white z-40">
              <CardView
                workshops={workshops}
                filter={filter}
                filterOpen={() => setFilterOpen(true)}
                onSortChanged={(v) => sortChangeHandler(v)}
                screenLoading={loading}
              />
            </div>
          )}
        </div>
        <ChangeViewFooter
          selectedWorkshop={selectedWorkshop}
          modeChangeHandler={modeChangeHandler}
          viewMode={viewMode}
          onGetLocation={getUserLocation}
          locationLoading={locationLoading}
        />
      </>
      <FilterModal
        isOpen={filterOpen}
        onClose={() => setFilterOpen(false)}
        filter={filter}
        onFilterChanged={(f) => filterChangeHandler(f)}
      />
      {/* <Footer activeTab={3} /> */}
    </>
  );
};

export { Search as default };
