/* eslint-disable */
import { useEffect, useState } from "react";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { HiLogin, HiQuestionMarkCircle, HiUserCircle, HiUsers } from "react-icons/hi";
import { IoCarSport } from "react-icons/io5";
import { RiSettings5Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import user from "../../../assets/images/user-profile-icon.svg";
import wrench from "../../../assets/images/wrench-icon.svg";
import { ConfigLink, ServiceStorage, vState } from "../../../core";
import { useChangeTitle } from "../../../core/hooks";
import { ContainerScroll, Text } from "../../components";
import { LogoutModal, MenuButton, ProfileLoader, Version } from "./components";

const UserProfile = ({}) => {
  const metaTags = useChangeTitle("ویراژ - پروفایل");
  const snap = useSnapshot(vState);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [logoutShow, setLogoutShow] = useState(false);

  const accountClickHandler = () => {
    navigate(ConfigLink.account);
  };

  const settingClickHandler = () => {
    navigate(ConfigLink.setting);
  };

  const contactClickHandler = () => {
    navigate(ConfigLink.contactUs);
  };

  const inviteClickHandler = () => {
    navigate(ConfigLink.invite);
  };

  const partRequestClickHandler = () => {
    navigate(ConfigLink.partRequest);
  };

  const garageClickHandler = () => {
    navigate(ConfigLink.garage);
  };

  useEffect(() => {
    const profile = ServiceStorage.getAuth();

    if (profile) {
      vState.userInfo = profile;
    }

    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={50} className="">
        {loading ? (
          <ProfileLoader />
        ) : (
          <>
            <div className="w-full flex justify-start items-center mb-3">
              <img src={user} alt="tst-user" className="w-14 h-14 rounded-full" />

              <div className="flex flex-col justify-between items-start mr-3">
                <Text type="H4" className="ts-accent">
                  {`${snap.userInfo.name || "بدون نام"}`}
                </Text>
              </div>
            </div>

            <MenuButton
              onClick={accountClickHandler}
              title="حساب کاربری"
              icon={<HiUserCircle size={23} className="ts-accent" />}
            />

            <MenuButton
              onClick={settingClickHandler}
              title="تنظیمات"
              icon={<RiSettings5Fill size={23} className="ts-accent -mt-1" />}
            />

            <MenuButton
              onClick={garageClickHandler}
              title="گاراژ من"
              icon={<IoCarSport size={23} className="ts-accent -mt-1" />}
            />

            {snap.activeFeatures.includes("part_order") && (
              <MenuButton
                onClick={partRequestClickHandler}
                title="درخواست قطعه"
                icon={<img src={wrench} alt="wrench" className="ts-wrench-icon" />}
              />
            )}

            {snap.activeFeatures.includes("share") && (
              <MenuButton
                onClick={inviteClickHandler}
                title="دعوت از دیگران"
                icon={<HiUsers size={23} className="ts-accent -mt-1" />}
              />
            )}

            <MenuButton
              onClick={contactClickHandler}
              title="تماس با ما"
              icon={<HiQuestionMarkCircle size={23} className="ts-accent" />}
            />

            <MenuButton
              onClick={() => window.open("https://virazh.ir/about", "_blank")}
              title="درباره ما"
              icon={<BsFillInfoCircleFill size={20} className="ts-accent" />}
              isExternal
            />

            <MenuButton
              onClick={() => setLogoutShow(true)}
              title="خروج از حساب"
              icon={<HiLogin size={21} className="ts-secondary-red" />}
              logout
            />

            {/* --------- VERSION NUMBER ---------- */}
            <Version />
          </>
        )}
      </ContainerScroll>
      <LogoutModal isOpen={logoutShow} onClose={() => setLogoutShow(false)} />
    </>
  );
};

export { UserProfile as default };
