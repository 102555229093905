/* eslint-disable */
import { ApiInstance, vState } from "core";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { Loader, SelectPicker } from "rsuite";
import { FeedbackCard, Text, useNotification } from "ui/components";
import { useSnapshot } from "valtio";

const env = import.meta.env;

interface IFeedbackStatisticsProps {
  id: string;
}

type SORT_TYPE = "NON" | "DATE" | "NAME";

const FeedbackComments: React.FC<IFeedbackStatisticsProps> = React.memo(({ id }) => {
  const snap = useSnapshot(vState);
  const navigate = useNavigate();

  const [feedbacks, setFeedbacks] = useState<any[]>([]);

  const [count, setCount] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);

  const [sortReminders, setSortReminders] = useState<SORT_TYPE>("DATE");

  const getFeedbacks = async (sort: SORT_TYPE, pageIncreased: boolean) => {
    await ApiInstance.provider
      .getFeedbacks(pageCount + 1, sort, id)
      .then((res) => {
        console.log(res);
        setCount(res.data.meta.total);
        if (pageIncreased) {
          let data = [...feedbacks, ...res.data.data];
          setFeedbacks(data);
          setPageCount((prev) => prev + 1);
        } else {
          setFeedbacks(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دوباره تلاش کنید.", "error", "دیدگاه مشتریان");
        setLoading(false);
        vState.isEndOfPage = false;
      })
      .finally(() => {
        setLoading(false);
        vState.isEndOfPage = false;
      });
  };

  useEffect(() => {
    getFeedbacks(sortReminders, false);
  }, []);

  useEffect(() => {
    if (snap.isEndOfPage && count > feedbacks.length && !loading) {
      getFeedbacks(sortReminders, true);
    } else {
      vState.isEndOfPage = false;
    }
  }, [snap.isEndOfPage]);

  const sortChangeHandler = (value) => {
    setSortReminders(value);
    setLoading(true);
    getFeedbacks(value, false);
  };

  const sortData = [
    {
      label: "به ترتیب تاریخ",
      value: "DATE",
    },
    {
      label: "به ترتیب الفبا",
      value: "NAME",
    },
  ];

  if (loading || feedbacks.length !== 0) {
    return (
      <>
        <Text type="H4" className="ts-accent mt-8 mb-4 text-right">
          دیدگاه مشتریان
        </Text>

        {(loading || feedbacks.length !== 0) && (
          <div className="w-full flex justify-between items-center mb-6">
            {loading ? (
              <Skeleton className="w-28" />
            ) : (
              <Text type="H6" className="ts-accent">
                {`${count} دیدگاه`}
              </Text>
            )}
            <SelectPicker
              size="lg"
              data={sortData}
              value={sortReminders}
              disabled={loading}
              cleanable={false}
              onChange={(value: any) => sortChangeHandler(value)}
              searchable={false}
              placeholder="مرتب سازی"
              className={`w-36 h-12 outline-none rounded-xl`}
            />
          </div>
        )}

        {loading
          ? [1, 2, 3, 4, 5, 6].map(() => {
              return (
                <Skeleton
                  style={{ height: `77px` }}
                  containerClassName="w-full"
                  className={`w-full rounded-2xl mb-2`}
                />
              );
            })
          : feedbacks.map((item) => {
              return <FeedbackCard accordion key={item.id} data={item} />;
            })}
        {snap.isEndOfPage && !loading && (
          <div className="w-full flex justify-center mt-4 mb-14">
            <Loader size="sm" />
          </div>
        )}
      </>
    );
  } else {
    return null;
  }
});

export { FeedbackComments as default };
