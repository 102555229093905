/* eslint-disable */
import { ConfigLink, useQueryString } from "core";
import React from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useNavigate } from "react-router-dom";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import banner1 from "../../../../assets/images/advertisement/ad-banner-1.svg";
import banner2 from "../../../../assets/images/advertisement/ad-banner-2.svg";
import banner3 from "../../../../assets/images/advertisement/ad-banner-3.svg";
import voteBanner from "../../../../assets/images/advertisement/vote-banner.svg";

interface IAdvertisementProps {}

const Advertisement: React.FC<IAdvertisementProps> = React.memo(() => {
  const navigate = useNavigate();

  const adBanners = [
    {
      id: 1,
      img: banner3,
      onClick: () => {
        navigate({
          pathname: ConfigLink.workshopInfo,
          search: useQueryString({ id: "dfd51ca5-7671-4a61-943a-ce99948d0e29" }),
        });
      },
    },
    {
      id: 0,
      img: banner1,
      onClick: () => {
        window.open("https://carpo.tech/hardware/", "_blank");
      },
    },
  ];

  return (
    <div className="w-full flex flex-col justify-center items-center sm:grid sm:grid-cols-2 sm:gap-3">
      <img
        src={voteBanner}
        onClick={() => window.open("https://virazh.ir/best-workshops", "_blank")}
        alt={`ad-1`}
        className={`w-full object-cover rounded-2xl mb-3 cursor-pointer`}
      />

      <Swiper
        slidesPerView={1}
        spaceBetween={3}
        modules={[Pagination, Navigation, Autoplay]}
        lazy={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        loop
        className="ts-ad-banner-swiper"
        pagination={{
          clickable: false,
        }}
      >
        {adBanners.map((item, index) => {
          return (
            <SwiperSlide key={index} className={`w-full`}>
              <img
                src={item.img}
                onClick={item.onClick}
                alt={`ad-${item.id}0`}
                className={`w-full object-cover rounded-2xl mb-3 cursor-pointer`}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>

      <img
        src={banner2}
        onClick={() => navigate(ConfigLink.support)}
        alt={`ad-2`}
        className={`w-full object-cover rounded-2xl mb-3 cursor-pointer col-span-2`}
      />
    </div>
  );
});

export { Advertisement };

const styles = {};
