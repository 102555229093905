/* eslint-disable */
import React, { useEffect, useState } from "react";
import { HiArrowNarrowLeft, HiArrowNarrowRight } from "react-icons/hi";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useNavigate } from "react-router-dom";
import { Input, SelectPicker } from "rsuite";
import { CarBrandSelector } from "ui/components/CarBrandSelector";
import { CarRegisteredBefore } from "ui/components/CarRegisteredBefore";
import { CarsModal } from "ui/components/CarsModal";
import { useSnapshot } from "valtio";
import banner from "../../../assets/images/setup-banner.svg";
import {
  ApiInstance,
  ConfigLink,
  fuelTypes,
  monthlyUsage,
  ServiceStorage,
  tools,
  useChangeTitle,
  useFetchQueryString,
  useObjectState,
  useQueryString,
  vState,
} from "../../../core";
import { Button, SetupCarTemplate, Text, useNotification } from "../../components";
import { InputLicensePlate } from "../Car/components";
import { EditCarPageLoader } from "./components";

interface IAddCarPageProps {}

interface IQueryStringTransfer {
  type: "edit";
}

const env = import.meta.env;

const AddCarPage: React.FC<IAddCarPageProps> = React.memo(() => {
  //@ts-ignore
  const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  const metaTags = useChangeTitle("اطلاعات خودرو");
  const navigate = useNavigate();
  const snap = useSnapshot(vState);
  let { type } = useFetchQueryString<IQueryStringTransfer>();
  const returnUrl = ServiceStorage.getTempReturnURL();

  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const [alreadyRegistered, setAlreadyRegistered] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const [plateValue, setPlateValue] = useObjectState({
    firstSection: "",
    secondSection: "",
    thirdSection: "",
    letter: "ا",
  });
  const { firstSection, letter, secondSection, thirdSection } = plateValue;

  const [carInfo, setCarInfo] = useObjectState<any>({
    carType: "",
    carId: null,
    fuelType: "",
    year: "",
    kilometerAvg: "",
  });
  const [yearError, setYearError] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const { carType, fuelType, carId, year, kilometerAvg } = carInfo;

  useEffect(() => {
    setDisabled(
      carId === null ||
        // fuelType.length === 0 ||
        // kilometerAvg.length === 0 ||
        (year.length !== 0 && year.length < 4) ||
        yearError ||
        firstSection.length === 0 ||
        secondSection.length === 0 ||
        thirdSection.length === 0 ||
        Object.keys(errors).length !== 0,
    );
  }, [plateValue, carInfo, carId, year, yearError]);

  useEffect(() => {
    const regex = /[1|۱][3-4 | ۳-۴][0-9 | \u06F0-\u06F9][0-9 | \u06F0-\u06F9]/;
    setYearError(!regex.test(year) && year.length !== 0);
  }, [year]);

  useEffect(() => {
    if (firstSection.length === 2 && secondSection.length === 3 && thirdSection.length === 2) {
      checkPlate();
    }
  }, [plateValue]);

  const checkPlate = async () => {
    const plate = tools.getFormattedPlate(firstSection, letter, secondSection, thirdSection);
    await ApiInstance.plates
      .checkPlateExists(plate)
      .then((res) => {
        console.log(res);
        if (res.data.meta.found) {
          let errorsObj = { ...errors };
          const mobile = res.data.data.mobile || "";
          console.log(mobile, snap.userInfo.username);

          errorsObj.plate = "این شماره پلاک قبلا ثبت شده است.";
          setErrors(errorsObj);
        } else {
          removeField("plate");
        }
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا مجددا تلاش کنید", "error");
      });
  };

  const removeField = (field) => {
    let cn = { ...errors };
    delete cn[field];
    setErrors(cn);
  };

  const getCarInfo = async () => {
    const id = ServiceStorage.getSetupCar();
    await ApiInstance.plates
      .getSingleCar(id || "")
      .then((res) => {
        console.log(res);
        const data = res.data.data;
        setCarInfo({
          carType: data.carName || "",
          carId: data.car || null,
          kilometerAvg: data.monthlyUsage || "",
          year: data.buildYear || "",
          fuelType: data.fuelType || "",
        });
        setPlateValue({
          firstSection: data.plateNumber.slice(0, 2),
          secondSection: data.plateNumber.substring(3, 6),
          thirdSection: data.plateNumber.substring(7, 9),
          letter: data.plateNumber.slice(2, 3),
        });
      })
      .catch((err) => console.log("err", err))
      .finally(() => {
        setPageLoading(false);
      });
  };

  useEffect(() => {
    if (type && type === "edit") {
      setPageLoading(true);
      getCarInfo();
    }
  }, []);

  const getFormattedData = () => {
    const plate = tools.getFormattedPlate(firstSection, letter, secondSection, thirdSection);

    return {
      plateNumber: plate,
      carId: carId ? carId.id : null,
      ...(year ? { buildYear: +year } : undefined),
      ...(carType ? { carName: carType } : undefined),
      ...(fuelType ? { fuelType } : undefined),
      ...(kilometerAvg ? { monthlyUsage: kilometerAvg } : undefined),
    };
  };

  const editCarHandler = async () => {
    setLoading(true);
    const id = ServiceStorage.getSetupCar();
    const finalData = getFormattedData();

    await ApiInstance.plates
      .editCar(id || "", finalData)
      .then((res: any) => {
        console.log(res);
        useNotification("اطلاعات خودرو ویرایش شد.", "success", "اضافه کردن خودرو");
        // ServiceStorage.setTempReturnURL(`${ConfigLink.createCar}${window.location.search}`);
        navigate(ConfigLink.homePage, { replace: true });
      })
      .catch((err) => {
        console.log("err", err);
        if (err.response.status === 400 && err.response.data.message.toString().includes("already registered.")) {
          setAlreadyRegistered(true);
        } else {
          useNotification(
            "مشکلی رخ داده است. لطفا اتصال خود را بررسی کنید و دوباره سعی کنید.",
            "error",
            "اضافه کردن خودرو",
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addCarHandler = async () => {
    const finalData = getFormattedData();

    await ApiInstance.plates
      .addCar(finalData)
      .then((res: any) => {
        console.log(res);
        useNotification("خودرو با موفقیت به گاراژ شما اضافه شد", "success", "اضافه کردن خودرو");
        navigate(
          {
            pathname: ConfigLink.createCarRegisterKilometer,
            search: useQueryString({ id: res.data.data.id }),
          },
          { replace: true },
        );
      })
      .catch((err) => {
        console.log("err", err);
        if (err.response.status === 400 && err.response.data.message.toString().includes("already registered.")) {
          setAlreadyRegistered(true);
        } else {
          useNotification(
            "مشکلی رخ داده است. لطفا اتصال خود را بررسی کنید و دوباره سعی کنید.",
            "error",
            "اضافه کردن خودرو",
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const registerDataHandler = async () => {
    setLoading(true);

    if (type && type === "edit") {
      editCarHandler();
    } else {
      addCarHandler();
    }
  };

  const previousStepHandler = () => {
    navigate(-1);
  };

  const checkStateValue = (state: string, operation: () => void) => {
    if (plateValue[state].length === 0) {
      operation();
    }
  };

  const setPlateValues = (e, place) => {
    // if (/^[\u06F0-\u06F9]*$/.test(e) || /^[0-9]*$/g.test(e)) {
    if ("plate" in errors) {
      removeField("plate");
    }
    setPlateValue({ [place]: e });
    // }
  };

  return (
    <>
      <SetupCarTemplate metaTag={metaTags} className="-mt-14" wrapperClassName="mt-8">
        <img src={banner} alt="banner" className="mb-8 w-full" />
        <Text type="H3" className="ts-accent">
          {type && type === "edit" ? "ویرایش خودرو" : "اضافه کردن خودرو"}
        </Text>
        <Text type="P3" className="ts-gray-08 mt-3 mb-8">
          اطلاعات خودروی خود را تکمیل نمایید.
        </Text>

        {pageLoading ? (
          <EditCarPageLoader />
        ) : (
          <>
            <Text type="H6" className="ts-gray-07 mb-2">
              شماره پلاک
            </Text>
            <InputLicensePlate
              lowMargin
              plateValue={plateValue}
              setPlateValues={setPlateValues}
              checkStateValue={checkStateValue}
              letterChange={(value) => setPlateValue({ letter: value })}
              error={"plate" in errors}
              errorText={errors.plate || ""}
            />

            <CarBrandSelector
              value={carId ? carId.name : null}
              highMarginTop
              onClick={() => {
                setModalOpen(true);
              }}
            />

            <Text type="H6" className="ts-gray-07 mb-2">
              نام خودرو (اختیاری)
            </Text>
            <Input
              value={carType}
              required
              onChange={(e) => setCarInfo({ carType: e })}
              className={`w-full rounded-xl mb-8 outline-none h-12 placeholder:text-gray-300`}
              placeholder="مانند پراید ۱۲۱، پژو ۲۰۶، ..."
            />

            <Text type="H6" className="ts-gray-07 mb-2">
              سال ساخت (اختیاری)
            </Text>
            <Input
              type={isIOS() ? "text" : "number"}
              pattern="[0-9]*"
              value={year}
              placeholder="یک عدد چهار رقمی؛ مثلا 1402"
              onChange={(e) => {
                if (e.length <= 4) {
                  setCarInfo({ year: e });
                }
              }}
              className={`w-full transition-all rounded-xl mb-8 outline-none ${
                yearError && year.length > 0 && "ts-error-border"
              } h-12 placeholder:text-gray-300`}
            />
            {/* <SelectPicker
          size="lg"
          data={years}
          value={year}
          onChange={(e) => setCarInfo({ year: e })}
          block
          searchable={false}
          cleanable={false}
          placeholder="سال ساخت خودرو را انتخاب کنید"
          className={`w-full h-12 mb-8 outline-none rounded-xl`}
        /> */}
            <Text type="H6" className="ts-gray-07 mb-2">
              نوع سوخت (اختیاری)
            </Text>
            <SelectPicker
              size="lg"
              placement="auto"
              data={fuelTypes}
              value={fuelType}
              onChange={(e) => setCarInfo({ fuelType: e })}
              block
              searchable={false}
              cleanable={false}
              placeholder="نوع سوخت خودرو را انتخاب کنید"
              className={`w-full h-12 mb-8 outline-none rounded-xl`}
            />

            <Text type="H6" className="ts-gray-07 mb-2">
              متوسط کارکرد در ماه (اختیاری)
            </Text>
            <SelectPicker
              size="lg"
              placement="auto"
              data={monthlyUsage}
              value={kilometerAvg}
              onChange={(e) => setCarInfo({ kilometerAvg: e })}
              block
              searchable={false}
              cleanable={false}
              placeholder="متوسط کارکرد خودروی خود را انتخاب کنید"
              className={`w-full h-12 mb-8 outline-none rounded-xl`}
            />
            <div className="w-full flex justify-between items-center gap-8">
              <Button
                onClick={registerDataHandler}
                loading={loading}
                disabled={disabled}
                icon={<HiArrowNarrowRight size={23} className="text-white ml-2" />}
                bcolor="primary"
                textType="H6"
                classNameContainer="ts-half-width"
                className="w-full rounded-xl h-12"
              >
                {type && type === "edit" ? "ویرایش" : "ثبت خودرو"}
              </Button>

              <Button
                onClick={previousStepHandler}
                icon={<HiArrowNarrowLeft size={23} className="ts-primary mr-2" />}
                iconPosition="afterText"
                bcolor="primaryTransparent"
                textType="H6"
                classNameContainer="ts-half-width"
                className="w-full rounded-xl h-12"
              >
                قبلی
              </Button>
            </div>
          </>
        )}
      </SetupCarTemplate>

      <CarRegisteredBefore isOpen={alreadyRegistered} onClose={() => setAlreadyRegistered(false)} />
      <CarsModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        isSingleChoice
        selectedCars={carId}
        selectCarHandler={(value) => {
          setCarInfo({ carId: value });
        }}
      />
    </>
  );
});

export { AddCarPage as default };

const styles = {};
