/* eslint-disable */
import React, { useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { CustomProvider } from "rsuite";
import { Guard } from "ui/components/Guard";
import CarStatus from "ui/views/Car/CarStatus";
import Garage from "ui/views/Car/Garage";
import History from "ui/views/Car/History";
import HistoryDetail from "ui/views/Car/HistoryDetail";
import ReminderDetail from "ui/views/Car/ReminderDetail";
import HomePage from "ui/views/Home/HomePage";
import Support from "ui/views/Home/Support";
import CompleteAccount from "ui/views/Introductions/CompleteAccount";
import CompleteInvite from "ui/views/Introductions/CompleteInvite";
import Introduction from "ui/views/Introductions/Introduction";
import OwnerPreRegister from "ui/views/PreRegister/OwnerPreRegister";
import OwnerReminders from "ui/views/PreRegister/OwnerReminders";
import Account from "ui/views/Profile/Account";
import ContactUs from "ui/views/Profile/ContactUs";
import Invite from "ui/views/Profile/Invite";
import PartRequest from "ui/views/Profile/PartRequest";
import PartRequestDetail from "ui/views/Profile/PartRequestDetail";
import Setting from "ui/views/Profile/Setting";
import UserProfile from "ui/views/Profile/UserProfile";
import Search from "ui/views/SearchWorkshops/Search";
import WorkshopInfo from "ui/views/SearchWorkshops/WorkshopInfo";
import PartDetail from "ui/views/Shop/PartDetail";
import PartShop from "ui/views/Shop/PartShop";
import AddCarPage from "ui/views/Workspace/AddCarPage";
import BeginJourney from "ui/views/Workspace/BeginJourney";
import RegisterKilometer from "ui/views/Workspace/RegisterKilometer";
import SetupIntro from "ui/views/Workspace/SetupIntro";
import SetupSteps from "ui/views/Workspace/SetupSteps";
import { AuthProvider } from "./auth";
import { configs } from "./configs/configs";
import { ConfigLink } from "./core";
import { AppProvider } from "./core/contexts/context.app";
import { ConfigFetcher } from "./ui/components";
import { LoadingCustom } from "./ui/components/LoadingPage";
import RedirectPage from "./ui/views/redirect";

const env = import.meta.env;
//================= [Solo Pages] =================
const ErrorPage = React.lazy(() => import("./ui/views/ErrorPages/ErrorPage"));

//Layouts
const MainPagesLayout = React.lazy(() => import("./ui/_layouts/MainPagesLayout"));
const SubPagesLayout = React.lazy(() => import("./ui/_layouts/SubPagesLayout"));
const TempPagesLayout = React.lazy(() => import("./ui/_layouts/TempPagesLayout"));

const App = () => {
  useEffect(() => {
    // ENABLE/DISABLE Console Logs
    if (configs().prod === "true") {
      console.log = function () {};
    }
  }, []);

  const router = createBrowserRouter([
    // main
    {
      element: <MainPagesLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: ConfigLink.homePage,
          element: (
            <Guard forceAuthenticated>
              <HomePage />
            </Guard>
          ),
        },
        {
          path: ConfigLink.intro,
          element: <Introduction />,
        },
        {
          path: ConfigLink.completeAccount,
          element: (
            <Guard forceAuthenticated>
              <CompleteAccount />
            </Guard>
          ),
        },
      ],
    },
    // sub
    {
      element: <SubPagesLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: ConfigLink.support,
          element: (
            <Guard forceAuthenticated>
              <Support />
            </Guard>
          ),
        },
        {
          path: ConfigLink.intro,
          element: <Introduction />,
        },
        {
          path: ConfigLink.completeAccount,
          element: (
            <Guard forceAuthenticated>
              <CompleteAccount />
            </Guard>
          ),
        },
        {
          path: ConfigLink.profile,
          element: (
            <Guard forceAuthenticated>
              <UserProfile />
            </Guard>
          ),
        },
        {
          path: ConfigLink.account,
          element: (
            <Guard forceAuthenticated>
              <Account />
            </Guard>
          ),
        },
        {
          path: ConfigLink.setting,
          element: (
            <Guard forceAuthenticated>
              <Setting />
            </Guard>
          ),
        },
        {
          path: ConfigLink.contactUs,
          element: (
            <Guard forceAuthenticated>
              <ContactUs />
            </Guard>
          ),
        },
        {
          path: ConfigLink.invite,
          element: (
            <Guard forceAuthenticated>
              <Invite />
            </Guard>
          ),
        },
        {
          path: ConfigLink.partRequest,
          element: (
            <Guard forceAuthenticated>
              <PartRequest />
            </Guard>
          ),
        },
        {
          path: ConfigLink.partRequestDetail,
          element: (
            <Guard forceAuthenticated>
              <PartRequestDetail />
            </Guard>
          ),
        },
        {
          path: ConfigLink.garage,
          element: (
            <Guard forceAuthenticated>
              <Garage />
            </Guard>
          ),
        },
        {
          path: ConfigLink.carStatus,
          element: (
            <Guard forceAuthenticated>
              <CarStatus />
            </Guard>
          ),
        },
        {
          path: ConfigLink.reminderDetail,
          element: (
            <Guard forceAuthenticated>
              <ReminderDetail />
            </Guard>
          ),
        },
        {
          path: ConfigLink.history,
          element: (
            <Guard forceAuthenticated>
              <History />
            </Guard>
          ),
        },
        {
          path: ConfigLink.shop,
          errorElement: <ErrorPage />,
          element: (
            <Guard forceAuthenticated>
              <PartShop />
            </Guard>
          ),
        },
        {
          path: ConfigLink.PartDetail,
          errorElement: <ErrorPage />,
          element: (
            <Guard forceAuthenticated>
              <PartDetail />
            </Guard>
          ),
        },
      ],
    },
    // pre-register
    {
      element: <TempPagesLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: ConfigLink.tempOwnerInfo,
          errorElement: <ErrorPage />,
          element: <OwnerReminders />,
        },
        {
          path: ConfigLink.ownerPreRegister,
          errorElement: <ErrorPage />,
          element: <OwnerPreRegister />,
        },
      ],
    },
    // noHeader
    {
      path: ConfigLink.redirect,
      errorElement: <ErrorPage />,
      element: <RedirectPage />,
    },
    {
      path: ConfigLink.createCarWelcome,
      errorElement: <ErrorPage />,
      element: (
        <Guard forceAuthenticated>
          <SetupIntro />
        </Guard>
      ),
    },
    {
      path: ConfigLink.createCar,
      errorElement: <ErrorPage />,
      element: (
        <Guard forceAuthenticated>
          <AddCarPage />
        </Guard>
      ),
    },
    {
      path: ConfigLink.createCarRegisterJourney,
      errorElement: <ErrorPage />,
      element: (
        <Guard forceAuthenticated>
          <BeginJourney />
        </Guard>
      ),
    },
    {
      path: ConfigLink.createCarRegisterKilometer,
      errorElement: <ErrorPage />,
      element: (
        <Guard forceAuthenticated>
          <RegisterKilometer />
        </Guard>
      ),
    },
    {
      path: ConfigLink.createCarRegisterSteps,
      errorElement: <ErrorPage />,
      element: (
        <Guard forceAuthenticated>
          <SetupSteps />
        </Guard>
      ),
    },
    {
      path: ConfigLink.search,
      errorElement: <ErrorPage />,
      element: (
        <Guard forceAuthenticated>
          <Search />
        </Guard>
      ),
    },
    {
      path: ConfigLink.workshopInfo,
      errorElement: <ErrorPage />,
      element: (
        <Guard forceAuthenticated>
          <WorkshopInfo />
        </Guard>
      ),
    },
    {
      path: ConfigLink.completeInvitation,
      errorElement: <ErrorPage />,
      element: (
        <Guard forceAuthenticated>
          <CompleteInvite />
        </Guard>
      ),
    },
    {
      path: ConfigLink.historyDetail,
      errorElement: <ErrorPage />,
      element: (
        <Guard forceAuthenticated>
          <HistoryDetail />
        </Guard>
      ),
    },
  ]);

  return (
    <AuthProvider>
      <AppProvider>
        <CustomProvider>
          <React.Suspense fallback={<LoadingCustom />}>
            <ConfigFetcher>
              <RouterProvider router={router} />
            </ConfigFetcher>
          </React.Suspense>
        </CustomProvider>
      </AppProvider>
    </AuthProvider>
  );
};

export default App;
