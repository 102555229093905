/* eslint-disable */
import { ServiceStorage } from "core";
import React from "react";
import { FiPlusSquare } from "react-icons/fi";
import { IoShareOutline } from "react-icons/io5";
import { BottomModal, Button, Text } from "../../../components";

interface IPwaModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const PwaModal: React.FC<IPwaModalProps> = React.memo(({ isOpen, onClose }) => {
  const doneClickHandler = () => {
    ServiceStorage.setPWAVisitStatus();
    onClose();
  };

  return (
    <BottomModal isOpen={isOpen} onClose={onClose} forPWA>
      <div className="w-full h-full flex flex-col justify-center items-center relative md:px-6">
        <div className="w-full text-center ts-hide-scroll-but-keep-working -mt-10 max-2xs:-mt-5 max-2xs:pb-20">
          <Text type="H1" className="ts-primary text-5xl font-black ts-letter-space mb-20 max-2xs:mb-10">
            ویراژ
          </Text>
          <Text type="H4" className="ts-gray-08 mb-6">
            اضافه کردن اپلیکیشن ویراژ
          </Text>
          <Text type="P3" className="ts-gray-08 text-center">
            با این کار می‌توانید از طریق صفحه اصلی موبایل خود، به راحتی از خدمات ویراژ استفاده کنید.
          </Text>

          <hr className="w-full h-px my-10 ts-bg-gray-02 border-gray-100" />

          <div className="w-full flex justify-between items-center">
            <Text type="P4" className="ts-gray-07">
              ۱- در نوار پایین روی دکمه Share کلیک کنید.
            </Text>
            <IoShareOutline size={26} color="ts-accent -mt-1" />
          </div>
          <div className="w-full flex justify-between items-center">
            <Text type="P4" className="ts-gray-07 my-6">
              ۲- گزینه Add to home screen را انتخاب کنید.
            </Text>
            <FiPlusSquare size={26} color="ts-accent -mt-1" />
          </div>
          <div className="w-full flex justify-between items-center">
            <Text type="P4" className="ts-gray-07">
              ۳- در نوار بالا روی گزینه Add کلیک کنید.
            </Text>
            <Text type="H5" className="ts-accent">
              Add
            </Text>
          </div>
        </div>

        <Button
          onClick={doneClickHandler}
          bcolor="primary"
          classNameContainer="w-full md:bottom-6 md:px-6 absolute bottom-0"
          className="w-full h-12"
        >
          متوجه شدم
        </Button>
      </div>
    </BottomModal>
  );
});

export { PwaModal };

const styles = {};
