/* eslint-disable */
import React from "react";
import { BsArrowLeft } from "react-icons/bs";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Text } from "ui/components";
import admission from "../../../../assets/images/admission-icn.svg";
import maps from "../../../../assets/images/maps-icn.svg";
import reminders from "../../../../assets/images/reminder-icn.svg";

interface IFeaturesListProps {}

const FeaturesList: React.FC<IFeaturesListProps> = React.memo(() => {
  const data = [
    {
      id: 0,
      label: "پذیرش و ترخیص",
      icon: admission,
      features: [
        {
          id: 0,
          label: "امکان پذیرش خودرو با پلاک",
        },
        {
          id: 1,
          label: "دریافت پیامک هنگام پذیرش و ترخیص خودرو",
        },
        {
          id: 2,
          label: "ثبت کارکرد خودرو برای اطمینان از عدم استفاده ",
        },
      ],
    },
    {
      id: 1,
      label: "یادآور سرویس",
      icon: reminders,
      features: [
        {
          id: 0,
          label: "امکان ثبت کارکرد خودرو",
        },
        {
          id: 1,
          label: "یادآوری انجام سرویس های دوره ای بر اساس زمان و کیلومتر",
        },
        {
          id: 2,
          label: "ثبت تاریخچه سرویس های دوره ای خودرو",
        },
      ],
    },
    {
      id: 2,
      label: "جستجو روی نقشه",
      icon: maps,
      features: [
        {
          id: 0,
          label: "امکان جستجوی انواع کسب‌وکارهای خودویی ",
        },
        {
          id: 1,
          label: "مشاهده خدمات و نظرات دیگران درباره کسب‌وکارها",
        },
        {
          id: 2,
          label: "مسیریابی و نمایش نزدیکترین اتو سرویس",
        },
      ],
    },
  ];

  return (
    <div className="w-full ts-features-wrapper">
      <Swiper
        slidesPerView={1}
        spaceBetween={20}
        modules={[Pagination]}
        pagination={{
          clickable: true,
          el: ".swiper-custom-pagination",
        }}
      >
        {data.map((item, index) => {
          return (
            <SwiperSlide key={index} style={{ height: "195px" }} className={`w-full`}>
              <div
                key={item.id}
                className="w-full ts-owner-features-box p-4 flex flex-col justify-start items-start rounded-xl"
              >
                <div className="flex justify-center items-center mb-2">
                  <img src={`${item.icon}`} alt={item.label} className="w-9 h-9" />
                  <Text type="H5" className="ts-accent mr-3">
                    {item.label}
                  </Text>
                </div>
                <ul className="list-disc mr-6 mb-4">
                  {item.features.map((feature) => {
                    return (
                      <li className="ts-text-p4 ts-gray-07" key={feature.id}>
                        {feature.label}
                      </li>
                    );
                  })}
                </ul>
                <div
                  onClick={() => window.open("https://virazh.ir/owners", "_blank")}
                  className="flex justify-center items-center cursor-pointer mr-6"
                >
                  <Text type="H6" className="ts-primary ml-2 ">
                    مشاهده اطلاعات بیشتر
                  </Text>
                  <BsArrowLeft size={22} className="ts-primary" />
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="swiper-custom-pagination" />
    </div>
  );
});

export { FeaturesList };

const styles = {};
