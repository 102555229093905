/* eslint-disable */
import { ApiInstance } from "core";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Panel } from "rsuite";
import { Text, useNotification } from "../../../components";

interface IInformationProps {}

const Information: React.FC<IInformationProps> = React.memo(() => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);

  const getServices = async () => {
    await ApiInstance.common
      .getServicesWithDescription()
      .then((res) => {
        console.log("info", res);
        setServices(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا مجددا امتحان کنید.", "error", "سرویس ها");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getServices();
  }, []);

  return (
    <div className="w-full flex flex-col justify-start items-center">
      {loading
        ? [1, 2, 3].map((item) => {
            return (
              <Skeleton
                key={item}
                style={{ height: "61px" }}
                containerClassName="w-full"
                className="w-full rounded-xl mb-2"
              />
            );
          })
        : services.map((item: any) => {
            return (
              <Panel key={item.id} header={item.name || "---"} collapsible className="w-full ts-shadow rounded-xl mb-2">
                <Text type="P4" className="ts-gray-07">
                  {item.description || "---"}
                </Text>
              </Panel>
            );
          })}
    </div>
  );
});

export { Information };
