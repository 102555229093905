/* eslint-disable */
import React, { useEffect, useState } from "react";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { Loader } from "rsuite";
import banner from "../../../assets/images/setup-banner.svg";
import { ApiInstance, ConfigLink, ServiceStorage, useChangeTitle, vState } from "../../../core";
import { Button, SetupCarTemplate, Text, useNotification } from "../../components";

interface IBeginJourneyProps {}

const env = import.meta.env;

const BeginJourney: React.FC<IBeginJourneyProps> = React.memo(({}) => {
  const metaTags = useChangeTitle("ویراژ - ثبت وضعیت خودرو");
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [time, setTime] = useState(0);

  const nextLevelHandler = () => {
    navigate(ConfigLink.createCarRegisterKilometer);
  };

  const previousStepHandler = () => {
    navigate(-1);
  };

  const getServices = async () => {
    await ApiInstance.common
      .getSetupServices()
      .then((res) => {
        console.log(res);
        vState.setupServices = res.data.data;
        ServiceStorage.setSetupServices(res.data.data);
        setTime(res.data.data.length);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا مجددا امتحان کنید.", "error", "سرویس ها");
        setHasError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getServices();
  }, []);

  return (
    <SetupCarTemplate metaTag={metaTags} className="-mt-14" wrapperClassName="mt-8">
      <img src={banner} alt="banner" className="mb-8 w-full" />
      <Text type="H3" className="ts-accent">
        ثبت آخرین وضعیت خودرو
      </Text>
      <Text type="P3" className="ts-gray-08 mt-4">
        پس از اضافه کردن خودروی خود، برای ارایه خدمات بهتر خواهشمندیم آخرین اطلاعات سرویس های خودرو را وارد نمایید تا
        پروفایل خودروی شما بر اساس آنها ایجاد گردد.
      </Text>
      <div className="flex justify-center items-center my-8">
        <Text type="P3" className="ts-accent ml-1">
          زمان تقریبی:
        </Text>
        {loading ? (
          <Loader size="sm" />
        ) : (
          <Text type="H5" className="ts-accent">
            {time} دقیقه
          </Text>
        )}
      </div>
      <div className="w-full flex justify-between items-center gap-2">
        <Button
          onClick={nextLevelHandler}
          disabled={loading || hasError}
          bcolor="primary"
          textType="H6"
          classNameContainer="ts-half-width"
          className="w-full rounded-xl h-12"
        >
          باشه، بریم جلو
        </Button>

        <Button
          onClick={previousStepHandler}
          icon={<HiArrowNarrowLeft size={23} className="ts-primary mr-2" />}
          iconPosition="afterText"
          bcolor="primaryTransparent"
          textType="H6"
          classNameContainer="ts-half-width"
          className="w-full rounded-xl h-12"
        >
          قبلی
        </Button>
      </div>
    </SetupCarTemplate>
  );
});

export { BeginJourney as default };

const styles = {};
