/* eslint-disable */
import { configs } from "#configs";
import { ConfigLink, ServiceStorage, tools, useQueryString } from "core";
import React from "react";
import { MdChevronLeft, MdLocationOn } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import defaultCar from "../../../../assets/images/part-request-default.svg";
import { Image, Text } from "../../../components";

interface IMyPartsCardProps {
  data: any;
}

const MyPartsCard: React.FC<IMyPartsCardProps> = React.memo(({ data }) => {
  const navigate = useNavigate();
  console.log(data);

  const requestClickHandler = () => {
    ServiceStorage.setTempReturnURL(`${ConfigLink.shop}${window.location.search}`);
    navigate({ pathname: ConfigLink.PartDetail, search: useQueryString({ id: data.id }) });
  };

  return (
    <div
      onClick={requestClickHandler}
      key={data.id}
      className="w-full flex justify-between items-center p-3 bg-white rounded-2xl ts-shadow mb-2 cursor-pointer relative"
    >
      <div className="w-full flex h-full flex-col justify-between items-start">
        <div className="w-full flex justify-start items-start">
          <div className="w-12 h-12 rounded-xl">
            <Image
              ErrorImage={defaultCar}
              width="100%"
              height="100%"
              src={data.images && data.images.length !== 0 ? `${configs().imageUrl}/${data.images[0]}` : defaultCar}
              alt="default"
              // loading="lazy"
              className={`w-full h-full ${
                data.images && data.images.length !== 0 ? "object-cover aspect-square rounded-xl" : ""
              }`}
            />
          </div>
          <div className="w-4/5 flex flex-col justify-start items-start mr-3">
            <div className="w-full flex justify-start items-center">
              <Text type="H6" className="ts-accent">
                {data.name || "---"}
              </Text>
              <Text type="P5" className="ts-gray-07 mr-1 truncate w-2/5">
                {data.cars && data.cars.length !== 0
                  ? data.cars.map((item) => item.name).join("، ")
                  : data.allCars
                  ? "همه خودروها"
                  : "---"}
              </Text>
            </div>
            <Text type="HM" className="ts-primary my-2">
              <strong className="ts-text-h6">{data.price ? tools.thousandSeparator(data.price) : "---"}</strong>{" "}
              {data.price ? "تومان" : ""}
            </Text>
            {data.workshop && (
              <div className="flex justify-center items-center -mr-1">
                <MdLocationOn size={20} className="ts-gray-06" />
                <Text type="P5" className="ts-gray-06 mr-0.5 pt-px">
                  {data.workshop.name || "بدون نام"}
                </Text>
              </div>
            )}
          </div>
        </div>
      </div>
      <MdChevronLeft size={25} className="ts-accent absolute left-3 z-10" />
    </div>
  );
});

export { MyPartsCard };

const styles = {};
