/* eslint-disable */
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useSnapshot } from "valtio";
import noSegment from "../../../assets/images/no-segment-requested.svg";
import { ApiInstance, useChangeTitle, vState } from "../../../core";
import { Button, ContainerScroll, Text, useNotification } from "../../components";
import { AddPartRequestModal, PartRequestCard } from "./components";

const PartRequest = () => {
  const metaTags = useChangeTitle("ویراژ - درخواست قطعه");
  const snap = useSnapshot(vState);

  const [pageLoading, setPageLoading] = useState(true);
  const [requests, setRequests] = useState([]);
  const [addRequestModal, setAddRequestModal] = useState(false);

  const getRequests = async () => {
    await ApiInstance.partSupply
      .getRequests()
      .then((res) => {
        console.log(res);
        setRequests(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error");
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  useEffect(() => {
    getRequests();
  }, []);

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={50} className="">
        <Text type="H4" className="ts-accent mb-4">
          درخواست قطعه
        </Text>
        <Text type="P4" className="ts-gray-06 mb-6">
          در این قسمت می‌توانید قطعات کمیابی که در بازار به سختی یافت می‌شوند را درخواست دهید.
        </Text>
        {pageLoading ? (
          [1, 2, 3, 4].map((item) => {
            return <Skeleton key={item} containerClassName="w-full" className="w-full ts-part-card rounded-2xl mb-4" />;
          })
        ) : requests.length === 0 ? (
          <div style={{ height: "50vh" }} className="w-full flex flex-col justify-center items-center">
            <img src={noSegment} alt="no-part" className="mb-6" />
            <Text type="P4" className="ts-gray-07">
              درخواست قطعه‌ای تاکنون ثبت نشده است.
            </Text>
          </div>
        ) : (
          requests.map((item: any) => {
            return <PartRequestCard key={item.id} data={item} />;
          })
        )}
      </ContainerScroll>
      <Button
        onClick={() => setAddRequestModal(true)}
        bcolor="primary"
        textType="H6"
        classNameContainer="ts-float-button"
        className="w-full h-12 rounded-xl"
      >
        ثبت درخواست قطعه
      </Button>
      <AddPartRequestModal
        type={"add"}
        isOpen={addRequestModal}
        onDone={getRequests}
        onClose={() => setAddRequestModal(false)}
      />
    </>
  );
};

export { PartRequest as default };
