/* eslint-disable */
import { digitsFaToEn } from "@persian-tools/persian-tools";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { HiArrowNarrowLeft, HiArrowNarrowRight } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { Checkbox, Input } from "rsuite";
import { useSnapshot } from "valtio";
import check from "../../../assets/images/last-step.svg";
import banner from "../../../assets/images/setup-background.svg";
import car from "../../../assets/images/setup-car.svg";
import line from "../../../assets/images/setup-line.png";
import { ApiInstance, ConfigLink, ServiceStorage, tools, useChangeTitle, useObjectState, vState } from "../../../core";
import { Button, PersianDatePicker, SetupCarTemplate, Text, useNotification } from "../../components";
import { FinalStep } from "./components";

interface ISetupStepsProps {}

const env = import.meta.env;

const SetupSteps: React.FC<ISetupStepsProps> = React.memo(({}) => {
  const metaTags = useChangeTitle("ویراژ - ثبت وضعیت خودرو");
  const snap = useSnapshot(vState);
  const navigate = useNavigate();
  const imgElement = React.useRef(null);
  const defaultData = {
    date: "",
    dateFormatted: "",
    kilometer: "",
    recommended: [],
  };

  const [STEPS, setFullSteps] = useState(0);
  const [step, setStep] = useState(0);

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const [data, setData] = useObjectState<any>(defaultData);
  const [service, setService] = useState({
    disabled: null,
    expiresInDays: 0,
    expiresInKilometers: "",
    id: "",
    name: "",
    showInPlateFirstSetup: true,
    recommendations: [],
  });
  const [ignoreStep, setIgnoreStep] = useState(false);
  const [imgWidth, setImgWidth] = useState(0);
  const [carMoveX, setCarMoveX] = useState(0);

  const checkStep = () => {
    const step = ServiceStorage.getStep();
    if (step) setStep(+step);
  };

  const checkServices = () => {
    const steps = ServiceStorage.getSetupServices();
    if (steps) {
      const services = JSON.parse(steps);
      vState.setupServices = services;
      setFullSteps(services.length);
    } else {
      navigate(ConfigLink.createCarRegisterJourney);
    }
  };

  useEffect(() => {
    checkStep();
    checkServices();
  }, []);

  useEffect(() => {
    if (snap.setupServices.length !== 0) {
      const service = snap.setupServices[step];
      setService(service);
    }
  }, [snap.setupServices]);

  useEffect(() => {
    if (/*step + 1 > 0 &&*/ step + 1 <= STEPS) {
      ServiceStorage.setStep(step);
      console.log(step, snap.setupServices[step]);
      const service = snap.setupServices[step];
      setData({ ...defaultData, kilometer: snap.setupServices[step].expiresInKilometers || "" });
      setService(service);
    }
  }, [step]);

  useEffect(() => {
    if (imgWidth !== 0) {
      setCarMoveX(imgWidth / STEPS);
    }
  }, [imgWidth]);

  useEffect(() => {
    setDisabled((data.date.length === 0 || data.kilometer.length === 0) && !ignoreStep);
  }, [data, ignoreStep]);

  const addRelatedReminders = async (id) => {
    data.recommended.map(async (item: any, index) => {
      const reminderObj = {
        expiresInKilometers: +digitsFaToEn(`${+item.ratio * +data.kilometer}`),
        kilometer: +digitsFaToEn(data.kilometer),
        service: item.service,
        updatedAt: data.dateFormatted,
      };

      await ApiInstance.plates
        .addReminder(id || "", reminderObj)
        .then((res) => {
          console.log(`addRelatedReminder-${index}:`, res);
        })
        .catch((err) => {
          console.log("err", err);
        });
    });
    setTimeout(() => {
      setLoading(false);
      setIgnoreStep(false);
      setStep((prev) => prev + 1);
    }, 1500);
  };

  const addReminderHandler = async () => {
    setLoading(true);
    const id = ServiceStorage.getSetupCar();
    const formattedData = {
      expiresInKilometers: +service.expiresInKilometers || 10000,
      kilometer: +data.kilometer,
      service: service.id,
      updatedAt: data.dateFormatted,
    };

    await ApiInstance.plates
      .addReminder(id || "", formattedData)
      .then((res) => {
        console.log(res);
        if (data.recommended.length !== 0) {
          addRelatedReminders(id);
        } else {
          setIgnoreStep(false);
          setStep((prev) => prev + 1);
        }
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا مجددا امتحان کنید.", "error", "افزودن یادآورها");
        setLoading(false);
      })
      .finally(() => {
        if (data.recommended.length === 0) {
          setLoading(false);
        }
      });
  };

  const nextStepHandler = async () => {
    if (ignoreStep) {
      setStep((prev) => prev + 1);
      setIgnoreStep(false);
    } else {
      addReminderHandler();
    }
  };

  const previousStepHandler = () => {
    step === 0 ? navigate(ConfigLink.createCarRegisterJourney) : setStep((prev) => prev - 1);
  };

  const saveDateHandler = (date) => {
    console.log(date);

    setData({ dateFormatted: moment(date.toDate()).toISOString(), date: date?.toString() });
  };

  const relatedSelectHandler = (value, checked) => {
    let finalValue = JSON.parse(value);
    console.log(finalValue, checked);
    let selected: any = [...data.recommended];
    if (checked) {
      selected.push(finalValue);
      console.log(selected);
      setData({ recommended: selected });
    } else {
      selected = selected.filter((item) => item.id !== finalValue.id);
      console.log(selected);
      setData({ recommended: selected });
    }
  };

  return (
    <SetupCarTemplate metaTag={metaTags} className="-mt-14" wrapperClassName="mt-8" noDenyButton={step >= STEPS}>
      <div
        id="banner-container"
        // onLoad={() => {
        //   setImgWidth((document.getElementById("banner-container") as any).offsetWidth);
        // }}
        className="relative mb-8 mx-auto"
      >
        <img
          src={banner}
          alt="banner"
          ref={imgElement}
          id="setup-banner"
          // className="w-full"
          onLoad={() => {
            const img = imgElement.current as any;
            console.log(img.width);
            setImgWidth(img.width - 85);
          }}
        />
        <img
          src={car}
          style={{
            position: "inherit",
            marginRight: `${carMoveX * step}px`,
          }}
          alt="car"
          className="transition-all z-10 duration-500 -mt-9"
        />
        <img
          src={line}
          alt="line"
          // style={{ width: `calc(${(carMoveX / imgWidth) * 100 * step}% + ${43 / (step === 0 ? 1 : step + 0.7)}px)` }}
          // style={{ width: step >= STEPS ? `${carMoveX * STEPS}px` : `${carMoveX * step + 43}px` }}
          style={{ width: `${carMoveX * step + 45}px` }}
          className={`ts-setup-line transition-all duration-500 -mt-9`}
        />
        <img
          src={check}
          style={{ height: "34%" }}
          alt="check"
          className={`transition-all ${step !== STEPS ? "opacity-0" : "opacity-100"} absolute -bottom-1.5 left-0`}
        />
      </div>
      {step < STEPS ? (
        <div className="w-full">
          <Text type="H5" className="ts-gray-08">
            آخرین بار در چه تاریخ و کیلومتری{" "}
            <b className="ts-warning">{service.name ? service.name.replace("تعویض", "") : "---"}</b> خودرو خود را تعویض
            کرده اید؟
          </Text>

          <Checkbox checked={ignoreStep} onChange={(value, checked) => setIgnoreStep(checked)} className="my-8">
            <Text type="P4" className="ts-accent">
              اطلاعات تعویض را فراموش کرده‌ام یا تابحال عوض نکرده‌ام.
            </Text>
          </Checkbox>

          <Text type="H6" className="ts-gray-07 mb-2">
            تاریخ
          </Text>
          <PersianDatePicker
            disabled={ignoreStep}
            value={data.date}
            onChange={saveDateHandler}
            defaultLabel="انتخاب کنید"
          />

          <Text type="H6" className="ts-gray-07 mt-5 mb-2">
            کارکرد تعویض
          </Text>
          <Text type="P4" className="ts-accent mb-2">
            کارکرد خودرو را در زمان آخرین تعویض، وارد نمایید.
          </Text>
          <div className="w-full relative mb-8">
            <Input
              type={tools.isIOS() ? "text" : "number"}
              pattern="[0-9]*"
              value={data.kilometer}
              disabled={ignoreStep}
              required
              onChange={(e) => {
                setData({ kilometer: e });
              }}
              className={`w-full h-12 rounded-xl mb-2 outline-none bg-transparent`}
              placeholder=""
            />
            <Text type="P4" className="ts-gray-06 absolute left-4 top-4">
              کیلومتر
            </Text>
          </div>

          {service.recommendations && service.recommendations.length !== 0 && (
            <div className="w-full flex flex-col justify-start items-start mb-6">
              <Text type="P4" className="ts-accent mb-4">
                در صورت تعویض همزمان موارد زیر با {data.name}، آنها را انتخاب نمایید.
              </Text>
              {service.recommendations.map((item: any) => {
                const ratio = item.kilometerRatio || 1;
                const expire = item.expiresInKilometers || 1;
                return (
                  <Checkbox
                    onChange={(value, checked) => relatedSelectHandler(value, checked)}
                    disabled={ignoreStep}
                    value={JSON.stringify({
                      id: item.id,
                      service: item.recommendService.id,
                      ratio: ratio,
                      expireKm: expire,
                    })}
                    className="mb-1"
                  >
                    <Text type="P4" className="ts-accent">
                      {item.recommendService.name}
                    </Text>
                  </Checkbox>
                );
              })}
            </div>
          )}
        </div>
      ) : (
        <FinalStep />
      )}
      {step < STEPS && (
        <div className="w-full flex justify-between items-center gap-8 mb-8">
          <Button
            onClick={nextStepHandler}
            loading={loading}
            disabled={disabled}
            icon={<HiArrowNarrowRight size={23} className="text-white ml-2" />}
            bcolor="primary"
            textType="H6"
            classNameContainer="ts-half-width"
            className="w-full rounded-xl h-12"
          >
            بعدی
          </Button>

          <Button
            onClick={previousStepHandler}
            icon={<HiArrowNarrowLeft size={23} className="ts-primary mr-2" />}
            iconPosition="afterText"
            bcolor="primaryTransparent"
            textType="H6"
            classNameContainer="ts-half-width"
            className="w-full rounded-xl h-12"
          >
            قبلی
          </Button>
        </div>
      )}
    </SetupCarTemplate>
  );
});

export { SetupSteps as default };
