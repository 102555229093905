/* eslint-disable */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input } from "rsuite";
import { useSnapshot } from "valtio";
import { ApiInstance, ServiceStorage, useObjectState, vState } from "../../../core";
import { useChangeTitle } from "../../../core/hooks";
import { useAuth } from "../../../hooks";
import { Button, ContainerScroll, Text, useNotification } from "../../components";
import { ChangePassModal } from "./components";

const env = import.meta.env;

const Account = ({}) => {
  const auth = useAuth();
  const metaTags = useChangeTitle("ویراژ - حساب کاربری");
  const snap = useSnapshot(vState);
  const navigate = useNavigate();

  const [changePassShow, setChangePassShow] = useState(false);

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [info, setInfo] = useObjectState({
    name: "",
    phone: "",
  });

  const getProfile = async () => {
    const result: any = await ApiInstance.account.getUserProfile();
    console.log(result);
    vState.userInfo = result;
    setInfo({
      name: result.name,
      phone: result.mobile || result.username,
    });
  };

  useEffect(() => {
    setDisabled(info.name.length === 0 || info.name === snap.userInfo.name);
  }, [info]);

  useEffect(() => {
    const profile: any = ServiceStorage.getAuth();

    if (profile) {
      console.log(profile);
      vState.userInfo = profile;
      setInfo({
        name: profile.name ? profile.name : "",
        phone: profile.mobile || profile.username,
      });
    } else {
      getProfile();
    }
  }, []);

  const saveInfo = () => {
    const copyUser = { ...snap.userInfo, name: info.name, username: info.phone };
    console.log("copyUser", copyUser);
    ServiceStorage.setAuth(copyUser);
  };

  const changeNameHandler = async () => {
    if (snap.userInfo.name !== info.name) {
      setLoading(true);

      await ApiInstance.account
        .updateUserProfile(info.name)
        .then((res) => {
          vState.userInfo.name = info.name;
          saveInfo();
          useNotification("نام با موفقیت تغییر کرد.", "success", "تغییر نام");
          navigate(-1);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={50} className="">
        <Text type="H3" className="ts-accent mb-8">
          حساب کاربری
        </Text>

        <Form fluid className="w-full">
          <Text type="H6" className="ts-accent mb-2">
            نام و نام خانوادگی
          </Text>
          <div className="w-full relative mb-6">
            <Input
              value={info.name}
              required
              onChange={(e) => setInfo({ name: e })}
              className={`w-full rounded-xl outline-none h-12`}
              placeholder="نام و نام خانوادگی"
            />
          </div>

          <Text type="H6" className="ts-accent mb-2">
            شماره تماس
          </Text>

          <div className="w-full relative mb-3">
            <Input
              disabled
              value={info.phone}
              required
              onChange={(e) => setInfo({ phone: e })}
              className={`w-full rounded-xl outline-none h-12 bg-white ts-gray-07`}
              placeholder="09000000000"
            />
          </div>
          <Text type="P5" className="ts-gray-07 ts-letter-space-min mb-6">
            {" "}
            در حال حاضر امکان تغییر شماره تماس وجود ندارد. در صورت نیاز با پشتیبانی تماس بگیرید.
          </Text>

          <Button
            onClick={changeNameHandler}
            loading={loading}
            disabled={disabled}
            bcolor="primary"
            classNameContainer="w-full"
            className="w-full h-12"
          >
            اعمال تغییرات
          </Button>

          {/* <Text type="H6" className="ts-accent mb-2">
            کلمه عبور
          </Text>
          <Button
            onClick={() => setChangePassShow(true)}
            bcolor="primaryTransparent"
            textType="H5"
            classNameContainer="w-full"
            className="w-full rounded-lg h-10"
          >
            تغییر رمز عبور
          </Button> */}
        </Form>
      </ContainerScroll>
      <ChangePassModal isOpen={changePassShow} onClose={() => setChangePassShow(false)} />
    </>
  );
};

export { Account as default };
