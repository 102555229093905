/* eslint-disable */
import React from "react";
import { BiChevronLeft, BiDotsVerticalRounded } from "react-icons/bi";
import { HiClipboardList, HiPencilAlt, HiTrash } from "react-icons/hi";
import { useNavigate } from "react-router";
import { Dropdown } from "rsuite";
import defaultCar from "../../../../assets/images/garage-card-image.png";
import { ConfigLink, ServiceStorage, tools, useQueryString } from "../../../../core";
import { Text } from "../../../components";

interface ICarsCardProps {
  data: any;
  forSetup?: boolean;
  onDelete: () => void;
  onEdit?: () => void;
  onStart?: () => void;
  lowMargin?: boolean;
}

const CarsCard: React.FC<ICarsCardProps> = React.memo(
  ({ lowMargin, data, forSetup = false, onDelete, onEdit, onStart }) => {
    const navigate = useNavigate();

    const itemClickHandler = () => {
      if (forSetup) {
        ServiceStorage.setSetupCar(data.id);
        navigate({
          pathname: ConfigLink.createCar,
          search: useQueryString({ type: "edit" }),
        });
      } else {
        ServiceStorage.setTempReturnURL(ConfigLink.garage);
        navigate({
          pathname: ConfigLink.carStatus,
          search: useQueryString({ id: data.id }),
        });
      }
    };

    const menuButton = (props, ref) => {
      return <BiDotsVerticalRounded {...props} ref={ref} size={23} className="ts-accent -mt-0.5" />;
    };

    const historyClickHandler = () => {
      ServiceStorage.setSetupReturnURL(`${window.location.pathname}${window.location.search}`);
      navigate({ pathname: ConfigLink.history, search: useQueryString({ id: data.id }) });
    };

    return (
      <div
        style={{ height: "73px" }}
        className={`w-full flex justify-between items-start ts-shadow mb-2 bg-white rounded-xl p-3`}
      >
        <div className="flex h-full justify-start items-start">
          {/* <div className="py-3 px-5  max-2xs:px-2 rounded-xl ts-bg-gray-01 ml-4"> */}
          <img src={defaultCar} alt="default" className="object-fit h-12 w-20 ml-4" />
          {/* </div> */}
          <div onClick={itemClickHandler} className="h-full flex flex-col justify-between items-start cursor-pointer">
            <div className="flex justify-center items-center">
              <Text type="H5" className="ts-accent ml-1">
                {data.car ? data.car.name : data.carName ? data.carName : "---"}
              </Text>
              <BiChevronLeft size={23} className="ts-accent -mt-px" />
            </div>
            <Text type="P5" className="ts-gray-07">
              {tools.getFullPlate(data.plateNumber) || "---"}
            </Text>
          </div>
        </div>
        <Dropdown renderToggle={menuButton}>
          {!forSetup && (
            <>
              <Dropdown.Item
                className="flex justify-start items-center ts-cars-menu-width px-6 py-3"
                onSelect={onEdit}
                icon={<HiPencilAlt size={21} className="ts-gray-08 -rotate-90" />}
              >
                <Text type="P4" className="ts-gray-08 mr-1.5">
                  ویرایش اطلاعات
                </Text>
              </Dropdown.Item>

              {/* {data.owner === null && (
                <Dropdown.Item
                  className="flex justify-start items-center ts-cars-menu-width px-6 py-3"
                  onSelect={onStart}
                  icon={<IoIosRocket size={20} className="ts-gray-08" />}
                >
                  <Text type="P4" className="ts-gray-08 mr-1.5">
                    راه اندازی اولیه
                  </Text>
                </Dropdown.Item>
              )} */}

              <Dropdown.Item
                className="flex justify-start items-center ts-cars-menu-width px-6 py-3"
                onSelect={historyClickHandler}
                icon={<HiClipboardList size={21} className="ts-gray-08" />}
              >
                <Text type="P4" className="ts-gray-08 mr-1.5">
                  تاریخچه یادآورها
                </Text>
              </Dropdown.Item>
            </>
          )}

          <Dropdown.Item
            className="flex justify-start items-center w-40 px-6 py-3"
            onSelect={onDelete}
            icon={<HiTrash size={21} color="#CA2525" className="ts-secondary-red" />}
          >
            <Text type="P4" className="ts-secondary-red mr-1.5">
              حذف خودرو
            </Text>
          </Dropdown.Item>
        </Dropdown>
      </div>
    );
  },
);

export { CarsCard };

const styles = {};
