/* eslint-disable */
import { ApiInstance } from "core";
import React, { useEffect, useState } from "react";
import { Text } from "ui/components";
import noHistory from "../../../../assets/images/history-image.svg";
import { HistoryCard } from "./HistoryCard";
import { HistoryCardLoader } from "./HistoryCardLoader";

interface ICarHistoryProps {
  id: string;
}

const CarHistory: React.FC<ICarHistoryProps> = React.memo(({ id }) => {
  const [carHistory, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);

  const getHistory = async () => {
    await ApiInstance.car
      .getCarHistory(id)
      .then((res) => {
        console.log(res);
        setHistory(res.data.data);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getHistory();
  }, []);

  return (
    <div className="w-full flex flex-col justify-start items-center">
      {loading ? (
        <HistoryCardLoader />
      ) : carHistory.length === 0 ? (
        <>
          <img src={noHistory} className="mx-auto mt-10" />
          <Text type="P4" className="ts-gray-07 mt-4 mx-auto text-center">
            تاکنون سرویسی برای خودرو شما ثبت نشده است.
          </Text>
        </>
      ) : (
        carHistory.map((item: any) => {
          return <HistoryCard plateId={item.plate.id || ""} key={item.id} data={item} />;
        })
      )}
    </div>
  );
});

export { CarHistory };
