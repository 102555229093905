/* eslint-disable */
import { vState } from "core";
import React from "react";
import { BiChevronLeft, BiTransfer } from "react-icons/bi";
import { HiPencilAlt, HiTrash } from "react-icons/hi";
import { Text } from "ui/components";
import { useSnapshot } from "valtio";

interface ICarSettingProps {
  id: string;
  onDelete: () => void;
  onEdit: () => void;
  onStart: () => void;
}

const CarSetting: React.FC<ICarSettingProps> = React.memo(({ id, onDelete, onEdit, onStart }) => {
  const snap = useSnapshot(vState);

  const options = [
    {
      id: 0,
      title: "مشاهده و ویرایش اطلاعات خودرو",
      icon: <HiPencilAlt size={21} className="ts-accent -rotate-90" />,
      onClick: () => {
        onEdit();
      },
      redButton: false,
      enabled: true,
    },
    // {
    //   id: 1,
    //   title: "راه اندازی اولیه",
    //   icon: <IoIosRocket size={20} className="ts-accent" />,
    //   onClick: () => {
    //     onStart();
    //   },
    //   redButton: false,
    //   enabled: snap.carStatus.owner ? false : true,
    // },
    {
      id: 2,
      title: "انتقال مالکیت",
      icon: <BiTransfer />,
      onClick: () => {},
      redButton: false,
      enabled: false,
    },
    {
      id: 3,
      title: "حذف خودرو",
      icon: <HiTrash size={21} color="#CA2525" className="ts-secondary-red" />,
      onClick: () => {
        onDelete();
      },
      redButton: true,
      enabled: true,
    },
  ];

  return (
    <div className="w-full flex flex-col justify-start items-center pt-1">
      {options.map((item) => {
        if (item.enabled) {
          return (
            <div
              key={item.id}
              onClick={item.onClick}
              className="w-full flex justify-between items-center cursor-pointer rounded-xl ts-shadow mb-2 p-4"
            >
              <span className="flex justify-center items-center">
                {item.icon}
                <Text type="P3" className={`${item.redButton ? "ts-secondary-red" : "ts-accent"} mr-2`}>
                  {item.title}
                </Text>
              </span>
              <BiChevronLeft size={26} className="ts-accent" />
            </div>
          );
        }
      })}
    </div>
  );
});

export { CarSetting };
