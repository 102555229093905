import { BsApple } from "react-icons/bs";
import { RxDownload } from "react-icons/rx";
import { Button, Text } from "ui/components";
import { StoreButtons } from "./StoreButtons";

interface IDownloadApp {
  type?: IType;
}

type IType = "provider" | "owner";

const DownloadApp: React.FC<IDownloadApp> = ({ type = "owner" }) => {
  const iosHandler = () => {
    window.open(`https://${type}.${window.location.hostname}/intro`, "_self");
  };

  return (
    <>
      <div className="w-full flex flex-col justify-center items-center mb-5">
        <Text type="P4" className="ts-gray-06 mb-2 max-md:mb-2 text-center">
          دانلود نسخه اندروید
        </Text>

        <a href={`/apk/virazh-owner.apk`} download className={`w-48`}>
          <Button
            icon={<RxDownload size={22} className="text-white ml-2 -mt-1" />}
            classNameContainer={"w-48 ts-main-button-height"}
            className="w-full ts-download-button-wrapper rounded-md mb-2 text-white"
          >
            دانلود مستقیم
          </Button>
        </a>

        <StoreButtons type={type} platform="bazar" />

        <StoreButtons type={type} platform="myket" />
      </div>

      <div className="w-full flex flex-col justify-center items-center">
        <Text type={"P4"} className="ts-gray-06 mb-2 text-center">
          دانلود نسخه ios
        </Text>
        <Button
          icon={<BsApple size={22} className="text-white ml-2 -mt-1" />}
          classNameContainer={"w-48 ts-main-button-height"}
          className="w-full ts-download-button-wrapper rounded-md text-white"
          onClick={iosHandler}
        >
          وب اپلیکیشن
        </Button>
      </div>
    </>
  );
};

export { DownloadApp };
