/* eslint-disable */
import React from "react";
import metadata from "../../../../metadata.json";
import { Text } from "../../../components";

interface IVersionProps {}

const Version: React.FC<IVersionProps> = React.memo(() => {
  return (
    <div className="w-full flex flex-col justify-center items-center mt-20">
      <div className="flex justify-center items-center mb-2">
        <Text type="P3" className="ts-gray-06 ml-2">{`ویراژ ©`}</Text>
        <Text type="P3" className="ts-gray-06">
          {/* @ts-ignore */}
          {`نسخه ${+metadata.buildMajor}.${+metadata.buildMinor}.${+metadata.buildRevision}`}
        </Text>
      </div>
      <Text type="P5" className="ts-gray-04">
        تمامی حقوق برای ویرا محفوظ است.
      </Text>
    </div>
  );
});

export { Version };

const styles = {};
