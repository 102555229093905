/* eslint-disable */
import { digitsFaToEn } from "@persian-tools/persian-tools";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "rsuite";
import { ConfirmKilometerUpdate, ReminderUpdateAlert } from "ui/views/Car/components";
import { useSnapshot } from "valtio";
import { ApiInstance, vState } from "../../../../core";
import { BottomModal, Button, Text, useNotification } from "../../../components";

interface IKilometerUpdateModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDone?: () => void;
  id?: string;
  km?: number;
}

const KilometerUpdateModal: React.FC<IKilometerUpdateModalProps> = React.memo(
  ({ isOpen, onClose, onDone, id = "", km = 0 }) => {
    //@ts-ignore
    const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    const navigate = useNavigate();
    const snap = useSnapshot(vState);

    const [kilometer, setKilometer] = useState("");
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [reminderUpdate, setReminderUpdate] = useState(false);

    useEffect(() => {
      if (isOpen) {
        setKilometer("");
      }
    }, [isOpen]);

    const updateKilometer = async () => {
      await ApiInstance.car
        .updateCarKilometer(id || snap.sessionId, +digitsFaToEn(kilometer))
        .then((res) => {
          console.log(res);
          useNotification("کارکرد خودرو اپدیت شد.", "success");
          if (id) {
            const index = snap.ownerCars.findIndex((item) => item.id === id);
            console.log(index, snap.ownerCars);

            vState.ownerCars[index].kilometer = +digitsFaToEn(kilometer);
            vState.carStatus.kilometer = +digitsFaToEn(kilometer);
            vState.carStatus.kilomeeterCreatedAt = new Date().toISOString();
            vState.carStatus.updatedAt = new Date().toISOString();
          }

          if (km && +km > +kilometer) {
            setReminderUpdate(true);
          }
          onDone && onDone();
          closeConfirmModal();
          onClose();
        })
        .catch((err) => {
          console.log("err", err);
          useNotification("مشکلی رخ داده است. لطفا اتصال خود به اینترنت را بررسی و مجددا تلاش نمایید.", "error");
        })
        .then(() => {
          setLoading(false);
        });
    };

    const viewCarHandler = async () => {
      if (km && +km !== 0 && (+km > +kilometer || +kilometer - +km >= 10000)) {
        setConfirmModalOpen(true);
      } else {
        setLoading(true);
        updateKilometer();
      }
    };

    const closeConfirmModal = () => {
      setConfirmModalOpen(false);
    };

    return (
      <>
        <BottomModal isOpen={isOpen} onClose={onClose}>
          <div className="w-full flex flex-col justify-start items-start">
            <Text type="H4" className="ts-accent">
              ثبت یادآور جدید
            </Text>
            <Text type="P3" className="ts-gray-08 my-6">
              {snap.carStatus && snap.carStatus.kilomeeterCreatedAt
                ? `آخرین به روز رسانی کارکرد شما در تاریخ ${new Date(
                    snap.carStatus.kilomeeterCreatedAt,
                  ).toLocaleDateString(
                    "fa-IR",
                  )} انجام شده است. برای ثبت یادآور جدید ابتدا کارکرد خودرو را به روز رسانی کنید.`
                : `تاریخ آخرین به روز رسانی کارکرد برای شما ثبت نشده است. برای ثبت یادآور جدید ابتدا کارکرد خودرو را به روز رسانی کنید.`}
            </Text>

            <Text type="H6" className="ts-accent mb-2">
              کیلومتر فعلی
            </Text>
            <Input
              type={isIOS() ? "text" : "number"}
              pattern="[0-9]*"
              value={kilometer}
              required
              onChange={(e) => {
                setKilometer(e);
              }}
              className={`w-full rounded-xl mb-6 outline-none h-12 placeholder:text-gray-300`}
              placeholder={km ? `کیلومتر قبلی: ${km}` : "14,500"}
            />
            <Button
              disabled={+kilometer === 0}
              loading={loading}
              onClick={viewCarHandler}
              bcolor="primary"
              classNameContainer="w-full"
              className="w-full h-12"
            >
              ادامه
            </Button>
          </div>
        </BottomModal>

        <ConfirmKilometerUpdate
          isOpen={confirmModalOpen}
          lastKilometer={km}
          nextKilometer={kilometer}
          onClose={closeConfirmModal}
          onDone={updateKilometer}
        />

        <ReminderUpdateAlert isOpen={reminderUpdate} onClose={() => setReminderUpdate(false)} />
      </>
    );
  },
);

export { KilometerUpdateModal };

const styles = {};
