/* eslint-disable */
import { axios } from "./api";

const sessions = {
  getRecommendationService: async (id: string) => {
    const url = `/services/${id}/recommendations`;

    const result = await axios.get(url);

    return result;
  },
};
export { sessions };
