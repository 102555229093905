/* eslint-disable */
import { useAuth } from "hooks";
import React, { useEffect, useState } from "react";
import { IoCarSport } from "react-icons/io5";
import { RiErrorWarningFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { Divider } from "rsuite";
import { useSnapshot } from "valtio";
import prototype from "../../../assets/images/car-prototype.svg";
import kmIcon from "../../../assets/images/kilomemter-icon.svg";
import noReminder from "../../../assets/images/no-reminder.svg";
import {
  ApiInstance,
  ConfigApp,
  ConfigLink,
  tools,
  useChangeTitle,
  useFetchQueryString,
  useQueryString,
  vState,
} from "../../../core";
import { Button, ContainerScroll, Image, ReminderCard, Text } from "../../components";
import { DownloadApp, FeaturesList, LoginPrompt, ReminderLoader } from "./components";

interface IOwnerRemindersProps {}

interface IQueryStringTransfer {
  id: string;
}
const env = import.meta.env;

const OwnerReminders: React.FC<IOwnerRemindersProps> = React.memo(({}) => {
  const metaTags = useChangeTitle("ویراژ - وضعیت خودرو");
  const auth = useAuth();
  const navigate = useNavigate();
  const snap = useSnapshot(vState);
  let { id } = useFetchQueryString<IQueryStringTransfer>();
  const isRunningStandalone = ConfigApp.isRunningStandalone();

  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(true);
  const [loginPrompt, setLoginPrompt] = useState(false);
  const [loginTitle, setLoginTitle] = useState("ثبت دیدگاه");
  const [ownerStatus, setOwnerStatus] = useState({
    createdAt: "",
    id: "",
    kilometer: 0,
    plate: {
      id: "",
      mobile: "",
      plateNumber: "",
      carName: "",
      car: {
        id: "",
        name: "",
      },
    },
    status: "in_progress",
    updatedAt: "",
    workshop: {
      id: "",
      name: "",
    },
  });
  const [reminders, setReminders] = useState([]);

  const getCarStatus = async () => {
    await ApiInstance.plates
      .getOwnerReport(id)
      .then((res) => {
        console.log(res);
        setOwnerStatus(res.data.lastSession);
      })
      .catch((err) => console.log("err", err))
      .then(() => getReminders());
  };

  const getReminders = async () => {
    await ApiInstance.plates
      .getOwnerReminders(id, "kilometer")
      .then((res) => {
        console.log(res);
        setReminders(res.data.data);
      })
      .catch((err) => console.log("err", err))
      .then(() => setLoading(false));
  };

  useEffect(() => {
    getCarStatus();
  }, []);

  useEffect(() => {
    if (auth.ready) {
      setLoggedIn(auth.authenticated);
    }
  }, [auth]);

  const ownerLoginHandler = () => {
    if (window.location.hostname.includes("developersonline")) {
      window.location.href = "https://owner.developersonline.ir/intro";
    } else {
      window.location.href = "https://owner.virazh.ir/intro";
    }
  };

  const loginClickHandler = () => {
    if (loginTitle === "ثبت دیدگاه") {
      const url = new URL(
        `${ConfigLink.historyDetail}?id=${ownerStatus.id}&car=${ownerStatus.plate.id}&feedback=1`,
        window.location.origin,
      );
      auth.login({
        redirectUri: url.toString(),
      });
    } else {
      const url = new URL(
        `${window.location.origin}${ConfigLink.workshopInfo}?id=${ownerStatus.workshop.id}`,
        window.location.origin,
      );
      auth.login({
        redirectUri: url.toString(),
      });
    }
  };

  const registerFeedbackHandler = () => {
    if (auth.ready && auth.authenticated) {
      navigate({
        pathname: ConfigLink.historyDetail,
        search: useQueryString({ id: ownerStatus.id, car: ownerStatus.plate.id, feedback: "1" }),
      });
    } else {
      setLoginTitle("ثبت دیدگاه");
      setLoginPrompt(true);
    }
  };

  const providerClickHandler = () => {
    if (auth.ready && auth.authenticated) {
      navigate({
        pathname: ConfigLink.workshopInfo,
        search: useQueryString({ id: ownerStatus.workshop.id }),
      });
    } else {
      setLoginTitle("مشاهده اطلاعات کسب و کار");
      setLoginPrompt(true);
    }
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={200} className={`relative`}>
        <Text type="H3" className="ts-accent mb-2">
          وضعیت خودرو
        </Text>

        {ownerStatus.createdAt && (
          <Text type="P3" className="ts-gray-08 mb-6">
            اطلاعات زیر توسط{" "}
            {
              <b onClick={() => providerClickHandler()} className="cursor-pointer ts-text-h6 ts-primary">
                {ownerStatus.workshop?.name || "---"}
              </b>
            }{" "}
            در تاریخ {new Date(ownerStatus.createdAt || "").toLocaleDateString("fa-IR")} برای خودروی شما ثبت شده است.
          </Text>
        )}

        <div className="w-full ts-shadow flex flex-col justify-start items-start rounded-xl p-4 mb-6 ts-cars-card-bg">
          <div className="w-full">
            <span className="w-full flex justify-between items-center mb-4">
              <Text type="H5" className="ts-gray-08">
                {ownerStatus.plate.car
                  ? ownerStatus.plate.car?.name
                  : ownerStatus.plate.carName
                  ? ownerStatus.plate.carName
                  : "---"}
              </Text>
            </span>

            <span className="w-full flex justify-start items-start mb-4">
              <span className="flex flex-col justify-start items-start">
                <Text type="P4" className="ts-gray-06 mb-1">
                  کارکرد (کیلومتر)
                </Text>
                <span className="flex justify-center items-center">
                  <span className="w-6 h-6 flex justify-center items-center ts-bg-primary-light rounded-full ml-1">
                    <img src={kmIcon} alt="icn" className="" />
                  </span>
                  <Text type="H5" className="ts-gray-07">
                    {ownerStatus.kilometer ? tools.thousandSeparator(ownerStatus.kilometer) : "---"}
                  </Text>
                </span>
              </span>

              <Divider vertical className="h-12" />

              <span className="flex flex-col justify-start items-start">
                <Text type="P4" className="ts-gray-06 mb-1">
                  پلاک خودرو
                </Text>
                <span className="flex justify-center items-center">
                  <span className="w-6 h-6 flex justify-center items-center ts-bg-primary-light rounded-full ml-1">
                    <IoCarSport size={18} className="ts-primary" />
                  </span>
                  <Text type="H5" className="ts-gray-07">
                    {tools.getFullPlate(ownerStatus.plate.plateNumber)}
                  </Text>
                </span>
              </span>
            </span>

            <img src={prototype} alt="car" className="m-auto mb-4" />
          </div>
        </div>

        <div className="w-full flex justify-between items-center mb-2">
          <Text type="H5" className="ts-accent">
            یادآورهای ثبت شده
          </Text>
        </div>

        {loading ? (
          <ReminderLoader />
        ) : reminders.length === 0 ? (
          <div className="w-full flex flex-col justify-center items-center">
            <Image src={noReminder} width="" height="" alt="no-reminder" className="mt-2" />
            <Text type="P4" className="ts-gray-07 mt-2 mb-6">
              هیچ یادآوری برای آینده ثبت نشده است
            </Text>
          </div>
        ) : (
          reminders.map((item: any) => {
            return <ReminderCard data={item} forOwner currentKilometer={ownerStatus.kilometer} />;
          })
        )}

        <div className="w-full -mt-1 mb-6 relative ts-report-box-bg rounded-2xl px-4 py-2.5 flex justify-start items-start">
          <div className="w-5 h-5 absolute top-2.5 right-4">
            <RiErrorWarningFill size={20} className="ts-warning" />
          </div>
          <Text type="P4" className="ts-gray-07 indent-6">
            ویراژ هیچگونه مسئولیتی در قبال کیفیت خدمات ارائه شده توسط کسب و کارها ندارد. هدف ما صرفا معرفی خدمت دهنده ها
            می باشد.
          </Text>
        </div>

        <Text type="H4" className="ts-accent mb-3 text-right">
          امکانات ویراژ
        </Text>
        <FeaturesList />

        {!isRunningStandalone && (
          <>
            <Text type="H4" className="ts-accent mt-6 mb-4 text-right">
              دانلود اپلیکیشن ویراژ
            </Text>
            <DownloadApp type="owner" />
          </>
        )}
      </ContainerScroll>

      <div className={`w-full flex flex-col justify-center items-center py-6 px-3 ts-temp-box text-center`}>
        <Text type="P4" className="ts-gray-08 mb-4 temp-box-text">
          چنانچه روی سرویس انجام شده نظر، انتقاد یا پیشنهادی دارید می توانید آن را ثبت کنید.
        </Text>
        <Button
          onClick={registerFeedbackHandler}
          bcolor="primary"
          classNameContainer="w-full"
          className="w-full h-12 rounded-xl"
        >
          ثبت دیدگاه
        </Button>
      </div>

      <LoginPrompt
        isOpen={loginPrompt}
        title={loginTitle}
        onClose={() => setLoginPrompt(false)}
        onClick={loginClickHandler}
      />
    </>
  );
});

export { OwnerReminders as default };
