/* eslint-disable */
import React, { useEffect, useState } from "react";
import { AiFillExclamationCircle } from "react-icons/ai";
import { MdChevronLeft } from "react-icons/md";
import { useSnapshot } from "valtio";
import { ApiInstance, ConfigApp, ServiceStorage, tools, useChangeTitle, vState } from "../../../core";
import { ContainerScroll, Text, UpdateLogModal, useNotification, VpnAlert } from "../../components";
import { AddOrEditCar, RegisterNewService } from "../Car/components";
import {
  AddCarCard,
  Advertisement,
  CarsList,
  CompleteInfoAlert,
  HomePageLoader,
  KilometerUpdateModal,
  MostUsedServices,
  PwaModal,
  ReminderDetailModal,
  UpdateCarBrand,
} from "./components";

interface IHomePageProps {}

const HomePage: React.FC<IHomePageProps> = React.memo(({}) => {
  const metaTags = useChangeTitle("ویراژ - داشبورد");
  const snap = useSnapshot(vState);

  const [loading, setLoading] = useState(true);
  const [selectedCar, setSelectedCars] = useState<string>("");
  const [selectedCarIndex, setSelectedCarIndex] = useState<number>(0);
  const [userHasCar, setUserHasCar] = useState(false);
  const [updateKilometerModal, setUpdateKilometerModal] = useState(false);
  const [AddOrEditCarModal, setAddOrEditCarModal] = useState(false);
  const [addServiceModal, setAddServiceModal] = useState(false);
  const [updateCarInfoModal, setUpdateCarInfoModal] = useState(false);
  const [reminderDetailModal, setReminderDetailModal] = useState(false);
  const [selectedService, setSelectedService] = useState("");
  const [reachAddCarSlide, setReachAddCarSlide] = useState(false);
  const [userCity, setUserCity] = useState("");
  const [cityLoading, setCityLoading] = useState(true);
  const [versionModal, setVersionModal] = useState(false);

  const [vpnEnabled, setVpnEnabled] = useState(false);
  const [pwaModal, setPwaModal] = useState(false);

  const setSelectedCarHandler = (data) => {
    setSelectedCars(data);
    ServiceStorage.setSelectedCar(data);
  };

  const setInfo = (data) => {
    console.log("data", data);
    setSelectedCarHandler(data.id);
    vState.carStatus = data;
    setSelectedCarHandler(data.id);
    getReminders(data.id);
  };

  const getUserCars = async () => {
    await ApiInstance.plates
      .getUserCars()
      .then((result) => {
        console.log(result);
        const data = result.data.data;
        if (data.length === 0) {
          setUserHasCar(false);
        } else {
          vState.ownerCars = data;
          setUserHasCar(true);

          const selectedCar = ServiceStorage.getSelectedCar();
          if (selectedCar) {
            const selectedCarIdx = data.findIndex((item) => item.id === selectedCar);
            console.log(selectedCarIdx);
            if (selectedCarIdx === -1) {
              setInfo(data[0]);
            } else {
              getReminders(selectedCar);
              setSelectedCars(selectedCar);
              vState.carStatus = data[selectedCarIdx];
              setSelectedCarIndex(selectedCarIdx);
            }
          } else {
            setInfo(data[0]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا اتصال خود به اینترنت را بررسی کنید و مجددا تلاش کنید.", "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getReminders = async (id) => {
    await ApiInstance.plates
      .getPlateReminders(id)
      .then((res) => {
        console.log("getReminders", res);
        vState.reminders = res.data.data;
      })
      .catch((err) => console.log("err", err))
      .finally(() => {
        setLoading(false);
      });
  };

  const getUserLocation = async (ip) => {
    await ApiInstance.map
      .getUserLocation(ip)
      .then((res) => {
        console.log("location", res);
        if (res.status === 200 && res.data.countryCode !== "IR") {
          setVpnEnabled(true);
        }
      })
      .catch((err) => console.log("err location", err));
    // .finally(() => {
    // });
  };

  const getUserIp = async () => {
    await ApiInstance.map
      .getUserIp()
      .then((res) => {
        console.log("ip", res);
        getUserLocation(res.data.ip || "");
      })
      .catch((err) => console.log("err IP", err));
  };

  const getUserCity = async () => {
    await ApiInstance.account
      .getSetting()
      .then((res) => {
        console.log("userCity", res);
        setUserCity(res.data.cityId || "");
      })
      .catch((err) => {
        console.log("userCity", err);
      })
      .finally(() => {
        setCityLoading(false);
      });
  };

  useEffect(() => {
    getUserIp();
    getUserCars();
    getUserCity();

    const pwaVisited = !!ServiceStorage.getPWAVisitStatus();
    const isIOS = tools.isIOS();
    const isRunningStandalone = ConfigApp.isRunningStandalone();

    if (snap.isMobileDevice && isIOS && !pwaVisited && !isRunningStandalone) {
      setPwaModal(!isRunningStandalone);
    }
  }, []);

  useEffect(() => {
    const versionVisited = ServiceStorage.getVersionVisited();
    if (versionVisited) {
      const savedVersion = +snap.version.number.toString().replaceAll(".", "");
      if (savedVersion > +versionVisited) {
        setVersionModal(true);
      } else {
        setVersionModal(false);
      }
    } else {
      if (snap.version.number !== 0) {
        setVersionModal(true);
      }
    }
  }, [snap.version]);

  const dateComparison = (): boolean => {
    const kilometerUpdate = snap.carStatus.kilomeeterCreatedAt
      ? new Date(snap.carStatus.kilomeeterCreatedAt).toLocaleDateString("fa-IR")
      : null;
    const today = new Date().toLocaleDateString("fa-IR");

    return today === kilometerUpdate;
  };

  const registerServiceHandler = () => {
    const isNew = dateComparison();

    if (isNew) {
      setAddServiceModal(true);
    } else {
      setUpdateKilometerModal(true);
    }
  };

  const carChangeHandler = (value) => {
    const id = value.toString();
    setSelectedCarHandler(id);
    const index = snap.ownerCars.findIndex((item) => item.id === id);
    vState.carStatus = snap.ownerCars[index];
    getReminders(value);
    if (reachAddCarSlide) {
      setReachAddCarSlide(false);
    }
  };

  const checkKilometer = () => {
    if (snap.carStatus) {
      const isKilometerUpdated = dateComparison();
      if (snap.carStatus.kilometer && isKilometerUpdated) {
        setAddServiceModal(true);
      } else {
        setUpdateKilometerModal(true);
      }
    }
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={20} className={`relative overflow-x-hidden -mt-1 ts-home-page-wrapper`}>
        {loading ? (
          <HomePageLoader />
        ) : (
          <>
            {(userCity.length === 0 ||
              !("name" in snap.userInfo) ||
              ("name" in snap.userInfo && !Boolean(snap.userInfo.name))) && <CompleteInfoAlert />}
            {userHasCar ? (
              <>
                {snap.carStatus && !snap.carStatus.car && !reachAddCarSlide && (
                  <div
                    onClick={() => setUpdateCarInfoModal(true)}
                    style={{ background: "rgba(255, 162, 58, 0.2)" }}
                    className={`w-full z-20 transition-all px-3 py-2.5 mb-3 cursor-pointer rounded-2xl flex justify-between items-center`}
                  >
                    <div className="flex justify-center items-center">
                      <AiFillExclamationCircle size={21} className="ts-warning ml-1.5" />
                      <Text type="P5" className="ts-gray-08 pt-0.5">
                        برند و تیپ خودرو خود را مشخص نمایید
                      </Text>
                    </div>

                    <MdChevronLeft size={25} className="ts-accent" />
                  </div>
                )}

                <CarsList
                  carChanged={(value) => carChangeHandler(value)}
                  addReminderClick={checkKilometer}
                  addCarClick={() => setAddOrEditCarModal(true)}
                  reminderClick={(id) => {
                    setSelectedService(id);
                    setReminderDetailModal(true);
                  }}
                  addCarSlideReached={() => setReachAddCarSlide(true)}
                  initialIndex={selectedCarIndex}
                />
              </>
            ) : (
              <AddCarCard type="add" onClick={() => setAddOrEditCarModal(true)} />
            )}
          </>
        )}
        {snap.activeFeatures.length !== 0 && <MostUsedServices />}
        {snap.activeFeatures.includes("advertising") && <Advertisement />}
      </ContainerScroll>

      <AddOrEditCar isOpen={AddOrEditCarModal} onCarAdded={getUserCars} onClose={() => setAddOrEditCarModal(false)} />
      <RegisterNewService
        carId={selectedCar}
        isOpen={addServiceModal}
        currentKM={snap.carStatus && snap.carStatus.kilometer ? snap.carStatus.kilometer : 0}
        onClose={() => setAddServiceModal(false)}
        type="register"
        onDone={() => getReminders(selectedCar)}
      />
      <UpdateCarBrand
        isOpen={updateCarInfoModal}
        data={snap.carStatus}
        onClose={() => setUpdateCarInfoModal(false)}
        onCarUpdated={getUserCars}
      />

      <KilometerUpdateModal
        isOpen={updateKilometerModal}
        onClose={() => setUpdateKilometerModal(false)}
        onDone={() => setAddServiceModal(true)}
        id={selectedCar}
        km={snap.carStatus ? snap.carStatus.kilometer || undefined : undefined}
      />

      <ReminderDetailModal
        isOpen={reminderDetailModal}
        onClose={() => setReminderDetailModal(false)}
        service={selectedService}
        onDone={() => getReminders(selectedCar)}
        needUpdate={dateComparison}
      />

      <PwaModal isOpen={pwaModal} onClose={() => setPwaModal(false)} />
      <VpnAlert isOpen={vpnEnabled} onClose={() => setVpnEnabled(false)} />
      <UpdateLogModal isOpen={versionModal} onClose={() => setVersionModal(false)} />
    </>
  );
});

export { HomePage as default };

const styles = {};
