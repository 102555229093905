/* eslint-disable */
import { digitsFaToEn } from "@persian-tools/persian-tools";
import React, { useEffect, useState } from "react";
import { HiArrowNarrowLeft, HiArrowNarrowRight } from "react-icons/hi";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useNavigate } from "react-router-dom";
import { Input } from "rsuite";
import { useSnapshot } from "valtio";
import banner from "../../../assets/images/setup-banner.svg";
import {
  ApiInstance,
  ConfigLink,
  ServiceStorage,
  tools,
  useChangeTitle,
  useFetchQueryString,
  vState,
} from "../../../core";
import { Button, SetupCarTemplate, Text, useNotification } from "../../components";

interface IRegisterKilometerProps {}

const env = import.meta.env;

interface IQueryStringTransfer {
  id: string;
}

const RegisterKilometer: React.FC<IRegisterKilometerProps> = React.memo(() => {
  //@ts-ignore
  const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  let { id } = useFetchQueryString<IQueryStringTransfer>();

  const metaTags = useChangeTitle("اطلاعات خودرو");
  const navigate = useNavigate();
  const snap = useSnapshot(vState);
  const returnUrl = ServiceStorage.getTempReturnURL();

  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const [kilometer, setKilometer] = useState("");

  useEffect(() => {
    setDisabled(kilometer.length === 0);
  }, [kilometer]);

  const registerDataHandler = async () => {
    setLoading(true);
    const km = +digitsFaToEn(kilometer);
    await ApiInstance.car
      .updateCarKilometer(id || "", km)
      .then((res: any) => {
        console.log(res);
        useNotification("کارکرد خودروی شما ثبت شد.", "success");
        navigate(ConfigLink.homePage, { replace: true });
      })
      .catch((err) => {
        console.log("err", err);
        useNotification("مشکلی رخ داده است. لطفا اتصال خود را بررسی کنید و دوباره سعی کنید.", "error", "ثبت کارکرد");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const previousStepHandler = () => {
    navigate(ConfigLink.createCarWelcome);
  };

  return (
    <>
      <SetupCarTemplate metaTag={metaTags} className="-mt-14" wrapperClassName="mt-8">
        <img src={banner} alt="banner" className="mb-8 w-full" />
        <Text type="H3" className="ts-accent">
          ثبت کارکرد خودرو
        </Text>
        <Text type="P3" className="ts-gray-08 mt-4 mb-8">
          کارکرد فعلی خودرو را وارد نمایید.
        </Text>

        <Text type="H6" className="ts-gray-07 mb-2">
          کیلومتر فعلی
        </Text>
        <Input
          type={tools.isIOS() ? "text" : "number"}
          pattern="[0-9]*"
          value={kilometer}
          required
          onChange={(e) => {
            setKilometer(e);
          }}
          className={`w-full rounded-xl mb-8 outline-none h-12 bg-transparent placeholder:text-gray-300`}
          placeholder="به عنوان مثال: 8000"
        />
        <div className="w-full flex justify-between items-center gap-8">
          <Button
            onClick={registerDataHandler}
            loading={loading}
            disabled={disabled}
            icon={<HiArrowNarrowRight size={23} className="text-white ml-2" />}
            bcolor="primary"
            textType="H6"
            classNameContainer="ts-half-width"
            className="w-full rounded-xl h-12"
          >
            ثبت کارکرد
          </Button>

          <Button
            onClick={previousStepHandler}
            icon={<HiArrowNarrowLeft size={23} className="ts-primary mr-2" />}
            iconPosition="afterText"
            bcolor="primaryTransparent"
            textType="H6"
            classNameContainer="ts-half-width"
            className="w-full rounded-xl h-12"
          >
            قبلی
          </Button>
        </div>
      </SetupCarTemplate>
    </>
  );
});

export { RegisterKilometer as default };

const styles = {};
