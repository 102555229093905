/* eslint-disable */
import { useAuth } from "hooks";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { RiErrorWarningFill } from "react-icons/ri";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";
import { Divider } from "rsuite";
import { useSnapshot } from "valtio";
import banner from "../../../assets/images/car-prototype.svg";
import kmIcon from "../../../assets/images/kilometer-icon-primary.svg";
import {
  ApiInstance,
  ConfigApp,
  ConfigLink,
  tools,
  useChangeTitle,
  useFetchQueryString,
  useObjectState,
  useQueryString,
  vState,
} from "../../../core";
import { Button, ContainerScroll, Text } from "../../components";
import { CarKilometerRegisterModal, LicensePlate } from "../Car/components";
import { DownloadApp, FeaturesList, LoginPrompt } from "./components";

interface IOwnerPreRegisterProps {}

interface IQueryStringTransfer {
  id: string;
  update: "1";
}
const env = import.meta.env;

const OwnerPreRegister: React.FC<IOwnerPreRegisterProps> = React.memo(({}) => {
  const metaTags = useChangeTitle("ویراژ - وضعیت خودرو");
  const auth = useAuth();
  const navigate = useNavigate();
  const snap = useSnapshot(vState);
  let { id, update } = useFetchQueryString<IQueryStringTransfer>();
  const today = new Date().toISOString();
  const isRunningStandalone = ConfigApp.isRunningStandalone();

  const [loading, setLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(true);
  const [loginPrompt, setLoginPrompt] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [ownerStatus, setOwnerStatus] = useObjectState({
    createdAt: "",
    id: "",
    kilometer: 0,
    mobile: "",
    plateNumber: "",
    carName: "",
    car: null,
    status: "in_progress",
    kilomeeterCreatedAt: "",
  });
  const daysDiff = moment(today).diff(ownerStatus.kilomeeterCreatedAt, "days");

  const getCarStatus = async () => {
    await ApiInstance.plates
      .getOwnerReportForKilometer(id)
      .then((res) => {
        console.log(res);
        setOwnerStatus(res.data.data);
      })
      .catch((err) => console.log("err", err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (update && update === "1") {
      setUpdateModal(true);
    }
    getCarStatus();
  }, []);

  useEffect(() => {
    if (auth.ready) {
      setLoggedIn(auth.authenticated);
    }
  }, [auth]);

  const loginClickHandler = () => {
    const url = new URL(`${ConfigLink.ownerPreRegister}?id=${id}&update=1`, window.location.origin);
    auth.login({
      redirectUri: url.toString(),
    });
  };

  const updateKilometerHandler = () => {
    if (auth.ready && auth.authenticated) {
      setUpdateModal(true);
    } else {
      setLoginPrompt(true);
    }
  };

  const closeModalHandler = () => {
    setUpdateModal(false);
    if (update) {
      navigate(`${ConfigLink.ownerPreRegister}?id=${id}`, { replace: true });
    }
  };

  const updateValuesHandler = (km) => {
    setOwnerStatus({
      kilometer: km,
      kilomeeterCreatedAt: new Date().toISOString(),
    });
    navigate({
      pathname: ConfigLink.carStatus,
      search: useQueryString({ id }),
    });
  };

  return (
    <>
      {metaTags}
      <div className="w-full ts-cars-card-bg mt-20 -mb-20">
        <img src={banner} alt="banner" className="mb-6 mx-auto" />

        <Text type="P4" className="ts-accent text-center mb-2">
          کارکرد قبلی خودرو
        </Text>
        <div className="flex justify-center items-center mb-5">
          <img src={kmIcon} alt="path-icn" className="-mt-1 w-9 h-9" />
          {loading ? (
            <Skeleton className="w-28" />
          ) : (
            <Text type="H3" className="ts-primary mr-1">
              {ownerStatus.kilometer ? `${tools.thousandSeparator(ownerStatus.kilometer)} کیلومتر` : "کیلومتر ثبت نشده"}
            </Text>
          )}
        </div>

        <Text type="P4" className="ts-accent text-center mb-6">
          آخرین بروزرسانی:{" "}
          {ownerStatus.kilomeeterCreatedAt ? tools.convertDateToPersianDate(ownerStatus.kilomeeterCreatedAt) : "---"}{" "}
          <b className={`ts-text-p4 ts-primary`}>({daysDiff === 0 ? "امروز" : `${daysDiff} روز گذشته`})</b>
        </Text>

        <div className="w-full flex flex-col justify-center items-center">
          <div style={{ maxWidth: "244px" }} className="w-full flex justify-center items-center mb-6">
            <LicensePlate
              firstSection={+ownerStatus.plateNumber.slice(0, 2)}
              letter={ownerStatus.plateNumber.slice(2, 3) === "ا" ? "الف" : ownerStatus.plateNumber.slice(2, 3)}
              secondSection={+ownerStatus.plateNumber.substring(3, 6)}
              thirdSection={+ownerStatus.plateNumber.substring(7, 9)}
            />
          </div>

          <div style={{ maxWidth: "244px" }} className="w-full flex justify-evenly items-center mb-14">
            <span className="flex flex-col justify-start items-start">
              <Text type="P4" className="ts-gray-06 mb-1">
                نام خودرو
              </Text>
              {loading ? (
                <Skeleton className="w-28" />
              ) : (
                <Text type="H6" className="ts-accent">
                  {ownerStatus.carName || "---"}
                </Text>
              )}
            </span>

            <Divider vertical className="mx-6 h-11" />

            <span className="flex flex-col justify-start items-start">
              <Text type="P4" className="ts-gray-06 mb-1">
                نوع و برند خودرو
              </Text>
              {loading ? (
                <Skeleton className="w-28" />
              ) : (
                <Text type="H6" className="ts-accent">
                  {/* @ts-ignore */}
                  {ownerStatus.car ? ownerStatus.car?.name : "---"}
                </Text>
              )}
            </span>
          </div>
        </div>
      </div>
      <ContainerScroll heightOffset={200} className={`relative`}>
        <Text type="H4" className="ts-accent mb-3 text-right">
          امکانات ویراژ
        </Text>
        <FeaturesList />

        {!isRunningStandalone && (
          <>
            <Text type="H4" className="ts-accent mt-6 mb-4 text-right">
              دانلود اپلیکیشن ویراژ
            </Text>
            <DownloadApp type="owner" />
          </>
        )}

        <div className="w-full mt-8 relative ts-bg-gray-01 rounded-2xl px-4 py-2.5 flex justify-start items-start">
          <div className="w-5 h-5 absolute top-2.5 right-4">
            <RiErrorWarningFill size={20} className="ts-gray-07" />
          </div>
          <Text type="P4" className="ts-gray-07 indent-6">
            چنانچه این پیامک به اشتباه برای شما ارسال شده است آن را نادیده بگیرید یا با شماره تلفن 02191693862 تماس حاصل
            نمایید.
          </Text>
        </div>
      </ContainerScroll>

      <div className={`w-full flex flex-col justify-center items-center py-6 px-3 ts-temp-box text-center`}>
        <Text type="P4" className="ts-gray-08 mb-4 temp-box-text">
          برای مشاهده وضعیت خودرو، مدیریت یادآورها میتوانید کارکرد خودروی خود را به روزرسانی کنید.
        </Text>
        <Button
          onClick={updateKilometerHandler}
          bcolor="primary"
          classNameContainer="w-full"
          className="w-full h-12 rounded-xl"
        >
          به روزرسانی کارکرد
        </Button>
      </div>

      <LoginPrompt
        isOpen={loginPrompt}
        title={"بروزرسانی کارکرد"}
        onClose={() => setLoginPrompt(false)}
        onClick={loginClickHandler}
      />

      <CarKilometerRegisterModal
        justForEdit
        id={id}
        isOpen={updateModal}
        onClose={closeModalHandler}
        km={ownerStatus.kilometer || 0}
        onDone={(km) => updateValuesHandler(km)}
      />
    </>
  );
});

export { OwnerPreRegister as default };
