/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loader } from "rsuite";
import { useSnapshot } from "valtio";
import banner from "../../../../assets/images/setup-banner.svg";
import { ApiInstance, ConfigLink, ServiceStorage, vState } from "../../../../core";
import { useAuth } from "../../../../hooks";
import { BottomModal, Button, SetupCarTemplate, Text, useNotification } from "../../../components";

interface ISetupModalProps {
  isOpen: boolean;
  onClose: () => void;
  id: string;
}

const SetupModal: React.FC<ISetupModalProps> = React.memo(({ isOpen, onClose, id }) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const snap = useSnapshot(vState);

  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [time, setTime] = useState(0);

  const nextLevelHandler = () => {
    ServiceStorage.setSetupCar(id);
    ServiceStorage.setSetupReturnURL(`${window.location.pathname}${window.location.search}`);
    navigate(ConfigLink.createCarRegisterKilometer);
  };

  const previousStepHandler = () => {
    onClose();
  };

  const getServices = async () => {
    await ApiInstance.common
      .getSetupServices()
      .then((res) => {
        console.log(res);
        vState.setupServices = res.data.data;
        ServiceStorage.setSetupServices(res.data.data);
        setTime(res.data.data.length);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا مجددا امتحان کنید.", "error", "سرویس ها");
        setHasError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isOpen) {
      getServices();
    }
  }, [isOpen]);

  return (
    <BottomModal centerScreen isOpen={isOpen} onClose={onClose}>
      <SetupCarTemplate noPadding noDenyButton>
        <img src={banner} alt="banner" className="mb-8 w-full" />
        <Text type="H3" className="ts-accent">
          ثبت آخرین وضعیت خودرو
        </Text>
        <Text type="P3" className="ts-gray-08 mt-4">
          برای ارایه خدمات بهتر خواهشمندیم آخرین اطلاعات سرویس های خودرو را وارد نمایید تا پروفایل خودروی شما بر اساس
          آنها ایجاد گردد.
        </Text>
        <div className="flex justify-center items-center my-8">
          <Text type="P3" className="ts-accent ml-1">
            زمان تقریبی:
          </Text>
          {loading ? (
            <Loader size="sm" />
          ) : (
            <Text type="H5" className="ts-accent">
              {time} دقیقه
            </Text>
          )}
        </div>
        <div className="w-full flex justify-between items-center gap-2">
          <Button
            onClick={nextLevelHandler}
            disabled={loading || hasError}
            bcolor="primary"
            textType="H6"
            classNameContainer="ts-half-width"
            className="w-full rounded-xl h-12"
          >
            باشه، بریم جلو
          </Button>

          <Button
            onClick={previousStepHandler}
            iconPosition="afterText"
            bcolor="primaryTransparent"
            textType="H6"
            classNameContainer="ts-half-width"
            className="w-full rounded-xl h-12"
          >
            بعدا اضافه می‌کنم
          </Button>
        </div>
      </SetupCarTemplate>
    </BottomModal>
  );
});

export { SetupModal };

const styles = {};
