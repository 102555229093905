/* eslint-disable */
import { ConfigLink, tools } from "core";
import React from "react";
import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Text } from "../../../components";
import { useQueryString } from "./../../../../core/hooks/useQueryString";

interface IHistoryCardProps {
  data: any;
  plateId: string;
}

const HistoryCard: React.FC<IHistoryCardProps> = React.memo(({ data, plateId }) => {
  const navigate = useNavigate();

  const cardClickHandler = () => {
    navigate({ pathname: ConfigLink.historyDetail, search: useQueryString({ id: data.id, car: plateId }) });
  };

  return (
    <div
      onClick={cardClickHandler}
      style={{ height: "103px" }}
      className="w-full bg-white cursor-pointer ts-shadow rounded-2xl mb-3 p-3 flex justify-between items-center"
    >
      <div className="w-full max-2xs:w-5/6 flex flex-col justify-between items-start">
        <Text type="H6" className="ts-accent mb-2">
          {data.workshop ? data.workshop.name || "نام در دسترس نیست" : "نام در دسترس نیست"}
        </Text>
        <Text type="P4" className="ts-gray-06">
          {data.updatedAt ? tools.convertDateToPersianDate(data.updatedAt, false, true) : "تاریخ در دسترس نیست"}
        </Text>
      </div>
      <BiChevronLeft size={26} className="ts-accent" />
    </div>
  );
});

export { HistoryCard };

const styles = {};
