/* eslint-disable */
import React, { useEffect, useState } from "react";
import { HiTrash } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { ApiInstance, vState } from "../../../../core";
import { useAuth } from "../../../../hooks";
import { BottomModal, Button, Text, useNotification } from "../../../components";

interface IDeleteCarModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDeleteEnded: () => void;
  id: string;
}

const DeleteCarModal: React.FC<IDeleteCarModalProps> = React.memo(({ isOpen, onClose, id, onDeleteEnded }) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const snap = useSnapshot(vState);
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, []);

  const confirmHandler = async () => {
    setLoading(true);
    await ApiInstance.plates
      .archiveCar(id)
      .then((res) => {
        console.log(res);
        useNotification("خودرو حذف شد", "success", "حذف خودرو");
        onDeleteEnded();
        onClose();
      })
      .catch((err) => {
        console.log("err", err);
        useNotification("مشکلی رخ داده است. لطفا مجددا تلاش کنید.", "error", "حذف خودرو");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <BottomModal isOpen={isOpen} onClose={onClose}>
      <div className="w-full flex flex-col justify-start items-start">
        <div className="flex justify-end items-center mb-6">
          <HiTrash size={21} className="ts-secondary-red" />
          <Text type="H4" className="ts-secondary-red mr-2">
            حذف خودرو
          </Text>
        </div>
        <Text type="P3" className="ts-accent mb-8">
          آيا نسبت به حذف ماشین از این گاراژ اطمینان دارید؟ لازم هست بدانید که تمامی یادآورهای ثبت شده برای این خودرو،
          با حذف ماشین از بین میروند.
        </Text>
        <div className=" w-full flex justify-between items-center">
          <Button
            onClick={confirmHandler}
            loading={loading}
            bcolor="black"
            textType="H6"
            classNameContainer="ts-half-width"
            className="w-full rounded-lg h-12"
          >
            بله، حذف شود
          </Button>

          <Button
            onClick={() => onClose()}
            bcolor="blackTransparent"
            textType="H6"
            classNameContainer="ts-half-width"
            className="w-full rounded-lg h-12"
          >
            خیر
          </Button>
        </div>
      </div>
    </BottomModal>
  );
});

export { DeleteCarModal };

const styles = {};
