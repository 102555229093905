/* eslint-disable */
import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";

interface IReminderLoaderProps {}

const ReminderLoader: React.FC<IReminderLoaderProps> = React.memo(() => {
  return (
    <div className="w-full -mt-0.5">
      {[1, 2, 3, 4].map((item) => {
        return (
          <Skeleton style={{ height: "113px" }} containerClassName="w-full" className={`mb-3 w-full rounded-2xl`} />
        );
      })}
    </div>
  );
});

export { ReminderLoader };

const styles = {};
