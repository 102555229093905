/* eslint-disable */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Radio, RadioGroup } from "rsuite";
import { useSnapshot } from "valtio";
import { ApiInstance, ConfigLink, vState } from "../../../../core";
import { BottomModal, Button, Text, useNotification } from "../../../components";

interface IDeletePartRequestReasonModalProps {
  isOpen: boolean;
  onClose: () => void;
  id: string;
}

const DeletePartRequestReasonModal: React.FC<IDeletePartRequestReasonModalProps> = React.memo(
  ({ isOpen, onClose, id }) => {
    const navigate = useNavigate();
    const snap = useSnapshot(vState);

    const [loading, setLoading] = useState(false);
    const [reason, setReason] = useState<any>("");

    const confirmHandler = async () => {
      setLoading(true);
      await ApiInstance.partSupply
        .deleteRequestInfo(id, reason)
        .then((res) => {
          console.log(res);
          useNotification("درخواست قطعه مورد نظر حذف شد.", "success");
          onClose();
          navigate(ConfigLink.partRequest);
        })
        .catch((err) => {
          console.log(err);
          useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error");
        })
        .finally(() => {
          setLoading(false);
        });
    };

    return (
      <BottomModal isOpen={isOpen} onClose={onClose}>
        <div className="w-full flex flex-col justify-start items-start">
          <Text type="H4" className="ts-accent">
            حذف درخواست
          </Text>

          <Text type="P3" className="ts-accent my-6">
            لطفا دلیل حذف درخواست خود را انتخاب کنید.
          </Text>

          <RadioGroup name="delete-reason" value={reason} onChange={(e) => setReason(e)}>
            <Radio value="provided_by_virazh" className="ts-text-hm">
              قطعه از طریق ویراژ تامین شد.
            </Radio>
            <Radio value="provided_by_others" className="ts-text-hm">
              قطعه را از جای دیگری تهیه کردم.
            </Radio>
            <Radio value="not_provided" className="ts-text-hm">
              تامین کننده‌ای پیدا نشد.
            </Radio>
            <Radio value="cancelled" className="ts-text-hm">
              از خرید قطعه منصرف شدم.
            </Radio>
          </RadioGroup>

          <Button
            onClick={confirmHandler}
            loading={loading}
            disabled={reason.length === 0}
            bcolor="primary"
            classNameContainer="w-full"
            className="w-full rounded-xl h-12 mt-6"
          >
            ذخیره
          </Button>
        </div>
      </BottomModal>
    );
  },
);

export { DeletePartRequestReasonModal };

const styles = {};
