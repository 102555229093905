/* eslint-disable */
import React, { useEffect, useState } from "react";
import { AiFillExclamationCircle } from "react-icons/ai";
import Skeleton from "react-loading-skeleton";
import { useNavigate } from "react-router";
import { useSnapshot } from "valtio";
import { ApiInstance, countries, tools, useChangeTitle, useFetchQueryString, vState } from "../../../core";
import { ContainerScroll, ImageSlider, ImageViewerModal, ReportProblem, Text, useNotification } from "../../components";
import ProviderCard from "./components/ProviderCard";

interface IMyPartDetailProps {}

interface IQueryStringTransfer {
  id: string;
}

interface ICarProps {
  id: string;
  name: string;
}

interface IPartProps {
  name: string;
  cars: ICarProps[] | null;
  allCars: boolean;
  grouping: string;
  // manufacture: [],
  manufacture: string;
  manufacturingCountry: string;
  createdBy: any;
  price: string;
  code?: string;
  description?: string;
  workshop: any;
  images: string[] | null;
}

const PartDetail: React.FC<IMyPartDetailProps> = React.memo(({}) => {
  const metaTags = useChangeTitle("ویراژ - جزئیات قطعه");
  const navigate = useNavigate();
  const snap = useSnapshot(vState);
  const { id } = useFetchQueryString<IQueryStringTransfer>();

  const [partInfo, setPartInfo] = useState<IPartProps>({
    name: "",
    cars: null,
    allCars: false,
    grouping: "",
    // manufacture: [],
    manufacture: "",
    manufacturingCountry: "",
    createdBy: null,
    price: "",
    code: "",
    description: "",
    workshop: null,
    images: null,
  });
  const [pageLoading, setPageLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [reportModalShow, setReportModalShow] = useState<boolean>(false);

  const getPartInfo = async () => {
    await ApiInstance.shop
      .getPartInfo(id)
      .then((res) => {
        console.log(res);
        const data = res.data.data;
        setPartInfo(data);
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error");
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  useEffect(() => {
    getPartInfo();
  }, []);

  const reportOptions: any[] = [
    {
      id: 0,
      label: "اطلاعات قطعه ناقص است.",
      value: "not_precise",
    },
    {
      id: 1,
      label: "حاوی محتوای نامربوط است.",
      value: "irrelevant_content",
    },
    {
      id: 2,
      label: "تعریف کننده قطعه نامعتبر است.",
      value: "invalid_creator",
    },
    {
      id: 3,
      label: "سایر",
      value: "other",
    },
  ];

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={115} className={`relative overflow-x-hidden`}>
        <div className="w-full flex justify-between items-center mb-4">
          <Text type="H4" className="ts-accent">
            اطلاعات قطعه
          </Text>
        </div>
        <>
          <div className="w-full rounded-2xl ts-shadow p-4 mb-8 flex flex-col justify-start items-start">
            <div className="w-full flex justify-start items-start mb-4">
              <Text type="H6" className="ts-accent w-20 ml-2">
                نام قطعه
              </Text>
              {pageLoading ? (
                <Skeleton className="w-24" />
              ) : (
                <Text type="P4" className="ts-accent">
                  {partInfo.name || "---"}
                </Text>
              )}
            </div>

            <div className="w-full flex justify-start items-start mb-4">
              <Text type="H6" className="ts-accent w-20 ml-2">
                خودرو
              </Text>
              {pageLoading ? (
                <Skeleton className="w-24" />
              ) : (
                <Text type="P4" className="ts-accent">
                  {partInfo.cars && partInfo.cars.length !== 0
                    ? partInfo.cars.map((item) => item.name).join("، ")
                    : partInfo.allCars
                    ? "همه خودروها"
                    : "---"}
                </Text>
              )}
            </div>

            <div className="w-full flex justify-start items-start mb-4">
              <Text type="H6" className="ts-accent w-20 ml-2">
                دسته بندی
              </Text>
              {pageLoading ? (
                <Skeleton className="w-24" />
              ) : (
                <Text type="P4" className="ts-accent">
                  {partInfo.grouping || "---"}
                </Text>
              )}
            </div>

            <div className="w-full flex justify-start items-start mb-4">
              <Text type="H6" className="ts-accent w-20 ml-2">
                شرکت سازنده
              </Text>
              {pageLoading ? (
                <Skeleton className="w-24" />
              ) : (
                <Text type="P4" className="ts-accent">
                  {partInfo.manufacture ? partInfo.manufacture : "---"}
                </Text>
              )}
            </div>

            <div className="w-full flex justify-start items-start mb-4">
              <Text type="H6" className="ts-accent w-20 ml-2">
                کشور سازنده
              </Text>
              {pageLoading ? (
                <Skeleton className="w-24" />
              ) : (
                <Text type="P4" className="ts-accent">
                  {partInfo.manufacturingCountry
                    ? countries.filter((item) => item.value === partInfo.manufacturingCountry)[0].label
                    : "---"}
                </Text>
              )}
            </div>

            <div className="w-full flex justify-start items-start mb-4">
              <Text type="H6" className="ts-accent w-20 ml-2">
                قیمت
              </Text>
              {pageLoading ? (
                <Skeleton className="w-24" />
              ) : (
                <Text type="P4" className="ts-accent">
                  {partInfo.price ? `${tools.thousandSeparator(partInfo.price)} تومان` : "---"}
                </Text>
              )}
            </div>

            <div className="w-full flex justify-start items-start mb-4">
              <Text type="H6" className="ts-accent w-20 ml-2">
                کد یکتا
              </Text>
              {pageLoading ? (
                <Skeleton className="w-24" />
              ) : (
                <Text type="P4" className="ts-accent">
                  {partInfo.code ? partInfo.code : "---"}
                </Text>
              )}
            </div>

            <div className="w-full flex justify-start items-start">
              <Text type="H6" className="ts-accent w-20 ml-2">
                توضیحات
              </Text>
              {pageLoading ? (
                <Skeleton className="w-24" />
              ) : (
                <Text type="P4" className="ts-accent ts-request-description">
                  {partInfo.description || "---"}
                </Text>
              )}
            </div>

            {((partInfo.images && partInfo.images.length !== 0) || pageLoading) && (
              <div className="w-full flex flex-col justify-start items-start mt-4">
                <Text type="H6" className="ts-accent mb-2">
                  عکس قطعه
                </Text>
                {pageLoading ? (
                  <Skeleton className="w-full ts-carousel-image-height rounded-xl" />
                ) : (
                  <ImageSlider
                    images={partInfo.images || []}
                    dir=""
                    onClick={(i) => {
                      setImageIndex(i);
                      setViewModalOpen(true);
                    }}
                  />
                )}
              </div>
            )}
          </div>

          <Text type="H4" className="ts-accent mb-4">
            تامین کننده
          </Text>
          <div className="flex justify-start items-start mb-4 py-2.5 px-4 rounded-2xl ts-pending-chips relative">
            <AiFillExclamationCircle size={22} className="ts-warning -mt-0.5 absolute top-2.5 right-4" />
            <Text type="P4" className="ts-gray-07 mr-1 indent-6">
              ویراژ هیچگونه مسئولیتی در قبال تامین قطعه و صحت قیمت و سایر اطلاعات آن ندارد. در صورت مشاهده یا بروز
              هرگونه مشکل می توانید
              <b onClick={() => setReportModalShow(true)} className="ts-text-p4 z-20 ts-info mr-0.5 cursor-pointer">
                گزارش کنید.
              </b>
            </Text>
          </div>

          {pageLoading ? (
            <ProviderCard type="loader" />
          ) : partInfo.workshop ? (
            <ProviderCard type="info" data={{ workshop: partInfo.workshop, createdBy: partInfo.createdBy }} />
          ) : null}
        </>
      </ContainerScroll>

      <ImageViewerModal
        isOpen={viewModalOpen}
        onClose={() => setViewModalOpen(false)}
        images={partInfo.images || []}
        dir="/shop"
        activeIndex={imageIndex}
      />
      <ReportProblem
        isOpen={reportModalShow}
        id={id}
        options={reportOptions}
        onClose={() => setReportModalShow(false)}
      />
      {/* <Footer activeTab={4} /> */}
    </>
  );
});

export { PartDetail as default };

const styles = {};
