/* eslint-disable */
import React from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { useSnapshot } from "valtio";
import { vState } from "../../../../core";
import { Text } from "../../../components";

interface ITabBarProps {
  activeTab: number;
  onActiveChanged: (id: number) => void;
}

const env = import.meta.env;

const TabBar: React.FC<ITabBarProps> = React.memo(({ activeTab, onActiveChanged }) => {
  const snap = useSnapshot(vState);

  return (
    <div className="w-full border-b-2 border-b-gray-300 flex justify-start items-center mb-6 flex-nowrap whitespace-nowrap overflow-x-auto overflow-y-hidden ts-hide-scroll-x-but-keep-working touch-pan-x">
      <Tab onClick={(id) => onActiveChanged(id)} tabNumber={1} title="یادآورها" activeTabNumber={activeTab} />
      <Tab onClick={(id) => onActiveChanged(id)} tabNumber={2} title="تاریخچه سرویس ها" activeTabNumber={activeTab} />
      <Tab onClick={(id) => onActiveChanged(id)} tabNumber={3} title="تنظیمات" activeTabNumber={activeTab} />
      <Tab
        className="mx-4"
        onClick={(id) => onActiveChanged(id)}
        tabNumber={4}
        title="دانستنی ها"
        activeTabNumber={activeTab}
      />
    </div>
  );
});

const Tab = ({ tabNumber, title, activeTabNumber, onClick, className = "" }) => {
  return (
    <div
      onClick={() => onClick(tabNumber)}
      className={`w-max p-3 transition-all duration-300 -mb-px flex justify-center cursor-pointer items-center h-12 ${
        activeTabNumber === tabNumber && "ts-bg-primary-light rounded-tl-xl rounded-tr-xl border-b-2 ts-active-tab-line"
      } ${className}`}
    >
      <Text
        type={activeTabNumber === tabNumber ? "H5" : "P3"}
        className={`w-max transition-all duration-300 ${activeTabNumber === tabNumber ? "ts-primary" : "ts-gray-07"}`}
      >
        {title}
      </Text>
    </div>
  );
};

export { TabBar };
