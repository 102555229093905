import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { Marker, useMap } from "react-leaflet";
import L from "leaflet";
import { useNotification } from "ui/components";
import markerImg from "../../../../assets/images/user_marker.svg";

interface ISetLocationProps {
  userLocation: number[] | null;
}

const UserLocation: React.FC<ISetLocationProps> = forwardRef((props, ref) => {
  const map = useMap();

  const [position, setPosition] = useState<any>(null);

  let mapIcon = L.icon({
    iconUrl: markerImg,
    iconSize: [25, 25],
    // iconAnchor: [20, 50],
  });

  useEffect(() => {
    map.locate().on("locationfound", function (e) {
      setPosition(e.latlng);
      const circle = L.circle(e.latlng, 15, { fillOpacity: 0.1, opacity: 1, color: "#1E46D7", weight: 1 });
      circle.addTo(map);
    });
  }, [map]);

  return position === null ? null : <Marker position={position} icon={mapIcon}></Marker>;
  // return null;
});

export { UserLocation };
