/* eslint-disable */
import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { ApiInstance, ConfigLink, ServiceStorage, vState } from "../../../../core";
import { Button, Text, useNotification } from "../../../components";

interface IFinalStepProps {}

const env = import.meta.env;

const FinalStep: React.FC<IFinalStepProps> = React.memo(() => {
  const navigate = useNavigate();
  const snap = useSnapshot(vState);

  const [loading, setLoading] = useState(false);

  const doneClickHandler = async (hasCity) => {
    const car = ServiceStorage.getSetupCar();
    await ApiInstance.car
      .removeFromSetup(car || "")
      .then((res) => {
        console.log(res);
        ServiceStorage.removeSetupCar();
        ServiceStorage.removeSetupServices();
        ServiceStorage.removeStep();
        ServiceStorage.removeTempReturnURL();
        ServiceStorage.setSetupFlowVisited();
        if (!("name" in snap.userInfo) || ("name" in snap.userInfo && snap.userInfo.name.length === 0) || !hasCity) {
          navigate(ConfigLink.completeAccount);
        } else {
          const url = ServiceStorage.getSetupReturnURL();
          ServiceStorage.removeSetupReturnURL();
          url
            ? navigate(url !== ConfigLink.createCarRegisterSteps ? url : ConfigLink.homePage)
            : navigate(ConfigLink.homePage);
        }
      })
      .catch((err) => {
        useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error", "راه اندازی خودرو");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getUserSetting = async () => {
    setLoading(true);
    await ApiInstance.account
      .getSetting()
      .then((res) => {
        console.log(res);
        const ownerSetting = res.data.ownerAppSettings;
        const commonSetting = res.data.bothAppSettings;
        doneClickHandler((ownerSetting && ownerSetting.city) || (commonSetting && commonSetting.city) ? true : false);
      })
      .catch((err) => {
        console.log("getSetting Error", err);
        if (err.response.status === 400 && err.response.data.message.toString().includes("Setting Not Found")) {
          doneClickHandler(false);
        } else {
          useNotification("مشکلی رخ داده است. لطفا دقایقی بعد مجددا امتحان کنید.", "error", "راه اندازی خودرو");
          setLoading(false);
        }
      });
  };

  return (
    <>
      <Text type="H3" className="ts-accent">
        وضعیت خودروی شما ثبت شد
      </Text>

      <Text type="P3" className="ts-gray-08 mt-4 mb-8">
        در صورت نیاز به تغییر اطلاعات وارد شده، همواره میتوانید از صفحه وضعیت خودروی خود، آنها را ویرایش یا حذف کنید.
      </Text>

      <Button
        onClick={getUserSetting}
        loading={loading}
        bcolor="primary"
        classNameContainer="w-full"
        className="w-full h-12 rounded-xl"
      >
        بزن بریم
      </Button>
    </>
  );
});

export { FinalStep };

const styles = {};
