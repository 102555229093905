/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { ApiInstance, ConfigLink, ServiceStorage, useChangeTitle, useFetchQueryString, vState } from "../../../core";
import { Button, ContainerScroll } from "../../components";
import { KilometerUpdateModal, RegisteredReminders } from "../Home/components";
import {
  AddOrEditCar,
  CarHistory,
  CarKilometerRegisterModal,
  CarSetting,
  CustomHeader,
  DeleteCarModal,
  Information,
  RegisterNewService,
  SetupModal,
  TabBar,
} from "./components";

interface ICarStatusProps {}

interface IQueryStringTransfer {
  id: string;
}
const env = import.meta.env;

const CarStatus: React.FC<ICarStatusProps> = React.memo(({}) => {
  const isLargeScreen = useMediaQuery({
    query: "(min-height: 960px)",
  });

  const metaTags = useChangeTitle("ویراژ - وضعیت خودرو");
  const navigate = useNavigate();
  const snap = useSnapshot(vState);
  let { id } = useFetchQueryString<IQueryStringTransfer>();
  const returnUrl = ServiceStorage.getTempReturnURL();

  const [loading, setLoading] = useState(true);
  const [reminderLoading, setReminderLoading] = useState(true);

  const [kilometerEdit, setKilometerEdit] = useState(false);
  const [updateKilometerModal, setUpdateKilometerModal] = useState(false);
  const [showRegisterServiceModal, setShowRegisterServiceModal] = useState(false);
  const [editCarModal, setEditCarModal] = useState(false);

  const [sortReminders, setSortReminders] = useState("kilometer");
  const [activeTab, setActiveTab] = useState(1);
  const [isShrunk, setShrunk] = useState(false);

  const [deleteCarModal, setDeleteCarModal] = useState(false);
  const [setupModal, setSetupModal] = useState(false);

  const getCarStatus = async () => {
    await ApiInstance.plates
      .getSingleCar(id)
      .then((res) => {
        console.log(res);
        vState.carStatus = res.data.data;
      })
      .catch((err) => console.log("err", err))
      .finally(() => {
        setLoading(false);
      });
  };

  const getReminders = async () => {
    await ApiInstance.plates
      .getPlateReminders(id)
      .then((res) => {
        console.log("getReminders", res);
        vState.reminders = res.data.data;
      })
      .catch((err) => console.log("err", err))
      .finally(() => {
        setReminderLoading(false);
      });
  };

  useEffect(() => {
    vState.sessionId = id;
    getCarStatus();
    getReminders();
  }, []);

  const sortData = [
    {
      label: "به ترتیب کارکرد",
      value: "kilometer",
    },
    {
      label: "به ترتیب تاریخ",
      value: "expiry_date",
    },
  ];

  const changeSortHandler = (value) => {
    setLoading(true);
    setSortReminders(value);
    // getReminders(value, snap.carStatus.plate.id);
  };

  useEffect(() => {
    const updateScrollDirection = () => {
      const tab = document.getElementById("car-status-tabs");
      if (tab) {
        // console.log(tab.getBoundingClientRect().top);
        if (tab.getBoundingClientRect().top <= 81) {
          setShrunk(true);
        } else {
          setShrunk(false);
        }
      }
    };
    window.addEventListener("scroll", updateScrollDirection);
    return () => {
      window.removeEventListener("scroll", updateScrollDirection);
    };
  }, []);

  const tabWrapper = () => {
    if (activeTab === 1) {
      return (
        <RegisteredReminders
          reminderLoading={reminderLoading}
          returnUrl={`${ConfigLink.carStatus}${window.location.search}`}
          onButtonClicked={() => setShowRegisterServiceModal(true)}
          plate={id}
          updateNeeded={() => dateComparison()}
        />
      );
    } else if (activeTab === 2) {
      return <CarHistory id={id} />;
    } else if (activeTab === 3) {
      return <CarSetting id={id} onDelete={deleteCarHandler} onEdit={editCarHandler} onStart={setupCarHandler} />;
    } else {
      return <Information />;
    }
  };

  const editCarHandler = () => {
    setEditCarModal(true);
  };

  const deleteCarHandler = () => {
    setDeleteCarModal(true);
  };

  const setupCarHandler = () => {
    setSetupModal(true);
  };

  const dateComparison = (): boolean => {
    const kilometerUpdate = snap.carStatus.kilomeeterCreatedAt
      ? new Date(snap.carStatus.kilomeeterCreatedAt).toLocaleDateString("fa-IR")
      : null;
    const today = new Date().toLocaleDateString("fa-IR");

    return today === kilometerUpdate;
  };

  const checkKilometer = () => {
    if (snap.carStatus) {
      const isKilometerUpdated = dateComparison();
      if (snap.carStatus.kilometer && isKilometerUpdated) {
        setShowRegisterServiceModal(true);
      } else {
        setUpdateKilometerModal(true);
      }
    }
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={70} className={`z-10 relative`}>
        <div className="w-full">
          <CustomHeader
            loading={loading}
            onEdit={() => setEditCarModal(true)}
            updateKilometerClick={() => setKilometerEdit(true)}
          />
        </div>

        <div id="car-status-tabs" className={`w-full transition-all duration-500 ts-car-status-info bg-white`}>
          <div
            className={`w-full transition-all duration-75 ${
              isShrunk ? "fixed top-12 pt-9 bg-white z-20 ts-car-tab-wrapper" : ""
            }`}
          >
            <TabBar activeTab={activeTab} onActiveChanged={(number) => setActiveTab(number)} />
          </div>
        </div>

        {tabWrapper()}
      </ContainerScroll>
      {/* --------------- Modals ------------------- */}
      <RegisterNewService
        carId={id}
        type={"register"}
        isOpen={showRegisterServiceModal}
        currentKM={snap.carStatus.kilometer}
        onClose={() => setShowRegisterServiceModal((prev) => !prev)}
        onDone={() => {
          setReminderLoading(true);
          getReminders();
        }}
      />
      <KilometerUpdateModal
        isOpen={updateKilometerModal}
        onClose={() => setUpdateKilometerModal(false)}
        onDone={() => setShowRegisterServiceModal(true)}
        id={id}
        km={snap.carStatus ? snap.carStatus.kilometer || undefined : undefined}
      />
      <CarKilometerRegisterModal
        id={id}
        isSingle
        justForEdit={Boolean(snap.carStatus.kilometer)}
        km={snap.carStatus.kilometer || 0}
        isOpen={kilometerEdit}
        onClose={() => setKilometerEdit(false)}
        onDone={getCarStatus}
      />
      <AddOrEditCar
        type={"edit"}
        data={snap.carStatus}
        isOpen={editCarModal}
        onCarAdded={() => {
          setLoading(true);
          getCarStatus();
        }}
        onClose={() => setEditCarModal(false)}
      />

      <DeleteCarModal
        id={snap.carStatus.id || ""}
        onDeleteEnded={() => navigate(-1)}
        isOpen={deleteCarModal}
        onClose={() => setDeleteCarModal(false)}
      />
      <SetupModal id={snap.carStatus.id || ""} isOpen={setupModal} onClose={() => setSetupModal(false)} />

      {snap.carStatus.kilometer && activeTab === 1 && (
        <Button
          onClick={checkKilometer}
          bcolor="primary"
          textType="H6"
          classNameContainer="ts-float-button"
          className="w-full h-12 rounded-xl"
        >
          ثبت یادآور جدید
        </Button>
      )}
    </>
  );
});

export { CarStatus as default };
