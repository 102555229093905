/* eslint-disable */
import { negativeItems, positiveItems } from "core";
import React, { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { StarRating, Text } from "./";

interface IFeedbackCardProps {
  data: any;
  accordion?: boolean;
  expandable?: boolean;
}

const FeedbackCard: React.FC<IFeedbackCardProps> = React.memo(({ data, accordion = false, expandable = false }) => {
  const [cardOpen, setCardOpen] = useState(false);
  const [positives, setPositives] = useState([]);
  const [negatives, setNegatives] = useState([]);

  const separateFeedback = (feedback) => {
    let pos: any = positives;
    let neg: any = negatives;
    Object.keys(feedback).map((item) => {
      if (feedback[item] === true) {
        pos.push(positiveItems.filter((pos) => pos.value === item)[0]);
        setPositives(pos);
      } else if (feedback[item] === false) {
        neg.push(negativeItems.filter((neg) => neg.value === item)[0]);
        setNegatives(neg);
      }
    });
  };

  useEffect(() => {
    if (data) {
      separateFeedback(data);
    }
  }, [data]);

  useEffect(() => {
    if (accordion || expandable) {
      setCardOpen(false);
    }
  }, [accordion, expandable]);

  return (
    <div
      className={`w-full ${
        cardOpen ? "ts-feedback-box-open" : expandable ? "ts-feedback-card-expandable-close" : "ts-feedback-card-close"
      } ts-shadow ${accordion ? "mt-2" : "mt-6 mb-4"} rounded-2xl bg-white flex flex-col justify-start items-start p-4`}
    >
      <div
        onClick={() => {
          if (accordion) setCardOpen((prev) => !prev);
        }}
        className="w-full mb-4 flex justify-between items-center"
      >
        {!accordion ? (
          <Text type="H5" className="ts-accent">
            دیدگاه ثبت شده شما
          </Text>
        ) : (
          <Text type="H6" className="ts-accent">
            {data.userInfo.name || "بدون نام"}
          </Text>
        )}

        {accordion && (
          <BiChevronDown
            size={23}
            className={`ts-accent transition-all duration-300 mr-1.5 ${cardOpen ? "rotate-180" : "rotate-0"}`}
          />
        )}
      </div>
      <div className="w-full flex justify-start items-center mb-3">
        <Text type="P4" className="w-28 ml-3 ts-gray-06">
          امتیاز داده شده:
        </Text>
        <StarRating initialValue={data.score || 0} size={19} allowFraction readonly />
      </div>

      {accordion && (
        <div className="w-full flex justify-start items-center mb-3">
          <Text type="P4" className="w-28 ml-3 ts-gray-06">
            شماره تماس:
          </Text>
          <Text type="P4" className="ts-accent">
            {data.userInfo.phoneNumber || "---"}
          </Text>
        </div>
      )}

      <div className="w-full flex justify-start items-center mb-3">
        <Text type="P4" className="w-28 ml-3 ts-gray-06">
          زمان ارسال نظر:
        </Text>
        <Text type="P4" className="ts-accent">
          {data.createdAt
            ? `${new Date(data.createdAt).toLocaleDateString("fa-IR")} - ساعت ${new Date(
                data.createdAt,
              ).toLocaleTimeString("fa-IR", { hour: "2-digit", minute: "2-digit" })}`
            : "---"}
        </Text>
      </div>

      {expandable && !cardOpen && (
        <div onClick={() => setCardOpen(true)} className="w-full my-3 flex justify-center items-center">
          <Text type="H6" className="ts-primary ml-1">
            مشاهده بیشتر
          </Text>
          <BiChevronDown size={23} className={`ts-primary -mt-0.5`} />
        </div>
      )}
      {(positives.length !== 0 || negatives.length !== 0 || data.description) && (
        <hr className="w-full border-gray-200 mb-3" />
      )}
      {positives.length !== 0 && (
        <>
          <Text type="P4" className="ts-gray-06 mb-2">
            نقاط قوت:
          </Text>
          <ul className="list-disc mr-4">
            {positives.map((item: any) => {
              return <li className="ts-text-p4">{item.label}</li>;
            })}
          </ul>
        </>
      )}

      {negatives.length !== 0 && (
        <>
          <Text type="P4" className="ts-gray-06 mt-3 mb-2">
            نقاط ضعف:
          </Text>
          <ul className="list-disc mr-4">
            {negatives.map((item: any) => {
              return <li className="ts-text-p4">{item.label}</li>;
            })}
          </ul>
        </>
      )}

      {data.description && (
        <>
          <Text type="P4" className="ts-gray-06 mt-3 mb-2">
            توضیحات اضافی:
          </Text>
          <Text type="P4" className="ts-accent">
            {data.description}
          </Text>
        </>
      )}

      {expandable && cardOpen && (
        <div onClick={() => setCardOpen(false)} className="w-full mt-7 flex justify-center items-center">
          <Text type="H6" className="ts-primary ml-1">
            مشاهده کمتر
          </Text>
          <BiChevronDown size={23} className={`ts-primary -mt-0.5 rotate-180`} />
        </div>
      )}
    </div>
  );
});

export { FeedbackCard };
