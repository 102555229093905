/* eslint-disable */
import { ConfigLink, tools, useQueryString, vState } from "core";
import React from "react";
import { FiCornerUpRight } from "react-icons/fi";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import banner from "../../../../assets/images/history-detail-header.svg";
import { Button, Text } from "../../../components";

interface IHistoryDetailHeaderProps {
  data: any;
  loading: boolean;
  plateId: string;
  createdAt?: string;
}

const env = import.meta.env;

const HistoryDetailHeader: React.FC<IHistoryDetailHeaderProps> = React.memo(({ data, loading, plateId, createdAt }) => {
  const navigate = useNavigate();
  const snap = useSnapshot(vState);

  const isLargeScreen = useMediaQuery({
    query: "(min-width: 530px)",
  });

  const backButtonClickHandler = () => {
    // const returnUrl = ServiceStorage.getTempReturnURL();
    // ServiceStorage.removeTempReturnURL();
    // if (returnUrl) {
    //   navigate(returnUrl);
    // } else {
    navigate({ pathname: ConfigLink.carStatus, search: useQueryString({ id: plateId }) }, { replace: true });
    // }
  };

  const viewWorkshopHandler = () => {
    // ServiceStorage.setTempReturnURL(`${window.location.pathname}${window.location.search}`);
    navigate({ pathname: ConfigLink.workshopInfo, search: useQueryString({ id: data.id }) });
  };

  return (
    <div
      style={{
        background: `url(${banner})`,
        backgroundPosition: isLargeScreen ? "40% 55%" : "40% 65%",
        backgroundRepeat: "no-repeat",
      }}
      className={`w-full transition-all ts-history-custom-header-height-full bg-cover z-20 fixed -top-1 flex flex-col justify-between items-start pt-3 pb-6 max-2xs:px-3 p-6`}
    >
      <div
        onClick={backButtonClickHandler}
        className={`transition-all h-10 px-3  rounded-xl ts-bg-gray-01 flex justify-start items-center cursor-pointer`}
      >
        <FiCornerUpRight size={18} className="ts-primary" />
        <Text type="H6" className="ts-primary mr-1" onClick={backButtonClickHandler}>
          بازگشت
        </Text>
      </div>
      <div className="w-full">
        <div className="flex justify-start items-center mb-3">
          <Text type="P4" className="ts-gray-04 ml-4">
            تاریخ انجام سرویس:
          </Text>
          {loading ? (
            <Skeleton className="w-32" />
          ) : (
            <Text type="P4" className="text-white">
              {createdAt ? tools.convertDateToPersianDate(createdAt, false, true) : "تاریخ در دسترس نیست"}
            </Text>
          )}
        </div>
        <div className="w-full flex justify-between items-end rounded-xl p-3 ts-history-blur-header truncate">
          <div className="w-max flex flex-col justify-start items-start truncate">
            {loading ? (
              <Skeleton className="w-32" />
            ) : (
              <Text type="H5" className="text-white mb-2">
                {(data && data.name) || "نام کسب‌وکار ثبت نشده است."}
              </Text>
            )}
            {loading ? (
              <Skeleton className="w-52" />
            ) : (
              <Text type="P4" className="ts-gray-02 truncate ts-history-address-wrapper pl-1">
                {(data && data.address) || "آدرس ثبت نشده است."}
              </Text>
            )}
          </div>
          {data && !data.deletedAt && (
            <Button onClick={viewWorkshopHandler} bcolor="whiteTransparent" textType="H6" className="h-10 rounded-xl">
              مشاهده
            </Button>
          )}
        </div>
      </div>
    </div>
  );
});

export { HistoryDetailHeader };
