/* eslint-disable */
import { ConfigLink, ServiceStorage } from "core";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ContainerScroll } from "./ContainerScroll";
import { Text } from "./Text";

interface ISetupCarTemplateProps {
  className?: string;
  wrapperClassName?: string;
  redirectUrl?: string;
  metaTag?: JSX.Element;
  children: React.ReactNode;
  noPadding?: boolean;
  noDenyButton?: boolean;
}

const env = import.meta.env;

const SetupCarTemplate: React.FC<ISetupCarTemplateProps> = ({
  className = "",
  wrapperClassName = "",
  redirectUrl = ConfigLink.homePage,
  metaTag,
  children,
  noPadding = false,
  noDenyButton = false,
}) => {
  const navigate = useNavigate();

  const addLaterHandler = () => {
    ServiceStorage.setSetupFlowVisited();
    ServiceStorage.removeTempReturnURL();
    if (redirectUrl) {
      navigate(redirectUrl);
    } else {
      const url = ServiceStorage.getSetupReturnURL();
      ServiceStorage.removeSetupReturnURL();
      navigate(url || ConfigLink.homePage);
    }
  };

  return (
    <div
      className={`w-full ts-base-bg-white overflow-x-hidden relative flex flex-col justify-start items-end ${
        !noPadding && "pb-28"
      } ${wrapperClassName}`}
    >
      {metaTag && metaTag}
      {!noDenyButton && (
        <Text type="H6" className="ts-accent cursor-pointer px-6 max-2xs:px-3" onClick={addLaterHandler}>
          بعدا اضافه می کنم
        </Text>
      )}
      <ContainerScroll noPadding={noPadding} className={`overflow-x-hidden`}>
        <div className={`w-full flex flex-col justify-start items-start ${className}`}>{children}</div>
      </ContainerScroll>
    </div>
  );
};

export { SetupCarTemplate };

const styles = {};
