import { createContext, FC, PropsWithChildren, useLayoutEffect, useState } from "react";
import { ApiInstance, ConfigLink, ServiceStorage, vState } from "./core";
import { keycloak } from "./keycloak";
// import { UserProfile } from "#types";
import { UserProfile } from "./core/interfaces/dto/User";

export const AuthContext = createContext<{
  ready: boolean;
  authenticated: boolean;
  profile?: UserProfile;
}>({ authenticated: false, ready: false, profile: undefined });

export const AuthProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [ready, setReady] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [profile, setProfile] = useState<UserProfile | undefined>(undefined);

  useLayoutEffect(() => {
    keycloak.onReady = (result) => {
      setAuthenticated(!!result);
      vState.isAuthenticated = !!result;
      if (!!result) {
        ApiInstance.account
          .getUserProfile()
          .then((profile: any) => {
            ServiceStorage.setAuth(profile);
            vState.userInfo = profile;
            setProfile(profile);
            setReady(true);
          })
          .catch((err) => {
            console.log("Err get profile", err);
          });
      } else {
        console.log("ERROR");
        if (window.location.pathname !== ConfigLink.profile) {
          ServiceStorage.setRedirectUrl(`${window.location.href}${window.location.search}`);
        }
        setReady(true);
      }
    };

    // keycloak.onAuthSuccess = () => {
    //   ApiInstance.account.getUserProfile().then((profile) => {
    //     ServiceStorage.setAuth(profile);
    //     setProfile(profile);
    //   });
    // };

    keycloak.onAuthLogout = () => setAuthenticated(false);
    keycloak.onTokenExpired = () => {
      keycloak.updateToken(30);
      if (keycloak.idToken) {
        ServiceStorage.setToken(keycloak.idToken);
      }
    };
  }, []);

  return <AuthContext.Provider value={{ ready, authenticated, profile }}>{children}</AuthContext.Provider>;
};
