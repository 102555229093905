/* eslint-disable */
import React from "react";
import Skeleton from "react-loading-skeleton";
import user from "../../../../assets/images/user-profile-icon.svg";

interface IProfileLoaderProps {}

const ProfileLoader: React.FC<IProfileLoaderProps> = React.memo(() => {
  return (
    <>
      <div className="w-full flex justify-start items-center mb-4">
        <img src={user} alt="tst-user" className="w-14 h-14 rounded-full" />
        <div className="flex flex-col justify-between items-start">
          <Skeleton className={`w-32 mr-3`} />
        </div>
      </div>

      {[1, 2, 3, 4, 5, 6].map((item) => (
        <Skeleton
          key={item}
          style={{ height: "72px" }}
          containerClassName="w-full"
          className={`w-full mb-2 rounded-xl`}
        />
      ))}
    </>
  );
});

export { ProfileLoader };

const styles = {};
