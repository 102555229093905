import qs from "qs";

/**
 * Convert any Object into QueryString format
 * @param {any} inputObject give the object to serialize it into QueryString
 * @returns {string}
 * @example
 *  navigate({
 *    pathname:ConfigLink.account,
 *    search:useQueryString({id:1,name:"ali"})
 * })
 */
export const useQueryString = (inputObject: any = {}) => {
  let temp: string = qs.stringify({ ...inputObject }, { arrayFormat: "brackets", encode: false });
  return temp;
};
