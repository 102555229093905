/* eslint-disable */
import React from "react";
import { MdAddCircle } from "react-icons/md";
import banner from "../../../../assets/images/no-car-banner.svg";
import { Button, Text } from "../../../components";

interface IAddCarCardProps {
  type?: "add" | "extra";
  onClick: () => void;
}

const AddCarCard: React.FC<IAddCarCardProps> = React.memo(({ type = "add", onClick }) => {
  return (
    <div className="w-full ts-shadow flex flex-col justify-start items-start rounded-2xl p-4 mb-3 ts-cars-card-bg ts-cars-list-box-height">
      <span className="w-full flex justify-center items-center mb-4">
        <Text type="H4" className="ts-gray-08 text-center">
          {type === "extra" ? "خودرو دیگری دارید؟!" : "به ویراژ خوش آمدید"}
        </Text>
      </span>

      <img src={banner} alt="car-banner" className="m-auto mb-5" />

      <Text type="P4" className="ts-gray-08 text-center mb-5">
        {type === "extra"
          ? "ویراژ به شما کمک میکند تا اطلاعات سرویس‌های دوره‌ای همه خودروهای خود را مدیریت کنید."
          : "جهت استفاده از امکانات سرویس یادآور ویراژ لازم است ابتدا اطلاعات خودروهای خود را اضافه کنید."}
      </Text>

      <Button
        bcolor="primary"
        textType="H6"
        icon={<MdAddCircle size={20} className="text-white -mt-1" />}
        classNameContainer="w-full"
        className="w-full h-12 rounded-xl ts-letter-space"
        onClick={onClick}
      >
        اضافه کردن خودرو
      </Button>
    </div>
  );
});

export { AddCarCard };

const styles = {};
