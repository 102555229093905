/* eslint-disable */
import { isArabic, toPersianChars } from "@persian-tools/persian-tools";
import { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { Form, Input } from "rsuite";
import { useSnapshot } from "valtio";
import { ApiInstance, ConfigLink, ServiceStorage, vState } from "../../../core";
import { useChangeTitle } from "../../../core/hooks";
import { Button, ContainerScroll, SelectCity, Text, useNotification } from "../../components";

const env = import.meta.env;

const CompleteAccount = ({}) => {
  const metaTags = useChangeTitle("ویراژ - تکمیل پروفایل");
  const snap = useSnapshot(vState);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [cityModal, setCityModal] = useState(false);
  const [selectedCity, setSelectedCity] = useState<any | null>(null);

  const getProfile = async () => {
    await ApiInstance.account.getUserProfile().then((result: any) => {
      console.log(result);
      vState.userInfo = result;
      setName(result.name || "");
    });
  };

  const getCities = async (cityId) => {
    await ApiInstance.common
      .getAllCities()
      .then((res) => {
        console.log(res);
        const newArrayOfObj = res.data.data.map(({ id: value, city: city, province: province }) => ({
          value,
          label: `${isArabic(city) ? toPersianChars(city) : city}، ${
            isArabic(province) ? toPersianChars(province) : province
          }`,
        }));
        vState.cities = newArrayOfObj;
        const selectedCity = newArrayOfObj.filter((item) => item.value === cityId);
        if (selectedCity.length !== 0) {
          setCity(selectedCity[0].value);
          setSelectedCity(selectedCity[0]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserCity = async () => {
    await ApiInstance.account
      .getSetting()
      .then((res) => {
        console.log(res);
        const cityId = res.data.cityId;
        setCity(cityId || "");
        if (cityId) {
          getCities(cityId);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setDisabled(name.length === 0 || city.length === 0);
  }, [name, city]);

  useEffect(() => {
    getUserCity();
    const profile: any = ServiceStorage.getAuth();

    if (profile) {
      console.log(profile);
      vState.userInfo = profile;
      setName(snap.userInfo.name || "");
    } else {
      getProfile();
    }
  }, []);

  const saveInfo = () => {
    const copyUser = { ...snap.userInfo, name: name };
    console.log("copyUser", copyUser);
    ServiceStorage.setAuth(copyUser);
  };

  const changeNameHandler = async () => {
    setLoading(true);

    await ApiInstance.account
      .updateUserProfile(name)
      .then((res) => {
        vState.userInfo.name = name;
        saveInfo();
        cityRegisterHandler();
      })
      .catch((err) => {
        console.log(err);
        useNotification("مشکلی رخ داده است. لطفا مجددا تلاش کنید.", "error", "تکمیل پروفایل");
      });
  };

  const cityRegisterHandler = async () => {
    await ApiInstance.account
      .changeSetting({ cityId: city })
      .then((res) => {
        console.log("res", res);
        useNotification("اطلاعات حساب کاربری شما تکمیل شد.", "success", "تکمیل پروفایل");
        navigateToHome();
      })
      .catch((err) => {
        console.log("err", err);
        useNotification("مشکلی رخ داده است. لطفا مجددا تلاش کنید.", "error", "تکمیل پروفایل");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const selectCityHandler = (city) => {
    setSelectedCity(city);
    setCity(city.value);
  };

  const navigateToHome = () => {
    navigate(ConfigLink.homePage, { replace: true });
  };

  return (
    <>
      {metaTags}
      <ContainerScroll heightOffset={140} className="">
        <Text type="H4" className="ts-accent mb-8">
          تکمیل پروفایل
        </Text>

        <Form fluid className="w-full">
          <Text type="P3" className="ts-gray-08 mb-6">
            لطفا پروفایل خود را کامل کنید.
          </Text>
          <Text type="H6" className="ts-accent mb-2">
            نام و نام خانوادگی
          </Text>
          <Input
            value={name}
            required
            onChange={(e) => setName(e)}
            className={`w-full rounded-xl outline-none h-12 mb-6`}
            placeholder="نام و نام خانوادگی"
          />

          <Text type="H6" className="ts-accent mb-2">
            شهر
          </Text>

          <div
            onClick={() => setCityModal(true)}
            className="w-full cursor-pointer rounded-xl p-4 flex justify-between items-center h-12 mb-6 ts-input-border-city"
          >
            <Text type="P4" className="ts-accent">
              {selectedCity ? selectedCity.label : "انتخاب شهر"}
            </Text>
            <BiChevronDown size={21} className="ts-accent -mt-px" />
          </div>

          <Button
            onClick={changeNameHandler}
            loading={loading}
            disabled={disabled}
            bcolor="primary"
            textType="H6"
            classNameContainer="w-full"
            className="w-full h-12 rounded-xl mb-4"
          >
            تکمیل پروفایل
          </Button>
          <Button
            onClick={navigateToHome}
            bcolor="primaryTransparent"
            textType="H6"
            classNameContainer="w-full"
            className="w-full h-12 rounded-xl"
          >
            بعدا تکمیل می‌کنم
          </Button>
        </Form>
      </ContainerScroll>
      <SelectCity
        isOpen={cityModal}
        selectedId={city.length !== 0 ? city : selectedCity ? selectedCity?.value : null}
        onClose={() => setCityModal(false)}
        onSelect={(city) => selectCityHandler(city)}
      />
    </>
  );
};

export { CompleteAccount as default };
