/* eslint-disable */
import React from "react";
import { BiChevronLeft } from "react-icons/bi";
import Skeleton from "react-loading-skeleton";

interface IHistoryCardLoaderProps {}

const HistoryCardLoader: React.FC<IHistoryCardLoaderProps> = React.memo(() => {
  return (
    <>
      {[1, 2, 3].map((item) => (
        <div
          key={item}
          style={{ height: "103px" }}
          className="w-full bg-white ts-shadow rounded-2xl mb-3 p-3 flex justify-between items-center"
        >
          <div className="flex flex-col justify-between items-start">
            <Skeleton className="w-36 mb-2" />
            <Skeleton className="w-40" />
          </div>
          <BiChevronLeft size={24} className="ts-primary" />
        </div>
      ))}
    </>
  );
});

export { HistoryCardLoader };

const styles = {};
