/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { Input, SelectPicker } from "rsuite";
import flag from "../../../../assets/images/ir-flag.svg";
import { Text } from "../../../components";

interface IInputLicensePlateProps {
  lowMargin?: boolean;
  plateValue: any;
  setPlateValues: (e: any, place: string) => void;
  checkStateValue: (state: string, operation: () => void) => void;
  letterChange: (value: string) => void;
  error?: boolean;
  errorText?: string;
}

const InputLicensePlate: React.FC<IInputLicensePlateProps> = React.memo(
  ({
    lowMargin = false,
    plateValue,
    setPlateValues,
    letterChange,
    checkStateValue,
    error,
    errorText = "پر کردن همه قسمت های پلاک الزامی است",
  }) => {
    const [FSVisible, setFSVisible] = useState(false); //first section
    const [SSVisible, setSSVisible] = useState(false); //second section
    const [TSVisible, setTSVisible] = useState(false); //third section
    //@ts-ignore
    const isIOS = () => /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    const letterRef: any = useRef(null);

    useEffect(() => {
      if (
        plateValue.firstSection.length !== 0 &&
        plateValue.secondSection.length !== 0 &&
        plateValue.thirdSection.length !== 0
      ) {
        setFSVisible(true);
        setSSVisible(true);
        setTSVisible(true);
      }
    }, [plateValue]);

    const firstDash = () => {
      setFSVisible(true);
    };

    const secondDash = () => {
      setSSVisible(true);
    };

    const thirdDash = () => {
      setTSVisible(true);
    };

    const letters = [
      {
        label: "الف",
        value: "ا",
      },
      {
        label: "ب",
        value: "ب",
      },
      {
        label: "پ",
        value: "پ",
      },
      {
        label: "ت",
        value: "ت",
      },
      {
        label: "ث",
        value: "ث",
      },
      {
        label: "ج",
        value: "ج",
      },
      {
        label: "د",
        value: "د",
      },
      {
        label: "د",
        value: "د",
      },
      {
        label: "ز",
        value: "ز",
      },
      {
        label: "ژ",
        value: "ژ",
      },
      {
        label: "س",
        value: "س",
      },
      {
        label: "ش",
        value: "ش",
      },
      {
        label: "ص",
        value: "ص",
      },
      {
        label: "ط",
        value: "ط",
      },
      {
        label: "ع",
        value: "ع",
      },
      {
        label: "ف",
        value: "ف",
      },
      {
        label: "ق",
        value: "ق",
      },
      {
        label: "ک",
        value: "ک",
      },
      {
        label: "گ",
        value: "گ",
      },
      {
        label: "ل",
        value: "ل",
      },
      {
        label: "م",
        value: "م",
      },
      {
        label: "ن",
        value: "ن",
      },
      {
        label: "و",
        value: "و",
      },
      {
        label: "ه",
        value: "ه",
      },
      {
        label: "ی",
        value: "ی",
      },
      {
        label: "D",
        value: "D",
      },
      {
        label: "S",
        value: "S",
      },
    ];

    return (
      <div className="w-full flex flex-col justify-start items-start mb-4">
        <div
          className={`w-full transition-all ${lowMargin ? "h-12" : "h-14"} rounded-xl ${
            error ? "ts-error-border" : "ts-primary-border"
          } flex justify-between items-center pr-3`}
        >
          <div className="w-20 h-full flex justify-center items-center ts-left-border pl-2 max-2xs:pl-0">
            <Text type="P4" className="ts-accent ts-letter-space">
              ایران
            </Text>
            {TSVisible ? (
              <Input
                style={{ direction: "ltr" }}
                type={isIOS() ? "text" : "number"}
                pattern="[0-9]*"
                // inputMode={!isIOS() ? "numeric" : "text"}
                autoFocus
                tabIndex={3}
                onBlur={() => checkStateValue("thirdSection", () => setTSVisible(false))}
                value={plateValue.thirdSection}
                onChange={(e) => {
                  if (e.length < 3) setPlateValues(e, "thirdSection");
                }}
                className={`p-0 ${
                  lowMargin ? "w-8 h-12" : "w-11 h-14"
                } bg-transparent border-0 text-center outline-none max-2xs:-mr-0.5`}
              />
            ) : (
              <div onClick={thirdDash} className="h-16 flex justify-center items-center">
                <div onClick={thirdDash} className="w-4 h-px rounded-full ts-bg-gray-06 mr-1"></div>
                <div onClick={thirdDash} className="w-4 h-px rounded-full ts-bg-gray-06 mr-1"></div>
              </div>
            )}
          </div>
          <div className="w-3/5 flex justify-center items-center">
            {SSVisible ? (
              <Input
                style={{ direction: "ltr" }}
                type={isIOS() ? "text" : "number"}
                pattern="[0-9]*"
                // inputMode={!isIOS() ? "numeric" : "text"}
                tabIndex={2}
                autoFocus
                onBlur={() => checkStateValue("secondSection", () => setSSVisible(false))}
                value={plateValue.secondSection}
                onChange={(e) => {
                  if (e.length < 4) setPlateValues(e, "secondSection");
                  if (e.length === 3) setTSVisible(true);
                }}
                className={`p-0 ${
                  lowMargin ? "w-12 h-12" : "w-14 h-14"
                } bg-transparent border-0 text-center outline-none`}
              />
            ) : (
              <div onClick={secondDash} className="h-16 flex justify-center items-center">
                <div onClick={secondDash} className="w-4 h-px rounded-full ts-bg-gray-06"></div>
                <div onClick={secondDash} className="w-4 h-px rounded-full ts-bg-gray-06 mr-1"></div>
                <div onClick={secondDash} className="w-4 h-px rounded-full ts-bg-gray-06 mr-1"></div>
              </div>
            )}
            <SelectPicker
              data={letters}
              id="letter-picker"
              appearance="subtle"
              placement="auto"
              value={plateValue.letter}
              onChange={(value) => {
                letterChange(value);
                setSSVisible(true);
              }}
              searchable={false}
              cleanable={false}
              className={`${
                lowMargin ? "mx-0.5 ts-letter-m-width-low" : "mx-2 ts-letter-m-width"
              } max-2xs:mx-0.5 bg-transparent outline-none z-10`}
            />
            {FSVisible ? (
              <Input
                style={{ direction: "ltr" }}
                type={isIOS() ? "text" : "number"}
                pattern="[0-9]*"
                // inputMode={!isIOS() ? "numeric" : "text"}
                id="fsInput"
                autoFocus
                tabIndex={1}
                onBlur={() => checkStateValue("firstSection", () => setFSVisible(false))}
                value={plateValue.firstSection}
                onChange={(e) => {
                  if (e.length < 3) setPlateValues(e, "firstSection");
                  if (e.length === 2) {
                    document.getElementById("fsInput")?.blur();
                    document.getElementById("letter-picker")?.click();
                  }
                }}
                className={`border-0 p-0 ${
                  lowMargin ? "ts-first-input-width h-12" : "h-14 w-10"
                } bg-transparent text-center outline-none`}
              />
            ) : (
              <div onClick={firstDash} className="h-16 flex justify-center items-center">
                <div onClick={firstDash} className="w-4 h-px rounded-full ts-bg-gray-06"></div>
                <div onClick={firstDash} className="w-4 h-px rounded-full ts-bg-gray-06 mr-1"></div>
              </div>
            )}
          </div>
          <div
            className={`w-10 ${
              lowMargin ? "h-12 justify-center pt-1" : "h-14 justify-start pt-2"
            } -ml-px ts-bg-primary flex flex-col items-end pl-2 rounded-l-xl`}
          >
            <img src={flag} alt="ir-flag" className="mb-1 w-5 h-3 -ml-px" />
            {!lowMargin && (
              <Text type="Tiny" className="text-white -mb-0.5">
                IR
              </Text>
            )}
            <Text type="Tiny" className="text-white -mt-px">
              IRAN
            </Text>
          </div>
        </div>
        {error && (
          <Text type="P5" className={`ts-error mt-1`}>
            {errorText}
          </Text>
        )}
      </div>
    );
  },
);

export { InputLicensePlate };
