/* eslint-disable */
import Skeleton from "react-loading-skeleton";
import { Text } from "../../../components";
import CardLoader from "./CardLoader";

const ShopLoader = ({}) => {
  return (
    <>
      <Text type="H4" className="ts-accent mb-4">
        فروشگاه قطعات
      </Text>
      <div className="w-full flex justify-between items-center mb-3 -mt-0.5 gap-2">
        <Skeleton containerClassName="w-full" className="w-full h-12 rounded-xl" />
        <Skeleton className="w-14 h-12 rounded-xl" />
      </div>

      <div className="w-full flex mb-1.5 overflow-x-auto touch-auto ts-hide-category-scroll-but-keep-working">
        <div className="flex space-x-2 gap-2 mb-2.5">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((item) => {
            return <Skeleton className="w-20 h-9 rounded-xl" />;
          })}
        </div>
      </div>

      <div className="w-full flex justify-between items-center mb-4">
        <Skeleton className="w-28" />
        <Skeleton className="w-36 h-12 outline-none rounded-xl" />
      </div>

      <CardLoader />
    </>
  );
};

export { ShopLoader as default };
